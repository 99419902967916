<template>
    <div class="cc">
        <div class="content_wrap">
            <Loading :isLoading="isLoading" />
            <div class="monitoring_page_title">
                <span class="title">자동 재발송 문자 발송 기록</span>
                <span class="description"> 앱 미가입 고객 휴대폰으로 설정된 일시에 자동으로 가입문자를 재발송에 대한 기록입니다.</span>
            </div>
            <div class="h_scroll" style="justify-content: space-between">
                <div class="monitoring_wrap">
                    <div class="wrap_header">
                        <div class="wrap_content">
                            <div>총 {{ this.hTotalCount }}건</div>
                        </div>
                        <div class="wrap_content">
                            <input type="text" class="input_s" placeholder="병원명" v-model="keyword" @keyup.enter="loadReservedSms(0)"/>
                            <button class="btn_s btn_blue_line mgl_5" @click="loadReservedSms(0)">검색</button>
                            <select class="input_s mgl_5" v-model="viewType">
                                <option value="100">100개씩 보기</option>
                                <option value="150">150개씩 보기</option>
                                <option value="200">200개씩 보기</option>
                            </select>
                        </div>
                    </div>
                    <div class="monitoring_content">
                        <table class="table_default">
                            <thead>
                                <tr>
                                    <th width="150">
                                        <span style="vertical-align: middle">
                                            병원명
                                        </span>
                                    </th>
                                    <th width="80">
                                        <span style="vertical-align: middle">
                                            환자명
                                        </span>
                                    </th>
                                    <th width="250" @click="action_TableHeader('message')">
                                        <span style="vertical-align: middle">
                                            메세지
                                            <v-icon class="sort_icon">
                                                {{ changeSortIcon('message') }}
                                            </v-icon>
                                        </span>
                                    </th>
                                    <th width="80" @click="action_TableHeader('status')">
                                        <span style="vertical-align: middle">
                                            상태
                                            <v-icon class="sort_icon">
                                                {{ changeSortIcon('status') }}
                                            </v-icon>
                                        </span>
                                    </th>
                                    <th width="120" @click="action_TableHeader('createdAt')">
                                        <span style="vertical-align: middle">
                                            생성일
                                            <v-icon class="sort_icon">
                                                {{ changeSortIcon('createdAt') }}
                                            </v-icon>
                                        </span>
                                    </th>
                                    <th width="120" @click="action_TableHeader('sendDateTime')">
                                        <span style="vertical-align: middle">
                                            발송예정일시
                                            <v-icon class="sort_icon">
                                                {{ changeSortIcon('sendDateTime') }}
                                            </v-icon>
                                        </span>
                                    </th>
                                    <th width="120" @click="action_TableHeader('sendedAt')">
                                        <span style="vertical-align: middle">
                                            발송일시
                                            <v-icon class="sort_icon">
                                                {{ changeSortIcon('sendedAt') }}
                                            </v-icon>
                                        </span>
                                    </th>
                                    <th width="120" @click="action_TableHeader('canceledAt')">
                                        <span style="vertical-align: middle">
                                            취소일시
                                            <v-icon class="sort_icon">
                                                {{ changeSortIcon('canceledAt') }}
                                            </v-icon>
                                        </span>
                                    </th>
                                    <th width="120" @click="action_TableHeader('cancelReason')">
                                        <span style="vertical-align: middle">
                                            취소사유
                                            <v-icon class="sort_icon">
                                                {{ changeSortIcon('cancelReason') }}
                                            </v-icon>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <thead v-if="(hData?.length ?? 0) == 0">
                                <tr>
                                    <td colspan="5" style="padding: 6px 10px">검색된 결과가 존재하지 않습니다.</td>
                                </tr>
                            </thead>
                            <thead v-if="(hData?.length ?? 0) != 0">
                                <Item v-for="(data, index) in hData" :key="index" :data="data" />
                            </thead>
                        </table>
                    </div>
                </div>
                <PagenationComponent
                    v-bind:totalCount="hTotalCount"
                    v-bind:currentPage="hCurrentPage"
                    v-bind:countPerPage="hCountPerPage"
                    v-bind:countPerRow="5"
                    v-on:changePage="hChangePage"
                />
            </div>
        </div>
    </div>
</template>


<script>
import PageController from '../../../../../controller/PageController'
import PagenationComponent from '../../../ShareComponents/PagenationComponent.vue'
import Loading from '@/views/main/ShareComponents/LoadingIndicate.vue'
import Item from './SubComponent/AutoReservedListItem.vue'

export default {
    components: { PagenationComponent, Loading, Item },
    data() {
        return {
            pageController: undefined,
            hData: [],
            hTotalCount: 0,
            hCurrentPage: 0,
            hCountPerPage: 100,

            isLoading: false,
            keyword: '',
            viewType: 100,
            sortKey: 'sendDateTime',
            sortWay: -1
        }
    },
    watch: {
        viewType: {
            immediate: false,
            handler: function(newValue) {
                this.hCurrentPage = 0
                this.countPerPage = Number(newValue)
                this.pageController.countPerPage =  this.countPerPage
                this.loadReservedSms(this.hCurrentPage)
            }
        }
    },
    mounted() {
        this.pageController = new PageController(this.API.ReservedSMS.find)
        this.pageController.countPerPage = this.hCountPerPage
        this.loadReservedSms(this.hCurrentPage)
    },
    computed: {
        
    },
    methods: {
        hChangePage(page) {
            this.hCurrentPage = page
            this.loadReservedSms(this.hCurrentPage)
        }, 
        changeSortIcon(sort) {
            if (this.sortKey != sort) {
                return ''
            }
            if (this.sortWay == 1) {
                return 'mdi-menu-up'
            }
            return 'mdi-menu-down'
        },

        action_TableHeader(key) {
            if (this.sortKey == key) {
                if (this.sortWay == 1) {
                    this.sortWay = -1
                } else if (this.sortWay == -1) {
                    if (this.sortKey == 'sendDateTime') {
                        this.sortWay = 1
                    } else {
                        this.sortKey = 'sendDateTime'
                        this.sortWay = -1
                    }
                } else {
                    this.sortWay = 1
                }
            } else {
                this.sortKey = key
                this.sortWay = 1
            }
            this.loadReservedSms(this.hCurrentPage)
        },

        async loadReservedSms(page) {
            this.isLoading = true

            this.pageController.filter = {}
            this.pageController.filter.key = this.sortKey 
            this.pageController.filter.way = this.sortWay

            if (this.keyword.trim() != '') {
                this.pageController.filter.keyword = this.keyword
            }
            await this.pageController.loadPageIndex(page)
            await this.loadReservedSmsCount()
            
            this.hData = this.pageController.data
            this.isLoading = false
        },

        async loadReservedSmsCount() {
            let result = await this.API.ReservedSMS.count(this.pageController.filter)
            if (result.data.code != 0){
                this.isLoading = false
                console.error(result)
                return
            }
            this.hTotalCount = result.data.data
        }
    }
}
</script>

<style>
.monitoring_page_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 65px;
    padding: 8px 15px;
    background: transparent;
    border-bottom: 1px solid #dfdfe0;
}
.monitoring_page_title .title {
    font-size: 18px;
    font-weight: 600;
}
.monitoring_page_title .description {
    color: #929292;
}
</style>

