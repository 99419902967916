import axios from 'axios';
import URL from './URL';
import Util from './Util';

const find = async function (m) {
    return axios({
        method: 'GET',
        url: URL.CA_RESERVED_CHAT_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

const count = async function (m) {
    return axios({
        method: 'GET',
        url: URL.CA_RESERVED_CHAT_COUNT,
        params: m,
        headers: Util.getHeaders()
    })
}

const create = async function (m) {
    return axios({
        method: 'POST',
        url: URL.CA_RESERVED_CHAT_CREATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const createMany = async function (m) {
    return axios({
        method: 'POST',
        url: URL.CA_RESERVED_CHAT_CREATE_MANY,
        data: m,
        headers: Util.getHeaders()
    })
}


export default {
    find: find,
    count: count,
    create: create,
    createMany: createMany
}
