import axios from 'axios'
import URL from './URL'
import Util from './Util'

const getHospitals = async function (m) {
    return axios({
        method: 'GET',
        url: URL.HOSPITAL_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

const countHospitals = async function (m) {
    return axios({
        method: 'GET',
        url: URL.HOSPITAL_COUNT,
        params: m,
        headers: Util.getHeaders()
    })
}

const getNurses = async function (m) {
    return axios({
        method: 'GET',
        url: URL.HOSPITAL_FIND_NURSE,
        params: m,
        headers: Util.getHeaders()
    })
}

const createHospital = async function (m) {
    return axios({
        method: 'POST',
        url: URL.HOSPITAL_CREATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const updateHospital = async function (m) {
    return axios({
        method: 'POST',
        url: URL.HOSPITAL_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const getDoctors = async function (m) {
    return axios({
        method: 'GET',
        url: URL.HOSPITAL_FIND_DOCTOR,
        params: m,
        headers: Util.getHeaders()
    })
}

const countDoctors = async function (m) {
    return axios({
        method: 'GET',
        url: URL.HOSPITAL_COUNT_DOCTOR,
        params: m,
        headers: Util.getHeaders()
    })
}

const createDoctor = async function (m) {
    return axios({
        method: 'POST',
        url: URL.HOSPITAL_CREATE_DOCTOR,
        data: m,
        headers: Util.getHeaders()
    })
}

const updateDoctor = async function (m) {
    return axios({
        method: 'POST',
        url: URL.HOSPITAL_UPDATE_DOCTOR,
        data: m,
        headers: Util.getHeaders()
    })
}

const findHospitalEL = async function (m) {
    return axios({
        method: 'GET',
        url: URL.EL_HOSPITAL_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}


export default {
    getHospitals: getHospitals,
    countHospitals: countHospitals,
    getNurses: getNurses,
    createHospital: createHospital,
    updateHospital: updateHospital,
    getDoctors: getDoctors,
    countDoctors: countDoctors,
    createDoctor: createDoctor,
    updateDoctor: updateDoctor,
    findHospitalEL: findHospitalEL
}
