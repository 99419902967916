<template>
    <div class="content_wrap">
        <div class="page_title">
            <span>알림 소리 설정</span>
        </div>
        <div class="h_scroll pd_15">
            <div class="table_title">
                <p class="dgray_txt">
                    총 <span class="black_txt">{{ totalCount }}</span
                    >개
                </p>
                <div class="table_title_btn">
                    <button class="btn btn_l btn_blue" @click="action_Add">추가</button>
                </div>
            </div>
            <div class="table_header_fix">
                <table class="table_default">
                    <thead>
                        <tr>
                            <th width="150px">이름</th>
                            <th>파일명</th>
                            <th width="150px">사용여부</th>
                            <th width="80px">수정</th>
                            <th width="80px">삭제</th>
                        </tr>
                    </thead>
                    <tr v-if="soundList.length == 0">
                        <td colspan="5">등록된 소리가 없습니다.</td>
                    </tr>
                    <thead v-if="soundList.length != 0">
                        <Item v-for="sound in soundList" :key="sound._id" :sound="sound" @modify="action_Modify" />
                    </thead>
                </table>
            </div>
            <!-- <Pagenation 
                v-bind:totalCount="totalCount"
                v-bind:currentPage="currentPage"
                v-bind:countPerPage="countPerPage"
                v-bind:countPerRow="countPerRow"
                v-on:changePage="changePage"
            /> -->
        </div>
        <Popup_EditNotificationSound :isShow="isShowPopup" :sound="selectedSound" @close="closePopup" />
    </div>
</template>

<script>
import Item from './Cell/NotificationSoundSettingItem.vue'
import Popup_EditNotificationSound from './Popup/Popup_EditNotificationSound.vue'
// import Pagenation from '../../../ShareComponents/PagenationComponent.vue'

export default {
    components: { Item, Popup_EditNotificationSound },
    data() {
        return {
            soundList: [],
            totalCount: 0,

            isShowPopup: false,
            selectedSound: undefined,

            // for pagenation
            currentPage: 0,
            countPerPage: 20,
            countPerRow: 5
        }
    },
    mounted() {
        this.loadTotalCount()
        this.loadSound()
    },

    methods: {
        async loadTotalCount() {
            let result = await this.API.ElectronNotification.count({})
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.totalCount = result.data.data
        },
        async loadSound() {
            let result = await this.API.ElectronNotification.find({})
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.soundList = result.data.data
        },

        action_Add() {
            this.selectedSound = undefined
            this.openPopup()
        },
        action_Modify(sound) {
            this.openPopup(sound)
        },

        changePage(index) {
            console.log(index)
        },

        // MARK: - Popup
        openPopup(sound) {
            this.selectedSound = sound
            this.isShowPopup = true
        },
        closePopup(needRefresh) {
            this.isShowPopup = false
            this.selectedSound = undefined
            if (needRefresh) {
                this.loadSound()
            }
        }
    }
}
</script>
