import axios from 'axios'
import URL from './URL'
import Util from './Util'

/*
 *  imageData : {
 *      datas: [binaryData],
 *      sizes: [{width: width, height: height}],
 *      names: [String]
 *  }
 *
 */

var getFile = async function (m) {
    return axios({
        method: 'GET',
        url: URL.GET_FILE,
        params: m,
        headers: Util.getHeaders()
    })
}

var uploadFile = async function (files, sizes, type, mimeType) {
    var data = new FormData()
    let sizesString = JSON.stringify(sizes)
    data.append('sizes', sizesString)
    data.append('type', type)

    for (var i = 0; i < files.length; i++) {
        let file = files[i]
        data.append(String(i) + file.name, file)
    }

    let config = {
        header: {
            'Content-Type': mimeType
        },
        headers: Util.getHeaders()
    }

    console.log()
    return axios.post(URL.UPLOAD_FILE, data, config)
}

var createFile = async function (model) {
    return axios({
        method: 'POST',
        url: URL.CREATE_FILE,
        data: model,
        headers: Util.getHeaders()
    })
}

export default {
    getFile: getFile,
    uploadFile: uploadFile,
    createFile: createFile
}
