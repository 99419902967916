const state = {
    isShow: false,
    isHServiceLeft: false, // isClosed 되지 않은 HService 남아있는지 여부
    selectedHospital: undefined
}

const actions = {
    setHospitalSuspendPopupState: (store, param) => {
        store.commit('muHospitalSuspendPopupState', param)
    }
}

const mutations = {
    muHospitalSuspendPopupState: (state, param) => {
        state.isShow = param.isShow
        state.isHServiceLeft = param.isHServiceLeft
        state.selectedHospital = param.selectedHospital
    }
}

export default { state, actions, mutations }
