<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <Loading :isLoading="isLoading" />
        <div class="answeringChat_pop_wrap" style ="width: 1200px; height: 800px">
            <PopupTitleSection title="엑셀 데이터" @action_Close="action_Close" />
            <div class="content" style="color: red; padding: 10px;" v-if="errorMassage != ''">
                {{ errorMassage }}
            </div>
            <div class="content">
                <div class="h_scroll pd_15"  style="height: 600px">
                    <table class="table_default">
                        <thead>
                            <tr>
                                <th>hospitalID</th>
                                <th>hserviceID</th>
                                <th>chatRoomID</th>
                                <th>receiverName</th>
                                <th>receiverPhone</th>
                                <th style="width: 200px">content</th>
                                <th>caName</th>
                                <th>caCycle</th>
                                <th>sendDateTime</th>
                                <th>description</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr v-for="(value, index) in sheetData" :key="index">
                                <td>{{ value.hospitalID ?? '-' }}</td>
                                <td>{{ value.hserviceID ?? '-' }}</td>
                                <td>{{ value.chatRoomID ?? '-' }}</td>
                                <td>{{ value.receiverName ?? '-' }}</td>
                                <td>{{ value.receiverPhone ?? '-' }}</td>
                                <td :title="value.content">{{ getSubStringValue(value.content, 25) }}</td>
                                <td>{{ value.caName ?? '-' }}</td>
                                <td>{{ value.caCycle ?? '-' }}</td>
                                <td>{{ getIntToDateFormatString(value.sendDateTime) }}</td>
                                <td :title="value.description">{{ getSubStringValue(value.description, 25) }}</td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
            <div class="btn_wrap">
                <button class="btn btn_l btn_gray" @click="action_Close">닫기</button>
                <button class="btn btn_l btn_blue" @click="action_Save">저장</button>
            </div>
        </div>
    </div>
</template>


<script>
import PopupTitleSection from '../../../../ShareComponents/PopupTitleSection.vue';
import Loading from '@/views/main/ShareComponents/LoadingIndicate.vue'
import ExcelParser from '@/util/ExcelParser'

const moment = require('@/util/MPMoment')

export default {
    components: { PopupTitleSection, Loading }, 
    props: {
        isShow: Boolean,
        selectedFile: {
            validator(value) {
                return value instanceof File;
            }
        }
    },
    data() {
        return {
            excelPerse : undefined,
            sheetData: [],

            isLoading: false,
            errorMassage: '',

            senderInfo: {}
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function() { 
                this.errorMassage = ''
            }
        },
        selectedFile: {
            immediate: true,
            handler: function(newValue) {
                if (!newValue) { return }
                this.parseExcelFile()
            }
        }
    },
    mounted() {
        this.excelPerse = new ExcelParser()
    },
    computed: {
    },
    methods: {
        async parseExcelFile() {
            this.isLoading = true
            try {
                this.excelPerse.file = this.selectedFile
                this.sheetData = await this.excelPerse.parse()
            } catch (err) {
                console.error("parsing error:", err)
            } finally {
                this.isLoading = false
            }
        },
        getSubStringValue(str, maxLength) {
            let count = str?.length ?? 0
            let returnValue = str?.substring(0, maxLength)?.trimEnd() ?? '-'
            if (count > maxLength) { returnValue += '...' }
            return returnValue
        },
        getIntToDateFormatString(date) {
            if(!date) { return '-' }
            let intDateNumber = date - 25569
            let utcDateNumber = intDateNumber * 86400 * 1000
            return moment(utcDateNumber).add(-9, 'hours').format('YYYY-MM-DD HH:mm:ss')
        },
        getParsedParameter(model) {
            let targetDate = undefined
            if (model.sendDateTime != undefined)  {
                let intDateNumber = model.sendDateTime - 25569
                let utcDateNumber = intDateNumber * 86400 * 1000
                targetDate = moment(utcDateNumber).add(-9, 'hours')
            }
            return {
                hospitalID: model.hospitalID,
                hserviceID: model.hserviceID,
                chatRoomID: model.chatRoomID,
                hospitalName: model.hospitalName,
                receiverName: model.receiverName,
                receiverPhone: model.receiverPhone,
                contents: [
                    {
                        type: 100,
                        content: model.content
                    }
                ],
                caName: model.caName,
                caCycle: model.caCycle,
                description: model.description,
                sendDateTime: targetDate,
                userID: this.senderInfo[model.hospitalID]?.userID,
                userName : this.senderInfo[model.hospitalID]?.userName,
                userRealName: this.senderInfo[model.hospitalID]?.userRealName,
                jobType	: this.senderInfo[model.hospitalID]?.jobType,
                photoPath : this.senderInfo[model.hospitalID]?.photoPath
            }
        },
        handleApiSuccess() {
            this.isLoading = false
            this.action_Close()
            this.$emit('successCallback')
        },

        handleApiError(row) {
            this.isLoading = false
            let startLine = (Math.floor(row / 20) * 20) + 1
            let endLine = startLine + 19
            if (endLine > this.sheetData.length) {
                endLine = this.sheetData.length
            }
            this.errorMassage = startLine + '행에서 부터 ' + endLine + '행에 대한 작업 도중 오류가 발생했습니다.'
        },

        // MARK : User Action
        action_Close() { 
            this.$emit('action_Close') 
        },

        async action_Save() {
            if (this.sheetData.length == 0) { return }

            let array = []
            let lastIndex = this.sheetData.length - 1

            await this.reuqestFetchHospitalInfo()
            
            for (let row = 0; row <= lastIndex; row++) {
                array.push(this.getParsedParameter(this.sheetData[row]))
                try {
                    if (array.length == 20 || ((row == lastIndex) && array.length != 0)) {
                        let result = await this.API.CAReservedChat.createMany(array)
                        if (result.data.code != 0) { throw Error(result.data.message) }
                        array = []
                    }
                } catch (err) {
                    console.log(err)
                    this.handleApiError(row + 1)
                    return
                }
            }
            this.handleApiSuccess() 
        },

        // MARK: Network
        async reuqestFetchHospitalInfo() {
            let ids = this.sheetData.map(d => d.hospitalID)
            let uniqueIds = [...new Set(ids)];

            for (let i = 0; i < uniqueIds.length; i++) {
                let hospitalID = uniqueIds[i]
                let result = await this.API.Hospital.findHospitalEL({_id: hospitalID})
                if (result.data.code == 0) {
                    let nurseInfo = result.data.data[0].nurseID
                    await this.requestFetchFileInfo(hospitalID, nurseInfo)
                }
            }
        },

        async requestFetchFileInfo(hospitalID, nurseInfo) {
            if (this.senderInfo[hospitalID] != undefined) { return }

            await this.API.User.findOne({userID: nurseInfo._id})
                .then(result => {
                    if (result.data.code == 0) {
                        this.senderInfo[hospitalID] = {
                            userID: nurseInfo?._id,
                            userName : nurseInfo?.name,
                            userRealName: nurseInfo?.realName,
                            jobType	: nurseInfo?.type,
                            photoPath : result.data.data.photo.paths?.[0]
                        }
                    }
                })
        }
    }
}
</script>