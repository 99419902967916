<template>
    <Transition name="toast-fade">
        <div class="toast" v-if="isShow">
            <pre style="text-align: center">저장되었습니다.</pre>
        </div>
    </Transition>
</template>

<script>
export default {
    props: { isShow: Boolean } 
}
</script>