import axios from 'axios'
import URL from './URL'
import Util from './Util'

const find = async function (m) {
    return axios({
        method: 'GET',
        url: URL.HOSPITAL_AUTHTYPE_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

export default {
    find: find
}
