<template>
    <div class="snb">
        <div class="snb_title">예약 채팅 관리</div>
        <a @click="action_CAReservedChat" class="snb_menu" :class="{ on: $router.currentRoute.name == 'CAReservedChat' }">채팅관리</a>
    </div>
</template>

<script>
export default {
    methods: {
        action_CAReservedChat() {
            this.$router.push({ name: 'CAReservedChat' }).catch(() => {})
        },
    }
}
</script>

<style scoped></style>