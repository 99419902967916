import store from '../store/index'

var getHeaders = function () {
    let token = store.state.user.token
    return { Authorization: 'Bearer ' + token }
}
var getAlHeaders = function() {
    let token = store.state.alfeatureconfigpopup.token
    return { Authorization: 'Bearer ' + token }
}

export default {
    getHeaders   :   getHeaders,
    getAlHeaders :   getAlHeaders,
}
