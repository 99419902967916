<template>
    <div class="messagelog_wrap" v-if="almessagelogviewer.isShow">
        <div class="header_wrap">
            <div class="title">병원명: {{ hospitalName }}</div>
            <div class="title">병원 ID: {{ hospitalID }}</div>
            <div class="button_section"><button @click="action_Close">뒤로 가기</button></div>
        </div>
        <div class="h_scroll">
            <div style="height: 75%">
                <table class="table_default">
                    <thead>
                        <tr>
                            <th v-for="title in defaultTableList" :key="title">{{title}}</th>
                        </tr>
                    </thead>
                    <thead v-if="isLoading == true">
                        <tr>
                            <td colspan="6">데이터 로드 중...</td>
                        </tr>
                    </thead>
                    <thead v-if="userData.length == 0 && !isLoading">
                        <tr>
                            <td colspan="6">정보가 없습니다.</td>
                        </tr>
                    </thead>
                    <thead v-if="userData.length > 0 && !isLoading">
                        <tr v-for="u in userData" :key="u._id">
                            <td>{{ u.request.name }}</td>
                            <td>{{ u.request.patientID }}</td>
                            <td>{{ u.request.birth }}</td>
                            <td>{{ patientGender(u.request.gender) }}</td>
                            <td>{{ patientPhone(u.request.phone) }}</td>
                            <td>{{ getCreatedAt(u.createdAt) }}</td>
                        </tr>
                    </thead>
                </table>
            </div>
            <Pagenation 
                v-bind:totalCount="hTotalCount"
                v-bind:currentPage="hCurrentPage"
                v-bind:countPerPage="hCountPerPage"
                v-bind:countPerRow="5"
                v-on:changePage="hChangePage" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import PageController from '../../../../../../controller/PageController';
    import Pagenation from '../../../../ShareComponents/PagenationComponent.vue'
    import moment from '../../../../../../util/MPMoment'

    export default {
        components: { Pagenation },
        data() {
            return {
                isLoading: false,
                defaultTableList: ['스마트닥터 고객명', '스마트닥터 고객ID', '생년월일', '성별', '휴대폰 번호', '전능CRM 고객 등록 요청(문자 발송 일시)'],
                // pagecontroller
                userData: [],
                hTotalCount: 0,
                hCurrentPage: 0,
                hCountPerPage: 10,
                alPageController: undefined,
            }
        },
        watch: {
            'almessagelogviewer.isShow': {
                immediate: true,
                handler: function (newValue) {
                    if (newValue == true) {
                        this.initData()
                        this.alPageController = new PageController(this.API.AlService.getPatientRequest, this.filter)
                        this.alPageController.countPerPage = this.hCountPerPage
                        this.loadMessageLog(this.hCurrentPage)
                    }
                }
            }
        },
        computed: {
            ...mapState(['almessagelogviewer']),

            hospitalName: function () {
                if (this.almessagelogviewer.selectedHospital == undefined) { return }
                if (this.almessagelogviewer.selectedHospital.shortName == undefined) { return }
                return this.almessagelogviewer.selectedHospital.shortName
            },
            hospitalID: function () {
                if (this.almessagelogviewer.selectedHospital == undefined) { return }
                if (this.almessagelogviewer.selectedHospital._id == undefined) { return }
                return this.almessagelogviewer.selectedHospital._id
            },
            currentPath: function () {
                return this.$route.path;
            },
        },

        methods: {
            ...mapActions(['setAlMessageLogViewer']),

            // MARK: - Network
            async initData(){
                this.hCurrentPage = 0
            },

            async loadMessageLog(page) {
                this.isLoading = true;
                this.alPageController.filter = {}
                this.alPageController.filter.hospitalID = this.hospitalID
                this.alPageController.filter.action = 'regist'
                // this.filter.hospitalID = this.hospitalID
                // this.filter.action = 'regist'

                await this.alPageController.loadSIPageIndex(page)
                let result = await this.API.AlService.countPatientRequest(this.alPageController.filter)

                this.hTotalCount = result.data
                
                this.userData = this.alPageController.data
                this.isLoading = false
            },

            // MARK: - Action
            async action_Close() {
                this.initData()
                await this.setAlMessageLogViewer({
                    isShow: false
                })
            },
            hChangePage(page) {
                this.hCurrentPage = page
                this.loadMessageLog(this.hCurrentPage)
            },

            // MARK: - Variable
            patientGender(u) {
                let gender = u
                if (gender == undefined || gender == '') { return '-' }
                gender = gender.toLowerCase()
                if (gender == 'm' || gender == 'male') { return '남성' }
                return '여성'
            },
            patientPhone(u) {
                let phone = u
                if (phone == undefined || phone == '') { return '-' }
                phone = phone.replace('-', '').replace('-', '')
                phone = phone.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
                return phone
            },
            getCreatedAt(u) {
                return moment(u).format('YYYY[년] M[월] D[일] (ddd) HH:mm:ss')
            }
        }
    }
</script>

<style>
    .messagelog_wrap .h_scroll tr th {
        border: 1px solid #dcdcdc;
    }

    .messagelog_wrap .h_scroll tr td {
        border: 1px solid #dcdcdc;
        padding: 15px 10px;
    }
</style>