<template>
    <div class="cc">
        <div class="content_wrap">
        <Loading :isLoading="isLoading" />
            <div class="page_title">
                <span>예약된 채팅 관리</span>
            </div>
            <div ref="scroll" class="h_scroll pd_15">
                <div class="table_title">
                    <p class="dgray_txt">
                        총 <span class="black_txt">{{ totalCount }}</span>개
                    </p>
                    <div class="table_title_btn">
                        <select class="input_s mgl_5" v-model="viewType">
                            <option value="100">100개씩 보기</option>
                            <option value="150">150개씩 보기</option>
                            <option value="200">200개씩 보기</option>
                        </select>
                        <input ref="fileinput" type="file" hidden @change="setSelectedExcelFile($event)" :accept="getFileType()"/>
                        <button class="btn btn_l btn_blue" @click="action_ExcelUpload()">업로드</button>
                    </div>
                </div>
                <div class="monitoring_content">
                    <table class="table_default">
                        <thead>
                            <tr>
                                <th width="100px" @click="action_TableHeader('hospitalName')">
                                    <span style="vertical-align: middle">
                                        병원명
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('hospitalName') }}
                                        </v-icon>
                                    </span>
                                </th>
                                <th width="100px" @click="action_TableHeader('hserviceID')">
                                    <span style="vertical-align: middle">
                                        서비스ID
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('hserviceID') }}
                                        </v-icon>
                                    </span>
                                </th>
                                <th width="100px" @click="action_TableHeader('receiverName')">
                                    <span style="vertical-align: middle">
                                        수신자명
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('receiverName') }}
                                        </v-icon>
                                    </span>
                                </th>
                                <th width="100px">
                                    <span style="vertical-align: middle">
                                        본문
                                    </span>
                                </th>
                                <th width="100px" @click="action_TableHeader('caName')">
                                    <span style="vertical-align: middle">
                                        CA명
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('caName') }}
                                        </v-icon>
                                    </span>
                                </th>
                                <th width="100px" @click="action_TableHeader('caCycle')">
                                    <span style="vertical-align: middle">
                                        CA사이클
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('caCycle') }}
                                        </v-icon>
                                    </span>
                                </th>
                                <th width="100px" @click="action_TableHeader('status')">
                                    <span style="vertical-align: middle">
                                        발송상태
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('status') }}
                                        </v-icon>
                                    </span>
                                </th>
                                <th width="100px" @click="action_TableHeader('cancelReason')">
                                    <span style="vertical-align: middle">
                                        취소사유
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('cancelReason') }}
                                        </v-icon>
                                    </span>
                                </th>
                                <th width="100px" @click="action_TableHeader('description')">
                                    <span style="vertical-align: middle">
                                        설명
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('description') }}
                                        </v-icon>
                                    </span>
                                </th>
                                <th width="100px" @click="action_TableHeader('createdAt')">
                                    <span style="vertical-align: middle">
                                        생성일시
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('createdAt') }}
                                        </v-icon>
                                    </span>
                                </th>
                                <th width="100px" @click="action_TableHeader('sendDateTime')">
                                    <span style="vertical-align: middle">
                                        발송예정일시
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('sendDateTime') }}
                                        </v-icon>
                                    </span>
                                </th>
                                <th width="100px" @click="action_TableHeader('sendedAt')">
                                    <span style="vertical-align: middle">
                                        발송일시
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('sendedAt') }}
                                        </v-icon>
                                    </span>
                                </th>
                                <th width="100px" @click="action_TableHeader('canceledAt')">
                                    <span style="vertical-align: middle">
                                        취소일시
                                        <v-icon class="sort_icon">
                                            {{ changeSortIcon('canceledAt') }}
                                        </v-icon>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tr v-if="datas.length == 0">
                            <td colspan="12">등록된 예약 채팅이 없습니다.</td>
                        </tr>
                        <thead v-if="datas.length != 0">
                            <Item v-for="d in datas" :key="d._id" :data="d" />
                        </thead>
                    </table>
                </div>
                <PagenationComponent
                    v-bind:totalCount="totalCount"
                    v-bind:currentPage="currentPage"
                    v-bind:countPerPage="countPerPage"
                    v-bind:countPerRow="5"
                    v-on:changePage="changePage"
                />
            </div>
        </div>
        <Popup 
            :isShow="isShowPopup" 
            :selectedFile="selectedFile"
            @action_Close="action_Close" 
            @successCallback="createSuccessCallback"
        />
    </div>
</template>

<script>
import Popup from './Popup/CAExcelParsePopup.vue'
import PageController from '@/controller/PageController'
import PagenationComponent from '@/views/main/ShareComponents/PagenationComponent.vue'
import Loading from '@/views/main/ShareComponents/LoadingIndicate.vue'
import Item from './SubComponent/ReservedChatListItem.vue'


export default {
    components: { Popup, PagenationComponent, Loading, Item },
    data() {
        return {
            isLoading: false,
            isShowPopup: false,

            pageController: undefined,
            datas: [],
            totalCount: 0,
            currentPage: 0,
            countPerPage: 100,
            keyword: '',
            viewType: 100,
            sortKey: 'createdAt',
            sortWay: -1,

            toastMessage: '',
            uploadSuccess: true,

            selectedFile: undefined,
        }
    },
    watch: {
        viewType: {
            immediate: false,
            handler: function(newValue) {
                this.currentPage = 0
                this.countPerPage = Number(newValue)
                this.pageController.countPerPage =  this.countPerPage
                this.loadReservedChat(this.currentPage)
            }
        }
    },
    mounted() {
        this.pageController = new PageController(this.API.CAReservedChat.find)
        this.pageController.countPerPage = this.countPerPage
        this.loadReservedChat(this.currentPage)
    },
    computed: {

    },
    methods: {
        scrollToTop() {
            let el = this.$refs["scroll"]
            if (el != undefined) {
                el.scrollTop = 0
            }
        },
        changePage(page) {
            this.currentPage = page
            this.loadReservedChat(this.currentPage)
        }, 
        changeSortIcon(sort) {
            if (this.sortKey != sort) {
                return ''
            }
            if (this.sortWay == 1) {
                return 'mdi-menu-up'
            }
            return 'mdi-menu-down'
        },
        getFileType() {
            return ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        },
        setSelectedExcelFile(event) {
            if (event.target.files?.length > 0) {
                this.selectedFile = event.target.files[0]
                this.action_ShowParsingFilePopup()
            }
        },
        createSuccessCallback() {
            this.currentPage = 0
            this.loadReservedChat(this.currentPage)
        },

        // MARK : User Action
        action_ExcelUpload() {
            this.$refs['fileinput'].click()
        },
        action_ShowParsingFilePopup() {
            this.$refs.fileinput.value = null;
            this.isShowPopup = true
        },
        action_Close() {
            this.isShowPopup = false
            this.selectedFile = undefined
        },
        action_TableHeader(key) {
            if (this.sortKey == key) {
                if (this.sortWay == 1) {
                    this.sortWay = -1
                } else if (this.sortWay == -1) {
                    this.sortKey = undefined
                    this.sortWay = undefined
                } else {
                    this.sortWay = 1
                }
            } else {
                this.sortKey = key
                this.sortWay = 1
            }
            this.loadReservedChat(this.currentPage)
        },

        // MARK : Network
        async loadReservedChat(page) {
            this.isLoading = true

            this.pageController.filter = {}
            this.pageController.filter.key = this.sortKey 
            this.pageController.filter.way = this.sortWay

            if (this.keyword.trim() != '') {
                this.pageController.filter.keyword = this.keyword
            }

            await this.pageController.loadPageIndex(page)
            await this.loadReservedChatCount()

            this.datas = this.pageController.data
            this.scrollToTop()
            this.isLoading = false

        },
        async loadReservedChatCount() {
            let result = await this.API.CAReservedChat.count(this.pageController.filter)
            if (result.data.code != 0){
                this.isLoading = false
                console.error(result)
                return
            }
            this.totalCount = result.data.data
        }
    }
}
</script>
