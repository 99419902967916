// hospitalSettings.vue에서 사용되는 json

const popup_hospital_type_message = {
    // hospital type == 정형외과
    OS: {
        initialChat:
            '<애프터닥 정형외과>를 방문해주셔서 감사합니다.\n병원 치료 후 관리를 함께할 김아연 간호사입니다.\n\n앞으로 증상 개선을 위해 병원 밖에서도 건강관리를 도와드릴 예정입니다.\n\n재활운동, 생활습관, 치료 일정 외에도 궁금한 점이나 불편한 점이 있으시면 편하게 말씀해주세요^^',
        initialChats:[
            {
                chatType: 100,
                content: '<애프터닥 정형외과>를 방문해주셔서 감사합니다.\n병원 치료 후 관리를 함께할 김아연 간호사입니다.\n\n앞으로 증상 개선을 위해 병원 밖에서도 건강관리를 도와드릴 예정입니다.\n\n재활운동, 생활습관, 치료 일정 외에도 궁금한 점이나 불편한 점이 있으시면 편하게 말씀해주세요^^'
            }
        ],
        smsChatMessageA:
            '**{{병원명}}**\n\n안녕하세요 {{고객명}}님. \n1:1 VIP 맞춤 관리 서비스가 시작되었습니다. \n\n앱을 실행하여 담당 간호사의 메시지를 확인해보세요. \n\n▶확인하기\n{{링크}}',
        smsChatMessageB:
            '**{{병원명}}**\n\n안녕하세요 {{고객명}}님. \n1:1 VIP 맞춤 관리 서비스가 시작되었습니다. \n\n앱을 실행하여 담당 간호사의 메시지를 확인해보세요. \n\n▶확인하기\n{{링크}}',
        smsChatMessage:
            '**{{병원명}}**\n\n안녕하세요 {{고객명}}님. \n1:1 VIP 맞춤 관리 서비스가 시작되었습니다. \n\n앱을 실행하여 담당 간호사의 메시지를 확인해보세요. \n\n▶확인하기\n{{링크}}',
        smsBasicMessage:
            '{{병원명}}\n\n안녕하세요 애프터닥 정형외과입니다.\n{{고객명}}님 반갑습니다.\n\n저희 병원은 환자분의 빠른 쾌유를 위해 간단한 앱 다운로드를 통해 무료로 지속적인 관리를 해드리고 있으니, 진료 시작 전 꼭 다운로드 후 사용해주세요 ^^\n- 간호사가 1:1로 어디서든 환자분 증상에 맞는 재활운동방법 및 궁금하신 내용을 안내해드립니다.\n- 실손보험에 대한 궁금증도 앱으로 문의하세요.\n- 앱을 통해 진료 및 도수치료 예약을 도와드리고 있습니다.\n- 진료후 불편사항을 전달해주시면 개선하도록 하겠습니다.\n앱 다운 및 궁금하신 점은 저희 직원들에게 이야기 해주세요!\n\n▶30초 간편 가입\n{{링크}}',
        ReservationMessage:
            '{{병원명}}\n------------------------\n{{고객명}}님 예약이 완료되었습니다.\n모바일 앱을 통해 예약내역을 확인할 수 있습니다.\n\n치료 후 증상관리 방법 또는 맞춤 재활운동 영상도 보내드릴 예정이니, 아래 링크를 통해 앱을 다운받아주세요.\n\n▶바로가기\n{{링크}}',
        smsResendMessage:
            '{{병원명}}\n------------------------\n{{고객명}}님 안녕하세요.\n\n이전에 가입 문자로 가입이 어려우셨다면 저희 병원을 조금 더 편리하게 이용하실 수 있도록, 다시 한 번 아래 링크로 앱을 미리 다운받아주세요.\n\n▶바로가기\n{{링크}}',
        defaultVCMessage:
            '{{고객명}}님, 원장님께서 증상 완화를 위해 집에서도 쉽게 따라할 수 있는 홈케어 영상을 보냈어요. 아래 바로가기를 눌러 영상을 확인해 보세요!',
        defaultVCSMSMessage:
            '{{병원명}}\n원장님이 {{고객명}}님을 위한 홈케어 영상을 보냈어요!\n병원 치료와 함께 집에서 홈케어를 진행해주시면 증상 개선에 도움이 되실 거예요^^\n아래 링크를 클릭해 지금 바로 확인해보세요!\n\n▶바로가기\n{{링크}}'
    },

    // hospital type == 성형 및 피부과
    PS: {
        initialChat:
            '<애프터닥 뷰티의원>를 방문해주셔서 감사합니다.\n시술 후 관리를 도와드릴 김아연 간호사입니다.\n\n시술 후 관리 및 일정관리 등 문의가 필요하실 경우 편하게 말씀해주세요^^',
        initialChats:[
            {
                chatType: 100,
                content: '<애프터닥 뷰티의원>를 방문해주셔서 감사합니다.\n시술 후 관리를 도와드릴 김아연 간호사입니다.\n\n시술 후 관리 및 일정관리 등 문의가 필요하실 경우 편하게 말씀해주세요^^'
            }
        ],
        smsChatMessageA:
            '**{{병원명}}**\n\n안녕하세요 {{고객명}}님. \n1:1 VIP 맞춤 관리 서비스가 시작되었습니다. \n\n앱을 실행하여 담당 간호사의 메시지를 확인해보세요. \n\n▶확인하기\n{{링크}}',
        smsChatMessageB:
            '**{{병원명}}**\n\n안녕하세요 {{고객명}}님. \n1:1 VIP 맞춤 관리 서비스가 시작되었습니다. \n\n앱을 실행하여 담당 간호사의 메시지를 확인해보세요. \n\n▶확인하기\n{{링크}}',
        smsChatMessage:
            '**{{병원명}}**\n\n안녕하세요 {{고객명}}님. \n1:1 VIP 맞춤 관리 서비스가 시작되었습니다. \n\n앱을 실행하여 담당 간호사의 메시지를 확인해보세요. \n\n▶확인하기\n{{링크}}',
        smsBasicMessage:
            '**{{병원명}}**\n\n원장님 진료 후, 애프터닥 뷰티의원만의 특별 시스템인 "시술 후 1:1 맞춤 관리 프로그램"을 통해 관리를 도와드릴 예정입니다. \n\n보내드린 링크로 접속하셔서 어플을 가입해주시고 문의 사항을 남겨주시면 "애프터닥 뷰티의원 전담 간호사 선생님"께서 보다 자세히 답변 드리겠습니다.\n\n▶가입하기\n{{링크}}',
        ReservationMessage:
            '{{병원명}}\n------------------------\n{{고객명}}님 예약이 완료되었습니다.\n모바일 앱을 통해 예약내역을 확인할 수 있습니다.\n\n진료 후 맞춤 관리와 홈케어 영상도 보내드릴 예정이니, 아래 링크를 통해 앱을 다운받아주세요.\n\n▶바로가기\n{{링크}}',
        smsResendMessage:
            '{{병원명}}\n------------------------\n{{고객명}}님 안녕하세요.\n\n이전에 가입 문자로 가입이 어려우셨다면 저희 병원을 조금 더 편리하게 이용하실 수 있도록, 다시 한 번 아래 링크로 앱을 미리 다운받아주세요.\n\n▶바로가기\n{{링크}}',
        defaultVCMessage: '{{고객명}}님, 원장님께서 홈케어 영상을 보냈어요. 아래 바로가기를 눌러 영상을 확인해 보세요!',
        defaultVCSMSMessage:
            '{{병원명}}\n원장님이 {{고객명}}님을 위해 홈케어 영상을 보냈어요!\n집에서 홈케어를 진행해주시면 빠른 회복에 도움이 되실 거예요^^\n아래 링크를 클릭해 지금 바로 확인해보세요!\n\n▶바로가기\n{{링크}}'
    }
}

export default { popup_hospital_type_message }
