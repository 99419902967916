const state = {
    isShow: false,
    selectedHospital: undefined,
    token: undefined
}

const actions = {
    setAlFeatureConfigPopupState: (store, param) => {
        store.commit('muAlFeatrueConfigPopupState', param)
    }
}

const mutations = {
    muAlFeatrueConfigPopupState: (state, param) => {
        state.isShow = param.isShow
        state.selectedHospital = param.selectedHospital
        state.token = param.selectedHospital?.integrationSetting?.clientToken
    }
}

export default { state, actions, mutations }