<template>
    <div class="pop_overlay_bg" v-show="accountqrcodepopup.isShow == true">
        <div class="account_qacode_pop_wrap">
            <PopupTitleSection title="QR코드 보기" @action_Close="action_closePopup" />
            <div class="pop_cont">
                <table class="table_default">
                    <tr>
                        <td colspan="2"><b>Deferred Deep Link QRCode URL</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <span style="word-break: break-all; overflow-wrap: anywhere">{{ deeplink }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><canvas ref="canvas"></canvas></td>
                    </tr>
                    <tr>
                        <td><b>계정 이름</b></td>
                        <td>{{ hworkerName }}</td>
                    </tr>
                    <tr>
                        <td><b>직책/직급</b></td>
                        <td>{{ jobType }}</td>
                    </tr>
                    <tr>
                        <td><b>계정 ID</b></td>
                        <td>{{ doctorID }}</td>
                    </tr>
                    <tr>
                        <td><b>병원명</b></td>
                        <td>{{ shortName }}</td>
                    </tr>
                    <tr>
                        <td><b>병원 ID</b></td>
                        <td>{{ hospitalID }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import QRCode from 'qrcode'
import PopupTitleSection from '@/views/main/ShareComponents/PopupTitleSection.vue'

export default {
    name: 'accountSettingQRCodePopup',
    data() {
        return {
            shortName: '',
            hospitalID: '',
            hworkerName: '',
            jobType: '',
            doctorID: '',
            env: '',
            env_prefix: '',
            deeplink: ''
        }
    },
    watch: {
        'accountqrcodepopup.isShow': {
            immediate: true,
            handler: function (newValue) {
                if (newValue == true) {
                    // this.loadHWorker()
                    this.initPopup()
                    this.loadDeferredQRCode()
                }
            }
        }
    },
    computed: {
        ...mapState(['accountqrcodepopup'])
    },
    components: {
        PopupTitleSection
    },
    methods: {
        ...mapActions(['setAccountSettingQRCodePopupState']),
        initPopup() {
            if (this.accountqrcodepopup.isShow == true) {
                this.shortName = this.accountqrcodepopup.selectedHospital.shortName
                this.hospitalID = this.accountqrcodepopup.selectedHospital._id
                this.hworkerName = this.accountqrcodepopup.selectedHWorker.name
                this.jobType = this.accountqrcodepopup.selectedHWorker.jobType
                this.doctorID = this.accountqrcodepopup.selectedHWorker._id
            } else {
                this.shortName = ''
                this.hospitalID = ''
                this.hworkerName = ''
                this.jobType = ''
                this.doctorID = ''
            }
        },
        action_closePopup() {
            this.setAccountSettingQRCodePopupState({
                isShow: false
            })
            this.initPopup()
        },
        loadDeferredQRCode() {
            let canvas = this.$refs.canvas

            // live, dev 분기
            if (process.env.NODE_ENV == 'development') {
                this.env = 'dev'
                this.env_prefix = 'dev.api'
            } else if (process.env.NODE_ENV == 'production') {
                this.env = 'production'
                this.env_prefix = 'api'
            }

            console.log(`current env is : ${this.env}`)
            this.deeplink =
                'https://' + this.env_prefix + '.afterdoc.net/api/install/qr?hospitalID=' + this.hospitalID + '&doctorID=' + this.doctorID + '&type=1'
            let url = this.deeplink
            QRCode.toCanvas(canvas, url, { width: 250 }, function (err) {
                if (err != undefined) {
                    console.log(err)
                }
            })
        }
    }
}
</script>
