import axios from 'axios';
import URL from './URL';
import Util from './Util';

// SI API 
const getHospital = async function (m) {
    return axios({
        method: 'GET', 
        url: URL.AL_HOSPITAL_FIND,
        params: m,
    })
}
const createHospitalID = async function (m) {
    return axios({
        method: 'POST',
        url: URL.AL_HOSPITAL_CREATE,
        data: m,
    })
}
const updateHospitalID = async function (m) {
    return axios({
        method: 'POST',
        url: URL.AL_HOSPITAL_UPDATE,
        data: m,
    })
}
const getPatientRequest = async function(m) {
    return axios({
        method: 'GET',
        url: URL.AL_PATIENT_REQUEST,
        params: m
    })
}

const countPatientRequest = async function(m){
    return axios({
        method: 'GET',
        url: URL.AL_PATIENT_REQUEST_COUNT,
        params: m
    })
}

// SD API 
const getAlLabels = async function() {
    return axios({
        method: 'GET',
        url: URL.SD_FIND_LABELS,
        headers: Util.getAlHeaders()
    })
}

// AD API
const getIntegrationSetting = async function(m) {
    return axios({
        method: 'GET',
        url: URL.AL_INTEGRATION_SETTING,
        params: m,
        headers: Util.getHeaders()
    })
}
const createIntegrationSetting = async function(m) {
    return axios({
        method: 'POST',
        url: URL.AL_INTEGRATION_SETTING_CREATE,
        data: m,
        headers: Util.getHeaders()
    })
}
const updateIntegrationSetting = async function(m) {
    return axios({
        method: 'POST',
        url: URL.AL_INTEGRATION_SETTING_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}

export default {
    getAlLabels: getAlLabels, 
    getHospital: getHospital,

    createHospitalID:createHospitalID,
    updateHospitalID: updateHospitalID,

    getPatientRequest: getPatientRequest,
    countPatientRequest: countPatientRequest,

    getIntegrationSetting: getIntegrationSetting,

    createIntegrationSetting: createIntegrationSetting, 
    updateIntegrationSetting: updateIntegrationSetting,
}