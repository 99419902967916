<template>
    <div class="cc">
        <ManagementNavigation />
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
import ManagementNavigation from './Navigation/ManagementNavigation'
export default {
    components: { ManagementNavigation }
}
</script>

<style scoped></style>
