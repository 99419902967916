<template>
    <div class="content_wrap">
        <div class="page_title">
            <span>Default 홈케어 설정</span>
        </div>
        <div class="h_scroll">
            <div class="table_title">
                <p class="dgray_txt">
                    총 <span class="black_txt">{{ sTotalCount }}</span
                    >개
                </p>
                <div class="table_title_btn">
                    <input type="text" class="input_s" v-model="keyword" placeholder="병원명 검색" @keyup.enter="loadPageIndex(0)" />
                </div>
            </div>
            <div class="table_header_fix">
                <!-- default table -->
                <table class="table_default" v-if="sData.length == 0">
                    <thead>
                        <tr>
                            <th>병원명</th>
                            <th>병원 ID</th>
                            <th>생성일</th>
                            <th>첫번째 홈케어 콘텐츠</th>
                            <th>두번째 홈케어 콘텐츠</th>
                            <th></th>
                            <th>공유 기준</th>
                            <th>수정</th>
                        </tr>
                    </thead>
                    <tr>
                        <td colspan="7">데이터 로드중..</td>
                    </tr>
                </table>
                <!-- load table -->
                <table class="table_default" v-if="sData.length > 0">
                    <thead>
                        <tr>
                            <th>병원명</th>
                            <th>병원 ID</th>
                            <th>생성일</th>
                            <th>첫번째 홈케어 콘텐츠</th>
                            <th>두번째 홈케어 콘텐츠</th>
                            <th>공유 기준</th>
                            <th>수정</th>
                        </tr>
                    </thead>
                    <tr v-for="ss in sData" :key="ss._id" :class="{ lgray: ss.isRemoved }">
                        <td v-if="ss.hospitalID.shortName">{{ ss.hospitalID.shortName }}</td>
                        <td v-if="!ss.hospitalID.shortName">-</td>
                        <td v-if="ss.hospitalID._id">{{ ss.hospitalID._id }}</td>
                        <td v-if="!ss.hospitalID._id">-</td>
                        <td v-if="ss.hospitalID.createdAt">{{ ISODatetoDate(ss.hospitalID.createdAt) }}</td>
                        <td v-if="!ss.hospitalID.createdAt">-</td>
                        <td v-if="ss.selectedFirstVCH">{{ ss.selectedFirstVCH.title }}</td>
                        <td v-if="!ss.selectedFirstVCH">-</td>
                        <td v-if="ss.selectedSecondVCH">{{ ss.selectedSecondVCH.title }}</td>
                        <td v-if="!ss.selectedSecondVCH">-</td>
                        <td v-if="ss.vcuBadge">상위 {{ ss.vcuBadge }}%</td>
                        <td v-if="!ss.vcuBadge">상위 -%</td>
                        <td><button class="btn btn_s btn_blue" @click="action_ShowEditPopup(ss)">수정</button></td>
                    </tr>
                </table>
            </div>
            <Pagenation
                v-bind:totalCount="sTotalCount"
                v-bind:currentPage="sCurrentPage"
                v-bind:countPerPage="sCountPerPage"
                v-bind:countPerRow="5"
                v-on:changePage="sChangePage"
            />
        </div>
        <!-- modify popup -->
        <div class="pop_overlay_bg" v-if="popup_isShow">
            <div class="homecare_hospital_pop_wrap">
                <div class="pop_title">
                    <span class="title">병원별 홈케어 콘텐츠 설정</span>
                    <a class="mgl_auto" @click="action_CloseEditPopup()"><v-icon large>mdi-close</v-icon></a>
                </div>
                <div class="pop_cont pop_scroll_wrap">
                    <div class="pop_scroll">
                        <div class="form_list">
                            <div class="title">병원명</div>
                            <div class="cont">{{ popup_hospitalShortName }}</div>
                        </div>
                        <hr />
                        <div class="form_list">
                            <div class="title">
                                병원 종류
                                <br />
                                <small style="word-break: keep-all" class="lgray_txt mgt_10">
                                    자주 사용되는 환자에게 발송할 코멘트 내용 및 공유 기준을 불러옵니다.
                                </small>
                            </div>
                            <div class="cont">
                                <label class="radio_wrap"
                                    ><input type="radio" name="popup_hospitalType" v-model="popup_hospitalType" value="OS" /><span class="checkmark"></span
                                    >OS</label
                                >
                                <label class="radio_wrap"
                                    ><input type="radio" name="popup_hospitalType" v-model="popup_hospitalType" value="PS" /><span class="checkmark"></span
                                    >PS</label
                                >
                                <label class="radio_wrap"
                                    ><input type="radio" name="popup_hospitalType" v-model="popup_hospitalType" value="CUSTOM" />
                                    <span class="checkmark"></span>직접 설정</label
                                >
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">첫번째 홈케어 콘텐츠 선택</div>
                            <div class="cont">
                                <select v-model="popup_selectedFirstVCH" name="" id="" class="input_s w_full">
                                    <option value="">보내지 않음</option>
                                    <option v-for="vch in videoContentHospitals" :key="vch._id" :value="vch._id">{{ vch.title }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                환자에게 전송할 코멘트
                                <small class="lgray_txt mgt_10">
                                    모바일 앱에서 홈케어 콘텐츠 선택시, 상세 페이지에서 표시되는 내용입니다.
                                    <br />
                                    <br />[변환 가능 목록] <br />
                                    - 고객명 : 1번
                                </small>
                            </div>
                            <div class="cont">
                                <textarea
                                    v-model="popup_firstVCUMessage"
                                    :disabled="popup_selectedFirstVCH == undefined || popup_selectedFirstVCH == ''"
                                    rows="5"
                                    class="input_s w_full"
                                    placeholder="입력(앱에서 표시되는 코멘트)"
                                ></textarea>
                                <small class="red_txt mgt_10" v-if="popup_selectedFirstVCH != '' && popup_firstVCUMessage == ''">
                                    {{ popup_firstVCUMessage_ResultMessage }}
                                </small>
                            </div>
                        </div>
                        <hr />
                        <div class="form_list">
                            <div class="title">두번째 홈케어 콘텐츠 선택</div>
                            <div class="cont">
                                <select v-model="popup_selectedSecondVCH" name="" id="" class="input_s w_full">
                                    <option value="">보내지 않음</option>
                                    <option v-for="vch in videoContentHospitals" :key="vch._id" :value="vch._id">{{ vch.title }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                환자에게 전송할 코멘트
                                <small class="lgray_txt mgt_10">
                                    모바일 앱에서 홈케어 콘텐츠 선택시, 상세 페이지에서 표시되는 내용입니다.
                                    <br />
                                    <br />[변환 가능 목록] <br />
                                    - 고객명 : 1번
                                </small>
                            </div>
                            <div class="cont">
                                <textarea
                                    v-model="popup_secondVCUMessage"
                                    :disabled="popup_selectedSecondVCH == undefined || popup_selectedSecondVCH == ''"
                                    rows="5"
                                    class="input_s w_full"
                                    placeholder="입력(앱에서 표시되는 코멘트)"
                                ></textarea>
                                <small class="red_txt mgt_10" v-if="popup_selectedSecondVCH != '' && popup_secondVCUMessage == ''">
                                    {{ popup_secondVCUMessage_ResultMessage }}
                                </small>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">두번째 콘텐츠 발송시간<br /><small class="lgray_txt">(첫번째 콘텐츠 발송시간 기준)</small></div>
                            <div class="cont">
                                <select class="input_s" v-model="popup_sendVCInterval_day">
                                    <option v-for="(d, index) in options_day" :key="index" :value="d.value">+{{ d.text }}일</option>
                                </select>
                                <select class="input_s" v-model="popup_sendVCInterval_hour">
                                    <option v-for="(h, index) in options_hour" :key="index" :value="h.value">{{ h.text }}시</option>
                                </select>
                                <select class="input_s" v-model="popup_sendVCInterval_minute">
                                    <option v-for="(m, index) in options_minute" :key="index" :value="m.value">{{ m.text }}분</option>
                                </select>
                            </div>
                        </div>
                        <hr />
                        <div class="form_list">
                            <div class="title">공유 아이콘 표시</div>
                            <div class="cont">
                                병원에 등록된 앱 사용중인 환자 기준 상위
                                <input v-model="popup_vcuBadge" type="number" class="input_s w_60" placeholder="30" /> % 인 경우 라벨 노출
                            </div>
                        </div>
                        <div>
                            <small class="red_txt mgt_10">
                                {{ popup_vcuBadge_ResultMessage }}
                            </small>
                        </div>
                        <div class="btn_wrap">
                            <button class="btn btn_l btn_blue" @click="action_updateServiceSetting()">등록</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagenation from '../../../ShareComponents/PagenationComponent.vue'
import PageController from '../../../../../controller/PageController'
import moment from '../../../../../util/MPMoment'

export default {
    components: { Pagenation },
    data() {
        return {
            filter: {},

            keyword: '',

            conditioningCategoryID: '',
            videoContentHospitals: [],
            servicesettingPageController: undefined,
            sTotalCount: 0,
            sCurrentPage: 0,
            sCountPerPage: 10,
            sData: [],

            selectedServiceSetting: {},

            popup_hospitalType: '',

            popup_isShow: false,
            popup_hospitalShortName: '',
            popup_selectedFirstVCH: '',
            popup_firstVCUMessage: '',
            popup_firstVCUMessage_ResultMessage: '',
            popup_selectedSecondVCH: '',
            popup_secondVCUMessage: '',
            popup_secondVCUMessage_ResultMessage: '',
            popup_sendVCInterval: '', // ('1970-01-01 00:00:00'), or ('1970-01-01T09:00:00.000+09:00')
            popup_sendVCInterval_day: 0,
            popup_sendVCInterval_hour: 0,
            popup_sendVCInterval_minute: 0,
            popup_vcuBadge: 0,
            popup_vcuBadge_ResultMessage: '',

            options_day: [],
            options_hour: [],
            options_minute: []
        }
    },
    watch: {
        keyword: {
            handler: function (newValue) {
                if (newValue == '') {
                    this.loadPageIndex(0)
                }
            }
        },
        popup_hospitalType: {
            handler: function (newValue) {
                switch (newValue) {
                    case 'OS':
                        this.popup_firstVCUMessage =
                            '{{고객명}}님, 스트레칭을 통해 하루 동안 쌓인 피로를 풀어보시는 건 어떨까요? 전달드린 운동 방법은 통증이 있는 분들도 쉽게 따라 할 수 있어, 몸의 긴장을 푸는데 도움이 되실 거예요! \n동작을 하시면서 불편한 점이 있을 경우, 채팅을 통해 바로 말씀해주세요.:D'
                        this.popup_secondVCUMessage =
                            '{{고객명}}님, 스트레칭을 통해 근육을 깨워 가벼운 하루를 시작해 보시는 건 어떨까요? 전달드린 운동 방법은 통증이 있는 분들도 쉽게 따라 할 수 있어, 몸의 긴장을 푸는데 도움이 되실 거예요! \n동작을 하시면서 불편한 점이 있을 경우, 채팅을 통해 바로 말씀해주세요.'
                        this.popup_vcuBadge = 30
                        break
                    case 'PS':
                        this.popup_firstVCUMessage =
                            '{{고객명}}님, 스트레칭을 통해 하루 동안 쌓인 피로를 풀어보시는 건 어떨까요? 전달드린 운동 방법은 통증이 있는 분들도 쉽게 따라 할 수 있어, 몸의 긴장을 푸는데 도움이 되실 거예요! \n동작을 하시면서 불편한 점이 있을 경우, 채팅을 통해 바로 말씀해주세요.:D'
                        this.popup_secondVCUMessage =
                            '{{고객명}}님, 스트레칭을 통해 근육을 깨워 가벼운 하루를 시작해 보시는 건 어떨까요? 전달드린 운동 방법은 통증이 있는 분들도 쉽게 따라 할 수 있어, 몸의 긴장을 푸는데 도움이 되실 거예요! \n동작을 하시면서 불편한 점이 있을 경우, 채팅을 통해 바로 말씀해주세요.'
                        this.popup_vcuBadge = 30
                        break
                    default:
                        console.log('service setting custom start')
                }
            }
        },
        popup_firstVCUMessage: {
            handler: function (newValue) {
                if (newValue == '') {
                    if (this.popup_selectedFirstVCH != '') {
                        this.popup_firstVCUMessage_ResultMessage = '환자에게 전송할 코멘트를 입력해주세요.'
                    }
                } else {
                    this.popup_firstVCUMessage_ResultMessage = ''
                }
            }
        },
        popup_secondVCUMessage: {
            handler: function (newValue) {
                if (newValue == '') {
                    if (this.popup_selectedSecondVCH != '') {
                        this.popup_secondVCUMessage_ResultMessage = '환자에게 전송할 코멘트를 입력해주세요.'
                    }
                } else {
                    this.popup_secondVCUMessage_ResultMessage = ''
                }
            }
        },
        popup_vcuBadge: {
            handler: function (newValue) {
                if (newValue > 99 || newValue < 1) {
                    this.popup_vcuBadge_ResultMessage = '공유 아이콘 표시 입력값은 1~99 까지 입력 가능합니다.'
                } else {
                    this.popup_vcuBadge_ResultMessage = ''
                }
            }
        }
    },
    mounted() {
        // set edit popup time day, hour, minute options
        // start with 1 day
        for (let i = 1; i < 31; i++) {
            this.options_day.push({
                value: i,
                text: i
            })
        }
        for (let i = 0; i < 24; i++) {
            var hour = String(i)
            if (i < 10) {
                hour = '0'.concat(i)
            }
            this.options_hour.push({
                value: i,
                text: hour
            })
        }
        // 00, 30 min only
        for (let i = 0; i < 60; i += 30) {
            var minute = String(i)
            if (i < 10) {
                minute = '0'.concat(i)
            }
            this.options_minute.push({
                value: i,
                text: minute
            })
        }

        this.servicesettingPageController = new PageController(this.API.ServiceSetting.getServiceSettings)
        this.servicesettingPageController.countPerPage = this.sCountPerPage
        this.getConditioningCategory()
        this.loadPageIndex(this.sCurrentPage)
    },
    methods: {
        // reload Data
        initData() {
            this.loadPageIndex(this.sCurrentPage)
        },

        async loadPageIndex(page) {
            this.sCurrentPage = page
            this.servicesettingPageController.filter = {}
            if (this.keyword) {
                this.servicesettingPageController.filter.keyword = this.keyword
            }
            await this.servicesettingPageController.loadPageIndex(page)
            await this.loadTotalServiceSetting()
            this.sData = this.servicesettingPageController.data
        },

        async loadTotalServiceSetting() {
            let result = await this.API.ServiceSetting.count(this.servicesettingPageController.filter)
            console.log(result.data.data)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.sTotalCount = result.data.data
        },

        sChangePage(page) {
            this.sCurrentPage = page
            this.loadPageIndex(this.sCurrentPage)
        },

        // conditioning dropdown
        async loadVCHospitals() {
            let hospitalID = this.selectedServiceSetting.hospitalID._id
            // load conditioning vchs only
            let model = {
                hospitalID: hospitalID,
                videoContentCategoryID: this.conditioningCategoryID
            }
            let result = await this.API.VideoContentHospital.find(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)
            this.videoContentHospitals = result.data.data
            this.$forceUpdate()
        },

        async getConditioningCategory() {
            let model = { isConditioning: true }
            let result = await this.API.VideoContentCategory.findconditioning(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            let conditioningCategory = result.data.data[0]
            console.log(conditioningCategory)
            this.conditioningCategoryID = conditioningCategory._id
        },

        action_initPopup() {
            // TODO : initPopup
            this.popup_hospitalShortName = ''
            this.popup_selectedFirstVCH = ''
            this.popup_firstVCUMessage = ''
            this.popup_selectedSecondVCH = ''
            this.popup_secondVCUMessage = ''
            this.popup_sendVCInterval = ''
            this.popup_sendVCInterval_day = 0
            this.popup_sendVCInterval_hour = 0
            this.popup_sendVCInterval_minute = 0
            this.popup_vcuBadge = 30
        },

        action_ShowEditPopup(serviceSetting) {
            this.action_initPopup()
            console.log('serviceSetting is:')
            console.log(serviceSetting)

            this.selectedServiceSetting = serviceSetting
            this.loadVCHospitals()
            // set edit popup data
            this.popup_hospitalShortName = serviceSetting.hospitalID.shortName
            if (serviceSetting.selectedFirstVCH) {
                this.popup_selectedFirstVCH = serviceSetting.selectedFirstVCH._id
            }
            if (serviceSetting.selectedSecondVCH) {
                this.popup_selectedSecondVCH = serviceSetting.selectedSecondVCH._id
            }
            this.popup_firstVCUMessage = serviceSetting.firstVCUMessage
            this.popup_secondVCUMessage = serviceSetting.secondVCUMessage

            // 실제 값과 표기 값 일치를 위한 +1
            this.popup_sendVCInterval = moment(serviceSetting.sendVCInterval).add(1, 'days')

            // convert sendVCInterval to each dropdown values
            const DAY_TO_SECONDS = 86400
            const HOUR_TO_SECONDS = 3600
            const MINUTE_TO_SECONDS = 60

            let convertedInterval = this.popup_sendVCInterval.unix()

            this.popup_sendVCInterval_day = parseInt(convertedInterval / DAY_TO_SECONDS)
            this.popup_sendVCInterval_hour = parseInt((convertedInterval % DAY_TO_SECONDS) / HOUR_TO_SECONDS)
            this.popup_sendVCInterval_minute = parseInt((convertedInterval % HOUR_TO_SECONDS) / MINUTE_TO_SECONDS)
            this.popup_vcuBadge = serviceSetting.vcuBadge
            this.popup_isShow = true
        },

        action_CloseEditPopup() {
            this.popup_isShow = false
        },

        async action_updateServiceSetting() {
            if (this.popup_selectedFirstVCH != '' && this.popup_firstVCUMessage == '') {
                this.popup_firstVCUMessage_ResultMessage = '환자에게 전송할 코멘트를 입력해주세요.'
                return
            }

            if (this.popup_selectedSecondVCH != '' && this.popup_secondVCUMessage == '') {
                this.popup_secondVCUMessage_ResultMessage = '환자에게 전송할 코멘트를 입력해주세요.'
                return
            }

            if (this.popup_vcuBadge > 99 || this.popup_vcuBadge < 1) {
                this.popup_vcuBadge_ResultMessage = '공유 아이콘 표시 입력값은 1~99 까지 입력 가능합니다.'
                return
            }

            // convert unix timestamp to date
            const DAY_TO_SECONDS = 86400
            const HOUR_TO_SECONDS = 3600
            const MINUTE_TO_SECONDS = 60

            let calcDay = this.popup_sendVCInterval_day * DAY_TO_SECONDS
            let calcHour = this.popup_sendVCInterval_hour * HOUR_TO_SECONDS
            let calcMinute = this.popup_sendVCInterval_minute * MINUTE_TO_SECONDS
            this.popup_sendVCInterval = calcDay + calcHour + calcMinute

            // 저장 시 표기 값에서 -1 하여 실제 값으로 매칭.
            let sendVCInterval = moment.unix(this.popup_sendVCInterval).add(-1, 'days').toDate()

            let model = {
                _id: this.selectedServiceSetting._id,
                hospitalID: this.selectedServiceSetting.hospitalID,
                sendVCInterval: sendVCInterval
            }

            model.vcuBadge = parseInt(Number(this.popup_vcuBadge))
            if (this.popup_vcuBadge == '') {
                model.vcuBadge = 10
            }

            if (this.popup_selectedFirstVCH != undefined) {
                model.selectedFirstVCH = this.popup_selectedFirstVCH
            }
            if (this.popup_firstVCUMessage != undefined) {
                model.firstVCUMessage = this.popup_firstVCUMessage
            }
            if (this.popup_selectedSecondVCH != undefined) {
                model.selectedSecondVCH = this.popup_selectedSecondVCH
            }
            if (this.popup_secondVCUMessage != undefined) {
                model.secondVCUMessage = this.popup_secondVCUMessage
            }

            let result = await this.API.ServiceSetting.updateServiceSetting(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data)

            this.initData()
            this.action_CloseEditPopup()
        },

        // TODO : make these methods below to common function..? + Vue.mixin?
        ISODatetoDate(date) {
            let modifiedDate = moment(date).format('YYYY-MM-DD HH:mm')
            return modifiedDate
        }
    }
}
</script>
