<template>
    <div class="content_wrap">
        <LoadingIndicate :isLoading="isLoading" />
        <div class="page_title">
            <span>카테고리 설정</span>
        </div>
        <small class="red_txt mgt_10" v-show="isErrorMessage == true">
            {{ errorMessage }}
        </small>
        <button class="btn_xs btn btn_blue mgl_auto" @click="action_copyAllVCH()">모든 카테고리 및 병원 업데이트</button>
        <div class="category_set_wrap lgray">
            <!-- load hospitals -->
            <div class="category_set">
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">병원 선택</span>
                        <input type="text" class="input_xs mgl_auto w_150" placeholder="병원명 검색" v-model="searchKeyword" @keyup.enter="loadHospitals()" />
                    </div>
                    <div class="form_flex_cont">
                        <div class="h_scroll_fix">
                            <table class="table_default" v-if="hospitals.length > 0">
                                <tr :class="{ on: selectedHospitalID == null }" value="null" @click="loadTopCategories()">
                                    <td>❗전체</td>
                                </tr>
                                <tr
                                    v-for="h in hospitals"
                                    :key="h._id"
                                    :value="h._id"
                                    :class="{ on: h._id == selectedHospitalID }"
                                    @click="loadTopCategoriesByHospital(h._id)"
                                >
                                    <td>{{ h.shortName }}</td>
                                </tr>
                            </table>
                            <!-- default, empty hospitals -->
                            <table class="table_default" v-if="hospitals.length == 0">
                                <tr v-if="searchKeyword == ''">
                                    데이터 로드 중..
                                </tr>
                                <tr v-if="searchKeyword != ''">
                                    <small class="lgray_txt"> '{{ searchKeyword }}' 와 일치하는 검색 결과가 없습니다. </small>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- load top categories -->
            <div class="category_set">
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">대 카테고리</span>
                        <button
                            v-if="topCategories.length > 0 && selectedHospitalServiceSetting == null"
                            class="btn_xs btn btn_blue mgl_auto"
                            @click="action_ShowCreateVCCategoryPopup(CategoryEnum.addTop)"
                        >
                            추가
                        </button>
                        <button
                            v-if="topCategories.length > 0 && selectedHospitalServiceSetting != null"
                            class="btn_xs btn btn_blue mgl_auto"
                            @click="action_ShowCreateVCCategoryPopup(CategoryEnum.addTopCustomize)"
                        >
                            병원 전용 추가
                        </button>
                    </div>
                    <div class="form_flex_cont">
                        <div class="h_scroll_fix">
                            <!-- default top categories -->
                            <table class="table_default" v-if="topCategories.length == 0">
                                <tr>
                                    <td>생성된 카테고리 없음</td>
                                </tr>
                            </table>
                            <!-- load top categories - global -->
                            <table class="table_default" v-if="topCategories.length > 0 && selectedHospitalServiceSetting == null">
                                <tr>
                                    <b>공통</b>
                                </tr>
                                <tr
                                    v-for="tc in topCategories"
                                    :key="tc._id"
                                    :value="tc._id"
                                    :class="{
                                        on: tc._id == selectedTopCategory,
                                        lgray: tc.isHidden
                                    }"
                                    @click="loadParentCategories(tc._id)"
                                >
                                    <td>{{ tc.categoryName }}</td>
                                    <td class="txt_right">
                                        <!-- <button class="btn_xs btn_gray_line btn only_icon">
                                            <v-icon>mdi-chevron-up</v-icon>
                                        </button>
                                        <button class="btn_xs btn_gray_line btn only_icon mgl_5">
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </button> -->
                                        <button class="btn_xs btn_blue_line btn" @click="action_ShowEditVCCategoryPopup(CategoryEnum.editTop, tc)">수정</button>
                                        <!-- <label :for="tc._id" class="switch_wrap mgl_10">
                                            <input type="checkbox" :id="tc._id" v-model="tc.isHidden" 
                                                true-value="false" false-value="true" @change="action_updateHidden(tc)">
                                            <span class="slider round"></span>
                                        </label> -->
                                        <button class="btn_xs btn_gray btn" @click="action_copyByGlobalCategory(tc)">전체 병원 업데이트</button>
                                    </td>
                                </tr>
                            </table>
                            <!-- load top categories - hospital -->
                            <table class="table_default" v-if="topCategories.length > 0 && selectedHospitalServiceSetting != null">
                                <tr>
                                    <b>공통</b>
                                </tr>
                                <tr
                                    v-for="tc in topCategories"
                                    :key="tc._id"
                                    :value="tc._id"
                                    :class="{
                                        on: tc._id == selectedTopCategory,
                                        lgray: tc.isHidden
                                    }"
                                    @click="loadParentCategories(tc._id)"
                                >
                                    <td>{{ tc.categoryName }}</td>
                                    <td class="txt_right">
                                        <label :for="tc._id" class="switch_wrap mgl_10">
                                            <input
                                                type="checkbox"
                                                :id="tc._id"
                                                v-model="tc.isSelected"
                                                :disabled="tc.isHidden == true"
                                                @change="action_updateIsSelected(tc)"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <b>전용</b>
                                </tr>
                                <tr
                                    v-for="htc in hospitalTopCategories"
                                    :key="htc._id"
                                    :value="htc._id"
                                    :class="{
                                        on: htc._id == selectedTopCategory,
                                        lgray: htc.isHidden
                                    }"
                                    @click="loadParentCategories(htc._id)"
                                >
                                    <td>{{ htc.categoryName }}</td>
                                    <td class="txt_right">
                                        <button class="btn_xs btn_gray_line btn only_icon">
                                            <v-icon>mdi-chevron-up</v-icon>
                                        </button>
                                        <button class="btn_xs btn_gray_line btn only_icon mgl_5">
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </button>
                                        <button class="btn_xs btn_blue_line btn" @click="action_ShowEditVCCategoryPopup(CategoryEnum.editTopCustomize, htc)">
                                            수정
                                        </button>
                                        <label :for="htc._id" class="switch_wrap mgl_10">
                                            <input type="checkbox" :id="htc._id" v-model="htc.isSelected" @change="action_updateIsSelected(htc)" />
                                            <span class="slider round"></span
                                        ></label>
                                        <button class="btn_xs btn_gray btn" @click="action_copyByHospitalCategory(htc)">복사</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- load parent categories -->
            <div class="category_set">
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">중 카테고리</span>
                        <button class="btn_xs btn btn_blue mgl_auto" @click="action_ShowCreateVCCategoryPopup(CategoryEnum.addParent)">추가</button>
                    </div>
                    <div class="form_flex_cont">
                        <div class="h_scroll_fix">
                            <div v-if="parentCategories.length > 0 && parentCategories[0].hospitalID == selectedHospitalID" class="form_list mgb_15 middle">
                                <div class="title">명칭</div>
                                <div class="cont txt_right">
                                    <input type="text" class="input_s w_150" placeholder="입력" value="부위" v-model="parentTypename" />
                                    <button class="btn btn_s btn_blue mgl_5" @click="action_ShowParentTypenamePopup()">저장</button>
                                </div>
                            </div>
                            <!-- default parent categories -->
                            <table class="table_default" v-if="parentCategories.length == 0 && selectedTopCategory == ''">
                                <tr>
                                    <td>카테고리 대 선택</td>
                                </tr>
                            </table>
                            <!-- load parent categories but with no data -->
                            <table class="table_default" v-if="parentCategories.length == 0 && selectedTopCategory != ''">
                                <tr>
                                    <td>생성된 카테고리 없음</td>
                                </tr>
                            </table>
                            <!-- load parent categories -->
                            <table class="table_default" v-if="parentCategories.length > 0">
                                <tr
                                    v-for="pc in parentCategories"
                                    :key="pc._id"
                                    :value="pc._id"
                                    :class="{
                                        on: pc._id == selectedParentCategory,
                                        lgray: pc.isHidden
                                    }"
                                    @click="loadChildCategories(pc._id)"
                                >
                                    <td>{{ pc.categoryName }}</td>
                                    <td v-if="pc.hospitalID == selectedHospitalID" class="txt_right">
                                        <button class="btn_xs btn_gray_line btn only_icon">
                                            <v-icon>mdi-chevron-up</v-icon>
                                        </button>
                                        <button class="btn_xs btn_gray_line btn only_icon mgl_5">
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </button>
                                        <button class="btn_xs btn_blue_line btn" @click="action_ShowEditVCCategoryPopup(CategoryEnum.editParent, pc)">
                                            수정
                                        </button>
                                        <label :for="pc._id" class="switch_wrap mgl_10">
                                            <input
                                                type="checkbox"
                                                :id="pc._id"
                                                v-model="pc.isHidden"
                                                true-value="false"
                                                false-value="true"
                                                @change="action_updateHidden(pc)"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- load child categories -->
            <div class="category_set">
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <span class="title">소 카테고리</span>
                        <button class="btn_xs btn btn_blue mgl_auto" @click="action_ShowCreateVCCategoryPopup(CategoryEnum.addChild)">추가</button>
                    </div>
                    <div class="form_flex_cont">
                        <div class="h_scroll_fix">
                            <div v-if="childCategories.length > 0 && childCategories[0].hospitalID == selectedHospitalID" class="form_list mgb_15 middle">
                                <div class="title">명칭</div>
                                <div class="cont txt_right">
                                    <input type="text" class="input_s w_150" placeholder="입력" value="난이도" v-model="childTypename" />
                                    <button class="btn btn_s btn_blue mgl_5" @click="action_ShowChildTypenamePopup()">저장</button>
                                </div>
                            </div>
                            <!-- default child categories -->
                            <table class="table_default" v-if="childCategories.length == 0 && selectedParentCategory == ''">
                                <tr>
                                    <td>카테고리 중 선택</td>
                                </tr>
                            </table>
                            <!-- load child categories but with no data -->
                            <table class="table_default" v-if="childCategories.length == 0 && selectedParentCategory != ''">
                                <tr>
                                    <td>생성된 카테고리 없음</td>
                                </tr>
                            </table>
                            <!-- load child categories -->
                            <table v-if="childCategories.length > 0" class="table_default">
                                <tr
                                    v-for="cc in childCategories"
                                    :key="cc._id"
                                    :value="cc._id"
                                    :class="{
                                        on: cc._id == selectedChildCategory,
                                        lgray: cc.isHidden
                                    }"
                                    @click="setChildCategory(cc)"
                                >
                                    <!-- 기초 콘텐츠로 설정한 경우, 수정/순서 변경하지 못하도록 버튼 없음 처리 -->
                                    <td v-if="cc.isBasic == true">
                                        {{ cc.categoryName }}
                                        <label class="label lblue">기초</label>
                                    </td>
                                    <td v-if="cc.isBasic == true && selectedHospitalID == null" class="txt_right">
                                        <button class="btn_xs btn_blue_line btn" @click="action_ShowEditVCCategoryPopup(CategoryEnum.editChild, cc)">
                                            수정
                                        </button>
                                    </td>
                                    <td v-if="cc.isBasic == true && selectedHospitalID != null" class="txt_right"></td>
                                    <!-- 기초 콘텐츠 외에는 순서/이름 수정 가능 -->
                                    <td v-if="cc.isBasic != true">{{ cc.categoryName }}</td>
                                    <td v-if="cc.isBasic != true && cc.hospitalID == selectedHospitalID" class="txt_right">
                                        <button class="btn_xs btn_gray_line btn only_icon">
                                            <v-icon>mdi-chevron-up</v-icon>
                                        </button>
                                        <button class="btn_xs btn_gray_line btn only_icon mgl_5">
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </button>
                                        <button class="btn_xs btn_blue_line btn" @click="action_ShowEditVCCategoryPopup(CategoryEnum.editChild, cc)">
                                            수정
                                        </button>
                                        <label :for="cc._id" class="switch_wrap mgl_10">
                                            <input
                                                type="checkbox"
                                                :id="cc._id"
                                                v-model="cc.isHidden"
                                                true-value="false"
                                                false-value="true"
                                                @change="action_updateHidden(cc)"
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 카테고리 추가/수정 팝업 -->
        <div class="pop_overlay_bg" v-if="popup_isShow">
            <div class="add_category_pop_wrap">
                <div class="pop_title">
                    <span class="title">{{ popup_title }}</span>
                    <a class="mgl_auto" @click="action_CloseVCCategoryPopup()"><v-icon large>mdi-close</v-icon></a>
                </div>
                <div class="pop_cont">
                    <div class="form_list">
                        <div class="title">카테고리명</div>
                        <div class="cont">
                            <input type="text" class="input_s w_full" placeholder="입력" v-model="popup_categoryName" />
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">설명</div>
                        <div class="cont">
                            <input type="text" class="input_s w_full" placeholder="예) 메디팔 의원 전용" v-model="popup_description" />
                        </div>
                    </div>
                    <div class="form_list">
                        <div class="title">명칭</div>
                        <!-- // TODO : v-if by popup_state -->
                        <div class="cont">
                            <input type="text" class="input_s w_full" placeholder="예)부위, 난이도" v-model="popup_typeName" />
                        </div>
                    </div>
                    <!-- isBasic checkbox input -->
                    <div class="form_list" v-if="popup_state == CategoryEnum.addChild || popup_state == CategoryEnum.editChild">
                        <div class="title">기초 여부</div>
                        <div class="cont">
                            <label class="check_wrap">
                                <input
                                    type="checkbox"
                                    :disabled="popup_child_isBasicSelected == true && popup_child_isBasic == false"
                                    name="popup_child_isBasic"
                                    v-model="popup_child_isBasic"
                                />
                                <span class="checkmark"></span>ㅤ
                                <!-- 정렬용 공백 특수문자 -->
                            </label>
                        </div>
                    </div>
                </div>
                <div class="btn_wrap">
                    <button v-if="isAddPopup()" class="btn btn_blue btn_l" @click="action_createVCCategory()">등록</button>
                    <button v-if="!isAddPopup()" class="btn btn_blue btn_l" @click="action_editVCCategory()">수정</button>
                </div>
            </div>
        </div>
        <CategorySettingParentTypenamePopup
            v-bind:selectedTopCategory="selectedTopCategory"
            v-bind:parentCategories="parentCategories"
            v-bind:typeName="parentTypename"
            v-on:loadParentCategories="loadParentCategories"
        />

        <CategorySettingChildTypenamePopup
            v-bind:selectedParentCategory="selectedParentCategory"
            v-bind:childCategories="childCategories"
            v-bind:typeName="childTypename"
            v-on:loadChildCategories="loadChildCategories"
        />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import categoryEnum from '../../../../../enum/VideoContentCategoryEnum'
import CategorySettingParentTypenamePopup from './Components/CategorySettingParentTypenamePopup'
import CategorySettingChildTypenamePopup from './Components/CategorySettingChildTypenamePopup'
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'

export default {
    components: {
        CategorySettingParentTypenamePopup,
        CategorySettingChildTypenamePopup,
        LoadingIndicate
    },
    data() {
        return {
            CategoryEnum: categoryEnum,

            hospitals: [],
            topCategories: [],
            hospitalTopCategories: [],
            parentCategories: [],
            childCategories: [],
            selectedHospitalID: null,
            selectedHospitalServiceSetting: null,
            selectedVCCategoryID: '',
            selectedTopCategory: '',
            selectedParentCategory: '',
            selectedChildCategory: '',
            searchKeyword: '',

            parentTypename: '',
            childTypename: '',

            isLoading: false,

            isErrorMessage: false,
            errorMessage: '',

            popup_title: '',
            popup_isShow: false,
            popup_isGlobal: false,
            popup_state: 0, // state by enum

            popup_categoryName: '',
            popup_hospitalID: '',
            // popup_topCategory: '',
            // popup_parentCategory: '',
            // popup_childCategory: '',
            popup_parentCategoryIDs: [],
            popup_categoryIDforSortNum: '',
            popup_isTop: false,
            popup_isParent: false,
            popup_description: '',
            popup_typeName: '',
            popup_sortNum: 0 // sort - order by sortNum, desc, findOne,
        }
    },
    watch: {
        hospitals: {
            handler: function () {
                // do nothing
            }
        },
        searchKeyword: {
            handler: function (newValue) {
                if (newValue == '') {
                    this.loadHospitals()
                }
            }
        },
        topCategories: {
            handler: function () {
                // do nothing
            }
        },
        parentCategories: {
            handler: function () {
                // do nothing
            }
        },
        childCategories: {
            handler: function () {
                // do nothing
            }
        },
        selectedHospitalServiceSetting: {
            deep: true,
            handler: function () {
                // inf loop?
                // this.loadTopCategoriesByHospital(this.selectedHospitalID)
            }
        },
        selectedHospitalID: {
            handler: function () {
                // do nothing
            }
        },
        selectedTopCategory: {
            handler: function () {
                // do nothing
            }
        },
        selectedParentCategory: {
            handler: function () {
                // do nothing
            }
        },
        selectedChildCategory: {
            handler: function () {
                // do nothing
            }
        },
        parentTypename: {
            handler: function () {
                this.isErrorMessage = false
                this.errorMessage = ''
            }
        },
        childTypename: {
            handler: function () {
                this.isErrorMessage = false
                this.errorMessage = ''
            }
        }
    },
    computed: {
        ...mapState(['categorysettingparenttypenamepopup', 'categorysettingchildtypenamepopup'])
    },
    mounted() {
        this.loadHospitals()
        this.loadTopCategories()
    },
    methods: {
        ...mapActions(['setCategorySettingParentTypenamePopupState', 'setCategorySettingChildTypenamePopupState']),
        initData() {
            this.loadHospitals()
            this.loadTopCategories()
        },
        // load hospitals || find by name/shortName
        async loadHospitals() {
            this.isErrorMessage = false
            let model = {}
            if (this.searchKeyword) {
                model.keyword = this.searchKeyword
            }
            let result = await this.API.Hospital.getHospitals(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)
            this.hospitals = result.data.data
            this.$forceUpdate()
        },

        async loadTopCategories() {
            this.isLoading = true
            this.isErrorMessage = false
            this.selectedHospitalServiceSetting = null
            this.selectedHospitalID = null
            let model = {}
            let result = await this.API.VideoContentCategory.findtop(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)
            this.topCategories = result.data.data
            this.$forceUpdate()
            // init previous selection
            this.selectedTopCategory = ''
            this.parentCategories = []
            this.selectedParentCategory = ''
            this.childCategories = []
            this.selectedChildCategory = ''
            this.isLoading = false
        },

        async loadTopCategoriesByHospital(hospitalID) {
            this.isLoading = true
            this.isErrorMessage = false

            this.selectedHospitalServiceSetting = null
            if (hospitalID != undefined && hospitalID != '') {
                this.selectedHospitalID = hospitalID
            } else {
                this.selectedHospitalID = ''
            }

            // find servicesetting. if empty, set errorMessage
            let sModel = {}
            sModel.hospitalID = this.selectedHospitalID
            let serviceSetting = await this.API.ServiceSetting.getServiceSettingDetail(sModel)
            if (serviceSetting.data.data == null) {
                this.isErrorMessage = true
                this.errorMessage = '선택한 병원의 서비스 설정이 없습니다.'
            }

            let model = { hospitalID: this.selectedHospitalID }

            // call category twice, concat, set
            let globalTCResult = await this.API.VideoContentCategory.findtop()
            if (globalTCResult.data.code != 0) {
                console.log(globalTCResult)
                this.isLoading = false
                return
            }
            console.log(globalTCResult.data.data)
            this.topCategories = globalTCResult.data.data

            let hospitalTCResult = await this.API.VideoContentCategory.findtop(model)
            if (hospitalTCResult.data.code != 0) {
                console.log(hospitalTCResult)
                this.isLoading = false
                return
            }
            console.log(hospitalTCResult.data.data)

            this.hospitalTopCategories = hospitalTCResult.data.data

            // create serviceSetting if selectedHospital does not have
            if (this.selectedHospitalID) {
                let sModel = {}
                sModel.hospitalID = this.selectedHospitalID
                let serviceSetting = await this.API.ServiceSetting.getServiceSettingDetail(sModel)
                if (serviceSetting.data.code != 0) {
                    console.log(serviceSetting)
                    this.isLoading = false
                    return
                }
                let topVCCategories = []

                if (serviceSetting.data.data != null) {
                    topVCCategories = serviceSetting.data.data.topVCCategories
                    // if topVCCategories is empty, fill and update.
                    if (Array.isArray(topVCCategories) && topVCCategories.length < 1) {
                        let initTopVCCategories = []
                        let initVideoContentDuration = []

                        // add global topVCC
                        for (let i = 0; i < this.topCategories.length; i++) {
                            let topVccElem = {
                                categoryID: this.topCategories[i]._id,
                                // categoryName: this.topCategories[i].categoryName,
                                sortNum: i + 1,
                                isSelected: false
                            }

                            // set default doPerWeek, duration to 3, 2
                            let vcDurationElem = {
                                categoryID: this.topCategories[i]._id,
                                doPerWeek: 3,
                                duration: 2
                            }
                            initTopVCCategories.push(topVccElem)
                            initVideoContentDuration.push(vcDurationElem)
                        }

                        // add hospital-specific topVCC
                        for (let i = 0; i < this.hospitalTopCategories.length; i++) {
                            let htopVccElem = {
                                categoryID: this.hospitalTopCategories[i]._id,
                                categoryName: this.hospitalTopCategories[i].categoryName,
                                sortNum: i + 1,
                                isSelected: false
                            }

                            // set default doPerWeek, duration to 3, 2
                            let htopVcDurationElem = {
                                categoryID: this.hospitalTopCategories[i]._id,
                                doPerWeek: 3,
                                duration: 2
                            }
                            initTopVCCategories.push(htopVccElem)
                            initVideoContentDuration.push(htopVcDurationElem)
                        }

                        let ssModel = {
                            _id: serviceSetting.data.data._id.toString(),
                            hospitalID: this.selectedHospitalID,
                            topVCCategories: initTopVCCategories,
                            videoContentDuration: initVideoContentDuration
                        }
                        let ssResult = await this.API.ServiceSetting.updateServiceSetting(ssModel)
                        if (ssResult.data.code != 0) {
                            console.log(ssResult)
                            this.isLoading = false
                            return
                        }
                        console.log(ssResult.data.data)
                        this.selectedHospitalServiceSetting = ssResult.data.data
                    } else {
                        // 전체 카테고리 수와 맞지 않는 경우 전체+병원전용 카테고리 조회 후 현재 topVCCategories와 비교.
                        if (this.topCategories.length + this.hospitalTopCategories.length != topVCCategories.length) {
                            let topCategoryIDs = this.topCategories.map((category) => category._id)
                            let hospitalTopCategoryIDs = this.hospitalTopCategories.map((category) => category._id)
                            hospitalTopCategoryIDs = hospitalTopCategoryIDs.concat(topCategoryIDs)
                            let selectedHospitaltopVCCategoryIDs = topVCCategories.map((category) => category.categoryID)

                            let needToPushTopCategoryIDs = hospitalTopCategoryIDs.filter(function (categoryID) {
                                return !selectedHospitaltopVCCategoryIDs.includes(categoryID)
                            })
                            // 빠진 내용 push.
                            if (needToPushTopCategoryIDs.length > 0) {
                                let modifiedTopVCCategories = serviceSetting.data.data.topVCCategories
                                let modifiedVideoContentDuration = serviceSetting.data.data.videoContentDuration
                                console.log(modifiedTopVCCategories)
                                console.log(modifiedVideoContentDuration)
                                for (let i = 0; i < needToPushTopCategoryIDs.length; i++) {
                                    let topVccElem = {
                                        categoryID: needToPushTopCategoryIDs[i],
                                        sortNum: 999,
                                        isSelected: false
                                    }

                                    // set default doPerWeek, duration to 3, 2
                                    let vcDurationElem = {
                                        categoryID: needToPushTopCategoryIDs[i],
                                        doPerWeek: 3,
                                        duration: 2
                                    }
                                    modifiedTopVCCategories.push(topVccElem)
                                    modifiedVideoContentDuration.push(vcDurationElem)
                                }
                                let ssModel = {
                                    _id: serviceSetting.data.data._id.toString(),
                                    hospitalID: this.selectedHospitalID,
                                    topVCCategories: modifiedTopVCCategories,
                                    videoContentDuration: modifiedVideoContentDuration
                                }

                                let ssResult = await this.API.ServiceSetting.updateServiceSetting(ssModel)
                                if (ssResult.data.code != 0) {
                                    console.log(ssResult)
                                    this.isLoading = false
                                    return
                                }
                                console.log(ssResult.data.data)
                                this.selectedHospitalServiceSetting = ssResult.data.data
                                topVCCategories = this.selectedHospitalServiceSetting.topVCCategories
                            }
                            console.log(needToPushTopCategoryIDs)
                        }
                        this.selectedHospitalServiceSetting = serviceSetting.data.data

                        // update isSelected value for each this.topCategories
                        for (let i = 0; i < this.topCategories.length; i++) {
                            for (let j = 0; j < topVCCategories.length; j++) {
                                if (this.topCategories[i]._id.toString() == topVCCategories[j].categoryID.toString()) {
                                    if (topVCCategories[j].isSelected == true) {
                                        this.topCategories[i].isSelected = true
                                    } else {
                                        this.topCategories[i].isSelected = false
                                    }
                                }
                            }
                        }
                        // update isSelected value for each this.hospitalTopCategories
                        for (let i = 0; i < this.hospitalTopCategories.length; i++) {
                            for (let j = 0; j < topVCCategories.length; j++) {
                                if (this.hospitalTopCategories[i]._id.toString() == topVCCategories[j].categoryID.toString()) {
                                    if (topVCCategories[j].isSelected == true) {
                                        this.hospitalTopCategories[i].isSelected = true
                                    } else {
                                        this.hospitalTopCategories[i].isSelected = false
                                    }
                                }
                            }
                        }
                    }
                } else {
                    console.log('service Setting missing') // should not reach here
                }
            }
            this.$forceUpdate()

            // init previous selection
            this.selectedTopCategory = ''
            this.parentCategories = []
            this.selectedParentCategory = ''
            this.childCategories = []
            this.selectedChildCategory = ''
            this.isLoading = false
        },

        async loadParentCategories(topCategoryID) {
            if (topCategoryID != undefined && topCategoryID != '') {
                this.selectedTopCategory = topCategoryID
            } else {
                // TODO : set error message?
                this.selectedTopCategory = ''
                return
            }

            let model = {}
            if (this.selectedTopCategory) {
                model.parentCategoryIDs = this.selectedTopCategory
            }
            model.isTop = false
            model.isParent = true
            let result = await this.API.VideoContentCategory.find(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)
            this.parentCategories = result.data.data

            // init previous selection
            this.selectedParentCategory = ''
            this.childCategories = []
            this.selectedChildCategory = ''
            this.parentTypename = ''
            // show current typeName
            if (this.parentCategories != undefined && this.parentCategories != '' && this.parentCategories.length > 0) {
                this.parentTypename = this.parentCategories[0].typeName
            }
            this.childTypename = ''
            this.$forceUpdate()
        },

        async loadChildCategories(parentCategoryID) {
            if (parentCategoryID != undefined && parentCategoryID != '') {
                this.selectedParentCategory = parentCategoryID
            } else {
                // TODO : set error message?
                this.selectedParentCategory = ''
                return
            }

            let model = {}
            if (this.selectedParentCategory) {
                model.parentCategoryIDs = this.selectedParentCategory
            }
            model.isTop = false
            model.isParent = false
            let result = await this.API.VideoContentCategory.find(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)
            this.childCategories = result.data.data
            // init previous selection
            this.selectedChildCategory = ''
            this.childTypename = ''

            // show current typeName
            if (this.childCategories != undefined && this.childCategories != '' && this.childCategories.length > 0) {
                this.childTypename = this.childCategories[0].typeName
            }

            this.$forceUpdate()
        },

        setChildCategory(childCategory) {
            if (childCategory._id != undefined && childCategory._id != '') {
                this.selectedChildCategory = childCategory._id
            } else {
                // TODO : set error message
                this.selectedChildCategory = ''
                return
            }
        },

        action_initPopup() {
            this.popup_title = ''
            this.popup_isGlobal = false
            this.popup_state = 0

            this.popup_categoryName = ''
            this.popup_hospitalID = ''
            this.popup_parentCategoryIDs = []
            // this.popup_topCategory = ''
            // this.popup_parentCategory = ''
            // this.popup_childCategory = ''
            ;(this.popup_isTop = false), (this.popup_isParent = false), (this.popup_description = ''), (this.popup_typeName = ''), (this.popup_sortNum = 0)
            this.popup_categoryIDforSortNum = ''
            this.selectedVCCategoryID = ''
            this.popup_child_isBasic = false
            this.popup_child_isBasicSelected = false
        },

        // 선택한 병원ID 유무 판별
        isGlobalCategory() {
            if (this.selectedHospitalID == undefined || this.selectedHospitalID == '') {
                return true
            }
            return false
        },

        async action_updateHidden(category) {
            this.isLoading = true

            let model = {}
            model._id = category._id
            model.isHidden = category.isHidden

            if (category.isTop == true) {
                model.isTop = true
            } else {
                model.isTop = false
            }

            if (category.hospitalID != undefined) {
                model.hospitalID = category.hospitalID
            }

            let result = await this.API.VideoContentCategory.updateHidden(model)
            if (result.data.code != 0) {
                console.log(result)
                this.isLoading = false
                return
            }
            this.isLoading = false
        },

        async action_updateIsSelected(category) {
            // string to boolean true, false value
            if (category.isSelected == 'true') {
                category.isSelected = true
            }
            if (category.isSelected == 'false') {
                category.isSelected = false
            }

            // topVCCategories update
            for (let i = 0; i < this.selectedHospitalServiceSetting.topVCCategories.length; i++) {
                if (this.selectedHospitalServiceSetting.topVCCategories[i].categoryID == category._id) {
                    this.selectedHospitalServiceSetting.topVCCategories[i].isSelected = category.isSelected
                }
            }
            let model = {
                _id: this.selectedHospitalServiceSetting._id,
                hospitalID: this.selectedHospitalServiceSetting.hospitalID,
                topVCCategories: this.selectedHospitalServiceSetting.topVCCategories
            }
            let result = await this.API.ServiceSetting.updateServiceSetting(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)
        },

        action_ShowCreateVCCategoryPopup(num) {
            this.action_initPopup()
            switch (num) {
                case categoryEnum.addTop:
                    this.popup_title = '대 카테고리 추가(공통)'
                    this.popup_isTop = true
                    break
                case categoryEnum.addTopCustomize:
                    this.popup_title = '대 카테고리 추가(병원 전용)'
                    this.popup_isTop = true
                    break
                case categoryEnum.addParent:
                    this.popup_title = '중 카테고리 추가'
                    this.popup_isParent = true
                    this.popup_parentCategoryIDs.push(this.selectedTopCategory)
                    this.popup_categoryIDforSortNum = this.selectedTopCategory
                    break
                case categoryEnum.addChild:
                    this.popup_title = '소 카테고리 추가'
                    this.popup_parentCategoryIDs.push(this.selectedTopCategory, this.selectedParentCategory)
                    this.popup_categoryIDforSortNum = this.selectedParentCategory
                    // set isBasic checkbox disabled
                    for (let i = 0; i < this.childCategories.length; i++) {
                        if (this.childCategories[i].isBasic == true) {
                            this.popup_child_isBasicSelected = true
                            break
                        }
                    }
            }
            if (this.isGlobalCategory() == true) {
                this.popup_isGlobal = true
            } else {
                this.popup_hospitalID = this.selectedHospitalID
            }
            this.popup_state = num
            this.popup_isShow = true
        },

        async action_ShowEditVCCategoryPopup(num, vcCategory) {
            this.action_initPopup()
            switch (num) {
                case categoryEnum.editTop:
                case categoryEnum.editTopCustomize:
                    this.popup_title = '대 카테고리 수정'
                    this.popup_isTop = true
                    break
                case categoryEnum.editParent:
                    this.popup_title = '중 카테고리 수정'
                    this.popup_isParent = true
                    this.popup_parentCategoryIDs.push(this.selectedTopCategory)
                    break
                case categoryEnum.editChild:
                    this.popup_title = '소 카테고리 수정'
                    this.popup_parentCategoryIDs.push(this.selectedTopCategory, this.selectedParentCategory)
                    this.popup_child_isBasic = vcCategory.isBasic
                    // set isBasic checkbox disabled
                    for (let i = 0; i < this.childCategories.length; i++) {
                        if (this.childCategories[i].isBasic == true) {
                            this.popup_child_isBasicSelected = true
                            break
                        }
                    }
            }

            this.popup_categoryName = vcCategory.categoryName
            this.popup_description = vcCategory.description
            this.popup_typeName = vcCategory.typeName
            this.selectedVCCategoryID = vcCategory._id

            if (this.isGlobalCategory() == true) {
                this.popup_isGlobal = true
            } else {
                this.popup_hospitalID = this.selectedHospitalID
            }
            this.popup_state = num
            this.popup_isShow = true
        },

        async action_createVCCategory() {
            let model = {
                categoryName: this.popup_categoryName,
                parentCategoryIDs: this.popup_parentCategoryIDs,
                description: this.popup_description,
                typeName: this.popup_typeName,
                isTop: this.popup_isTop,
                isParent: this.popup_isParent
            }

            // set findModel to find last sortNum
            let findModel = {
                isTop: this.popup_isTop,
                isParent: this.popup_isParent
            }
            let state = this.popup_state
            if (state == categoryEnum.addTop || state == categoryEnum.addTopCustomize) {
                console.log('pass')
            } else {
                findModel.parentCategoryIDs = this.popup_categoryIDforSortNum
            }

            if (state == categoryEnum.addChild) {
                model.isBasic = this.popup_child_isBasic
            }

            if (this.popup_hospitalID) {
                model.hospitalID = this.popup_hospitalID
                findModel.hospitalID = this.popup_hospitalID
            }
            let lastCategory = await this.API.VideoContentCategory.findlast(findModel)
            let sortNum = isNaN(Number(lastCategory.sortNum)) ? 1 : Number(lastCategory.sortNum)
            model.sortNum = sortNum + 1

            // create
            let result = await this.API.VideoContentCategory.create(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            let categoryID = result.data.data._id
            let categoryName = result.data.data.categoryName
            console.log(result.data)

            // if add topglobal,
            if (this.popup_state == categoryEnum.addTop) {
                // find every
                let sResult = await this.API.ServiceSetting.findUsingVC()
                let serviceSettings = sResult.data.data
                console.log(serviceSettings)
                for (let i = 0; i < serviceSettings.length; i++) {
                    if (serviceSettings[i].usingAutoSendVideo.length > 0 && serviceSettings[i].topVCCategories.length > 0) {
                        let vcDuration = {
                            categoryID: categoryID,
                            duration: 2,
                            doPerWeek: 3
                        }
                        serviceSettings[i].videoContentDuration.push(vcDuration)
                        let vccategory = {
                            categoryID: categoryID,
                            categoryName: categoryName,
                            sortNum: 999,
                            isSelected: false
                        }
                        serviceSettings[i].topVCCategories.push(vccategory)
                        let ssModel = {
                            _id: serviceSettings[i]._id.toString(),
                            hospitalID: serviceSettings[i].hospitalID._id.toString(),
                            topVCCategories: serviceSettings[i].topVCCategories,
                            videoContentDuration: serviceSettings[i].videoContentDuration
                        }

                        let ssResult = await this.API.ServiceSetting.updateServiceSetting(ssModel)
                        console.log(ssResult.data.data)
                    }
                }
            } else if (this.popup_state == categoryEnum.addTopCustomize) {
                // hospital-specific only
                let hospitalID = this.popup_hospitalID
                let sResult = await this.API.ServiceSetting.getServiceSettingDetail({
                    hospitalID: hospitalID
                })
                let serviceSetting = sResult.data.data
                let vcDuration = {
                    categoryID: categoryID,
                    duration: 2,
                    doPerWeek: 3
                }
                serviceSetting.videoContentDuration.push(vcDuration)
                let vccategory = {
                    categoryID: categoryID,
                    categoryName: categoryName,
                    sortNum: 999,
                    isSelected: false
                }
                serviceSetting.topVCCategories.push(vccategory)
                let ssModel = {
                    _id: serviceSetting._id.toString(),
                    hospitalID: hospitalID,
                    topVCCategories: serviceSetting.topVCCategories,
                    videoContentDuration: serviceSetting.videoContentDuration
                }
                let ssResult = await this.API.ServiceSetting.updateServiceSetting(ssModel)
                console.log(ssResult.data.data)
            }

            // fire data reload

            console.log(state)
            switch (state) {
                case categoryEnum.addTop:
                case categoryEnum.editTop:
                    this.loadTopCategories()
                    break
                case categoryEnum.addTopCustomize:
                case categoryEnum.editTopCustomize:
                    this.loadTopCategoriesByHospital(this.selectedHospitalID)
                    break
                case categoryEnum.addParent:
                case categoryEnum.editParent:
                    this.loadParentCategories(this.selectedTopCategory)
                    break
                case categoryEnum.addChild:
                case categoryEnum.editChild:
                    this.loadChildCategories(this.selectedParentCategory)
            }

            this.action_CloseVCCategoryPopup()
        },

        // fire update
        async action_editVCCategory() {
            let model = {
                _id: this.selectedVCCategoryID,
                categoryName: this.popup_categoryName,
                parentCategoryIDs: this.popup_parentCategoryIDs,
                description: this.popup_description,
                typeName: this.popup_typeName,
                isTop: this.popup_isTop,
                isParent: this.popup_isParent
            }
            let state = this.popup_state

            if (this.popup_hospitalID) {
                model.hospitalID = this.popup_hospitalID
            }
            if (state == categoryEnum.editChild) {
                model.isBasic = this.popup_child_isBasic
            }

            // create
            let result = await this.API.VideoContentCategory.update(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data)

            // fire data reload

            console.log(state)
            switch (state) {
                case categoryEnum.addTop:
                case categoryEnum.editTop:
                    this.loadTopCategories()
                    break
                case categoryEnum.addTopCustomize:
                case categoryEnum.editTopCustomize:
                    this.loadTopCategoriesByHospital(this.selectedHospitalID)
                    break
                case categoryEnum.addParent:
                case categoryEnum.editParent:
                    this.loadParentCategories(this.selectedTopCategory)
                    break
                case categoryEnum.addChild:
                case categoryEnum.editChild:
                    this.loadChildCategories(this.selectedParentCategory)
            }

            this.action_CloseVCCategoryPopup()
        },

        action_CloseVCCategoryPopup() {
            this.popup_isShow = false
        },

        isAddPopup() {
            let num = this.popup_state
            switch (num) {
                case categoryEnum.addTop:
                case categoryEnum.addParent:
                case categoryEnum.addChild:
                case categoryEnum.addTopCustomize:
                    return true
                case categoryEnum.editTop:
                case categoryEnum.editParent:
                case categoryEnum.editChild:
                case categoryEnum.editTopCustomize:
                    return false
            }
        },

        async action_copyAllVCH() {
            this.isLoading = true
            let result = await this.API.VideoContentHospital.copyAllVCH()
            if (result.data.code != 0) {
                console.log(result)
                this.isLoading = false
                return
            }
            console.log(result)
            this.initData()
            this.isLoading = false
        },

        async action_copyByGlobalCategory(globalCategory) {
            this.isLoading = true
            let topCategoryID = globalCategory._id
            let model = {
                topCategoryID: topCategoryID
            }
            let result = await this.API.VideoContentHospital.copyByGlobalCategory(model)
            console.log(result)
            this.isLoading = false
        },

        async action_copyByHospitalCategory(category) {
            this.isLoading = true
            let hospitalID = this.selectedHospitalID
            let topCategoryID = category._id
            let model = {
                hospitalID: hospitalID,
                topCategoryID: topCategoryID
            }
            let result = await this.API.VideoContentHospital.copyByHospitalCategory(model)
            console.log(result)
            this.isLoading = false
        },

        action_ShowParentTypenamePopup() {
            if (this.parentTypename == '') {
                this.isErrorMessage = true
                this.errorMessage = '명칭을 입력해주세요.'
                return
            }
            this.setCategorySettingParentTypenamePopupState({
                isShow: true
            })
        },

        action_ShowChildTypenamePopup() {
            if (this.childTypename == '') {
                this.isErrorMessage = true
                this.errorMessage = '명칭을 입력해주세요.'
                return
            }
            this.setCategorySettingChildTypenamePopupState({
                isShow: true
            })
        }
        // TODO :
        // update sortNum?
    }
}
</script>
