<template>
    <div v-show="false">
        <input ref="oneimageinput" type="file" @change="handleFileChange" :accept="imageExt" />
        <input ref="imageinput" type="file" @change="handleFileChange" multiple :accept="imageExt" />
        <input ref="fileinput" type="file" @change="handleFileChange" multiple :accept="allExt" />
    </div>
</template>

<script>
export default {
    props: {
        value: [],
        isMultiple: Boolean,
        isOpen: Boolean,
        onlyImage: Boolean
    },
    data() {
        return {
            ext: [],
            imageExt: ['image/*'],
            allExt: ['*']
            // 'video/*'
        }
    },
    watch: {
        isOpen: {
            immediate: true,
            deep: true,
            handler: function (newValue) {
                console.log('isOpen in file picker : ', newValue)
                if (newValue == true) {
                    let input
                    if (this.onlyImage == true) {
                        console.log('file picker : image')
                        if (this.isMultiple == true) {
                            input = this.$refs['imageinput']
                        } else {
                            input = this.$refs['oneimageinput']
                        }
                    } else {
                        console.log('file picker : file')
                        input = this.$refs['fileinput']
                    }
                    input.value = ''
                    console.log(input)
                    input.click()
                    this.$emit('input', [])
                }
            }
        },
        onlyImage: {
            immediate: true,
            handler: function (newValue) {
                if (newValue == true) {
                    this.ext = this.imageExt
                } else {
                    this.ext = this.allExt
                }
            }
        }
    },
    methods: {
        handleFileChange(e) {
            let files = e.currentTarget.files
            console.log(files)
            let fileArray = []
            for (let i = 0; i < files.length; i++) {
                fileArray.push(files[i])
            }
            this.$forceUpdate()

            this.$emit('input', fileArray)
        }
    }
}
</script>

<style scoped></style>
