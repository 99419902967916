<template>
    <tr>
        <td data-row-idx="1" data-cell-idx="0" class="sot-left" style="left: 0px">{{ getUpdatedAt }}</td>
        <td data-row-idx="1" data-cell-idx="1" class="sot-left" style="left: 150px">{{ getHospitalName }}</td>
        <td data-row-idx="1" data-cell-idx="2" class="sot-left" style="left: 300px">{{ getEventTitle }}</td>
        <td data-row-idx="1" data-cell-idx="3" class="sot-left" style="left: 600px">{{ getPushSendCount }}</td>
        <td data-row-idx="1" data-cell-idx="4">{{ getFilter }}</td>
        <td data-row-idx="1" data-cell-idx="5">{{ getFilterTitle }}</td>
        <td data-row-idx="1" data-cell-idx="6">{{ getFilterContent }}</td>
        <td data-row-idx="1" data-cell-idx="7">{{ getFilterJoinedAt }}</td>
        <td data-row-idx="1" data-cell-idx="8">{{ getSendUserCount }}</td>
        <td data-row-idx="1" data-cell-idx="9">{{ getReceiveUserCount }}</td>
        <td data-row-idx="1" data-cell-idx="10">{{ getReceiveUserCountPerSendUserCount }}</td>
        <td data-row-idx="1" data-cell-idx="11">{{ getClickPushUserCount }}</td>
        <td data-row-idx="1" data-cell-idx="12">{{ getClickPushUserCountPerReceiveUserCount }}</td>
        <td data-row-idx="1" data-cell-idx="13">{{ getChatUserCount }}</td>
        <td data-row-idx="1" data-cell-idx="14">{{ getChatUserCountPerReceiveUserCount }}</td>
        <td data-row-idx="1" data-cell-idx="15">{{ getPurchaseUserCount }}</td>
        <td data-row-idx="1" data-cell-idx="16">{{ getPurchaseUserCountPerReceiveUserCount }}</td>
        <td data-row-idx="1" data-cell-idx="17">{{ getPurchaseCount }}</td>
        <td data-row-idx="1" data-cell-idx="18">{{ getPurchaseCountPerReceiveUserCount}}</td>
        <td data-row-idx="1" data-cell-idx="19">{{ getTotalPrice }}</td>
        <td data-row-idx="1" data-cell-idx="20">{{ getTotalPricePerReceiveUserCount }}</td>
    </tr>
</template>

<script>
const moment = require('moment')

export default {
    props: { data: Object },
    computed: {
        getUpdatedAt: function() {
            if(this?.data?.updatedAt == undefined) { return '-' }
            return moment(this.data.updatedAt).format('YYYY-MM-DD HH:mm')
        },
        getHospitalName: function() {
            if(this?.data?.title == undefined) { return '-' }
            return this.data.title
        },
        getEventTitle: function() {
            if(this?.data?.body == undefined) { return '-' }
            return this.data.body
        },
        getPushSendCount: function() {
            if(this?.data?.pushSendCount == undefined) { return '-' }
            return this.data.pushSendCount.toLocaleString()
        },
        getFilter: function() {
            if(this?.data?.isSendToHospital == undefined) { return '-' }
            return this.data.isSendToHospital ? '전체' : '특정'
        },
        getFilterTitle: function() {
            if(this.data.isSendToHospital == undefined) { return '-' }
            if(this.data.isSendToHospital) { return '-' }
            if(this.data.filterTitle == "" || this.data?.filterTitle == undefined) {
                return '모든고객'
            }
            return this.data?.filterTitle
        },
        getFilterContent: function() {
            if(this?.data?.filterContent == undefined || this?.data?.filterContent == '') { return '-' }
            return this.data.filterContent
        },
        getFilterJoinedAt: function() {
            if(this?.data?.filterJoinedAt == undefined || this.data?.filterJoinedAt == "" ) { return '-' }
            return this.data.filterJoinedAt
        },
        getSendUserCount: function() {
            if(this?.data?.sendUserCount == undefined) { return '-' }
            return this.data.sendUserCount.toLocaleString()
        },
        getReceiveUserCount: function() {
            if(this?.data?.receiveUserCount == undefined) { return '-' }
            return this.data.receiveUserCount.toLocaleString()
        },
        getReceiveUserCountPerSendUserCount: function() {
            if(this?.data?.receiveUserCountPerSendUserCount == undefined) { return '-' }
            return `${this.formatNumber(this.data.receiveUserCountPerSendUserCount * 100)}%`
        },
        getClickPushUserCount: function() {
            if(this?.data?.clickPushUserCount == undefined) { return '-' }
            return this.data.clickPushUserCount.toLocaleString()
        },
        getClickPushUserCountPerReceiveUserCount: function() {
            if(this?.data?.clickPushUserCountPerReceiveUserCount == undefined) { return '-' }
            return `${this.formatNumber(this.data.clickPushUserCountPerReceiveUserCount * 100)}%`
        },
        getChatUserCount: function() {
            if(this?.data?.chatUserCount == undefined) { return '-' }
            return this.data.chatUserCount.toLocaleString()
        },
        getChatUserCountPerReceiveUserCount: function() {
            if(this?.data?.chatUserCountPerReceiveUserCount == undefined) { return '-' }
            return `${this.formatNumber(this.data.chatUserCountPerReceiveUserCount * 100)}%`
        },
        getPurchaseUserCount: function() {
            if(this?.data?.purchaseUserCount == undefined) { return '-' }
            return this.data.purchaseUserCount.toLocaleString()
        },
        getPurchaseUserCountPerReceiveUserCount: function() {
            if(this?.data?.purchaseUserCountPerReceiveUserCount == undefined) { return '-' }
            return `${this.formatNumber(this.data.purchaseUserCountPerReceiveUserCount * 100)}%`
        },
        getPurchaseCount: function() {
            if(this?.data?.purchaseCount == undefined) { return '-' }
            return this.data.purchaseCount.toLocaleString()
        },
        getPurchaseCountPerReceiveUserCount: function() {
            if(this?.data?.purchaseCountPerReceiveUserCount == undefined) { return '-' }
            let result = Number(this.formatNumber(this.data.purchaseCountPerReceiveUserCount * 100))
            return `${result.toLocaleString()}%`
        },
        getTotalPrice: function() {
            if(this?.data?.totalPrice == undefined) { return '-' }
            let result = Number(this.formatNumber(this.data.totalPrice))
            return result.toLocaleString()
        },
        getTotalPricePerReceiveUserCount: function() {
            if(this?.data?.totalPricePerReceiveUserCount == undefined) { return '-' }
            let result = Number(this.formatNumber(this.data.totalPricePerReceiveUserCount))
            return result.toLocaleString()
        },
    },
    methods: {
        formatNumber(num) {
            if (Number.isInteger(num)) {
                return num.toString()
            } else {
                return num.toFixed(1)
            }
        }
    }
}
</script>
