import axios from 'axios'
import URL from './URL'
import Util from './Util'

const find = async function (m) {
    return axios({
        method: 'GET',
        url: URL.VIDEOCONTENTCATEGORY_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

const findOne = async function (m) {
    return axios({
        method: 'GET',
        url: URL.VIDEOCONTENTCATEGORY_FINDONE,
        params: m,
        headers: Util.getHeaders()
    })
}

const findtop = async function (m) {
    return axios({
        method: 'GET',
        url: URL.VIDEOCONTENTCATEGORY_FINDTOP,
        params: m,
        headers: Util.getHeaders()
    })
}

const findlast = async function (m) {
    return axios({
        method: 'GET',
        url: URL.VIDEOCONTENTCATEGORY_FINDLAST,
        params: m,
        headers: Util.getHeaders()
    })
}

const findconditioning = async function (m) {
    return axios({
        method: 'GET',
        url: URL.VIDEOCONTENTCATEGORY_FINDCONDITIONING,
        params: m,
        headers: Util.getHeaders()
    })
}

const create = async function (m) {
    return axios({
        method: 'POST',
        url: URL.VIDEOCONTENTCATEGORY_CREATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const update = async function (m) {
    return axios({
        method: 'POST',
        url: URL.VIDEOCONTENTCATEGORY_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const updateHidden = async function (m) {
    return axios({
        method: 'POST',
        url: URL.VIDEOCONTENTCATEGORY_UPDATE_HIDDEN,
        data: m,
        headers: Util.getHeaders()
    })
}

const updateTypeName = async function (m) {
    return axios({
        method: 'POST',
        url: URL.VIDEOCONTENTCATEGORY_UPDATE_TYPENAME,
        data: m,
        headers: Util.getHeaders()
    })
}

export default {
    find: find,
    findOne: findOne,
    findtop: findtop,
    findlast: findlast,
    findconditioning: findconditioning,
    create: create,
    update: update,
    updateHidden: updateHidden,
    updateTypeName: updateTypeName
}
