<template>
    <div class="cc">
        <MonitoringNavigation />
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
import MonitoringNavigation from './Navigation/MonitoringNavigation'
export default {
    components: { MonitoringNavigation }
}
</script>

<style scoped></style>
