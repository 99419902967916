const state = {
    isShow: false,
    selectedHospital: undefined,
}

const actions = {
    setAlhospitalInfoPopupState: (store, param) => {
        store.commit('muAlHospitalInfoPopupState', param)
    }
}

const mutations = {
    muAlHospitalInfoPopupState: (state, param) => {
        state.isShow = param.isShow
        state.selectedHospital = param.selectedHospital
    }
}

export default { state, actions, mutations }