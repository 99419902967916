'use strict'

class MPPlayObject {
    isDiscontinuity = false
    extinf = undefined
    byterange = undefined
    url = undefined
    constructor(extinf, byterange, url) {
        if (extinf == undefined && byterange == undefined && url == undefined) {
            this.isDiscontinuity = true
        }
        this.extinf = extinf
        this.byterange = byterange
        this.url = url
    }

    toINFString() {
        return '#EXTINF:' + this.extinf + '\n'
    }

    toRANGEString() {
        return '#EXT-X-BYTERANGE:' + this.byterange + '@0' + '\n'
    }

    toURLString() {
        return this.url + '\n'
    }
    toDiscontinuityString() {
        return 'EXT-X-DISCONTINUITY\n'
    }
}

class MPM3U {
    maxRange = 0.0
    fileString = undefined

    playList = []
    version = 4
    targetDuration = 0

    constructor(fileString) {
        this.fileString = fileString

        this.parse(fileString)
    }

    parse(fileString) {
        let tempArray = fileString.split('\n')
        for (let i = 0; i < tempArray.length; i++) {
            let line = tempArray[i]
            console.log(line)
            if (line == '#EXTM3U') {
                continue
            }
            if (line.includes('#EXT-X-PLAYLIST_TYPE')) {
                continue
            }
            if (line.includes('#EXT-X-VERSION:')) {
                continue
            }
            if (line.includes('#EXT-X-TARGETDURATION:')) {
                continue
            }
            if (line.includes('#EXTINF:')) {
                let extinf = this.parseINF(line)
                i++
                line = tempArray[i]
                let byterange = this.parseBYTERANGE(line)
                i++
                line = tempArray[i]
                let url = this.parseURL(line)
                let p = new MPPlayObject(extinf, byterange, url)
                this.playList.push(p)

                let currentRange = parseFloat(extinf)
                if (currentRange > this.maxRange) {
                    this.maxRange = currentRange
                }
            }
            if (line.includes('EXT-X-DISCONTINUITY')) {
                let p = new MPPlayObject()
                this.playList.push(p)
            }
        }
        this.parseM3U8()
    }

    appendAtFirst(array) {
        this.playList = array.concat(this.playList)
        this.parseM3U8()
    }

    appendAtLast(array) {
        this.playList = this.playList.concat(array)
        this.parseM3U8()
    }

    parseINF(line) {
        let tempString = line.replaceAll(',', '').replaceAll('#EXTINF:', '')
        return tempString
    }
    parseBYTERANGE(line) {
        let tempString = line.replaceAll('#EXT-X-BYTERANGE:', '')
        let tempArray = tempString.split('@')
        let rangeString = tempArray[0]
        return rangeString
    }
    parseURL(line) {
        return line
    }
    parseM3U8() {
        this.m3u8String = '#EXTM3U' + '\n' + '#EXT-X-VERSION:' + this.version + '\n' + '#EXT-X-TARGETDURATION:' + this.maxRange + '\n'
        let lastP = undefined
        let endRange = 0
        for (let i = 0; i < this.playList.length; i++) {
            let p = this.playList[i]
            this.m3u8String += p.toINFString()
            if (lastP != undefined && lastP.url == p.url) {
                this.m3u8String += p.toRANGEString().replace('@0', '@' + endRange)
                endRange += parseFloat(p.byterange)
            } else {
                this.m3u8String += p.toRANGEString()
                endRange = parseFloat(p.byterange)
            }
            this.m3u8String += p.toURLString()
            lastP = p
        }
        this.m3u8String += '#EXT-X-ENDLIST'
    }

    getVideoDuration() {
        let result = 0
        for (let i = 0; i < this.playList.length; i++) {
            let p = this.playList[i]
            result += parseFloat(p.extinf)
        }
        return result
    }
}

module.exports = MPM3U
