const state = {
    isShow: false,
    selectedHospital: undefined
}

const actions = {
    setHospitalPopupState: (store, param) => {
        store.commit('muHospitalPopupState', param)
    }
}

const mutations = {
    muHospitalPopupState: (state, param) => {
        state.isShow = param.isShow
        state.selectedHospital = param.selectedHospital
    }
}

export default { state, actions, mutations }
