module.exports = {
    text                   : 100,
    image                  : 300,
    video                  : 400,
    videoContent           : 410,
    file                   : 500,
    
    reservation            : 600,
    hospitalEvent          : 700,
    hospitalAftercare      : 710,
}