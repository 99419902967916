<template>
    <div class="content_wrap">
        <LoadingIndicate :isLoading="isLoading" />
        <div class="page_title">
            <span>병원 설정</span>
        </div>
        <div class="h_scroll">
            <div class="table_title">
                <p>
                    총 <span class="black_txt">{{ hTotalCount }}</span
                    >개
                </p>
                <div class="table_title_btn">
                    <input type="text" class="input_s" v-model="keyword" placeholder="병원명 검색" @keyup.enter="loadHospital(0)" />
                    <button class="btn_s btn_blue_line mgl_5" @click="loadHospital(0)">검색</button>
                    <a class="btn btn_s btn_blue" @click="action_ShowCreateHospitalPopup">등록</a>
                </div>
            </div>
            <div class="table_header_fix">
                <table v-if="hData.length == 0" class="h_setting_table">
                    <thead>
                        <tr>
                            <th>병원명</th>
                            <th>병원 ID</th>
                            <th>생성일</th>
                            <th>담당 서비스매니저</th>
                            <th>홈케어콘텐츠</th>
                            <th>이벤트</th>
                            <th>계정 수</th>
                            <th>채팅 응대</th>
                            <th>치료유형</th>
                            <th>수정</th>
                            <th>이용중단</th>
                            <th>채팅알림</th>
                            <th>자동메세지 설정</th>
                        </tr>
                    </thead>
                    <tr>
                        <td colspan="11">데이터 로드 중 ..</td>
                    </tr>
                </table>
                <!-- hospital data table -->
                <table class="table_default" v-if="hData.length > 0">
                    <thead>
                        <tr>
                            <th>병원명</th>
                            <th>병원 ID</th>
                            <th>생성일</th>
                            <th>담당 서비스매니저</th>
                            <th>홈케어콘텐츠</th>
                            <th>이벤트</th>
                            <th>계정 수</th>
                            <th>채팅 응대</th>
                            <th>치료유형</th>
                            <th>수정</th>
                            <th>이용중단</th>
                            <th>채팅알림</th>
                            <th>자동메세지 설정</th>
                        </tr>
                    </thead>
                    <tr v-for="h in hData" :key="h._id">
                        <td>
                            <div>
                                <label
                                    class="label"
                                    :class="{
                                        lblue: h.isClosed == false,
                                        lgray: h.isClosed != false
                                    }"
                                >
                                    {{ h.isClosed != undefined ? (h.isClosed == true ? '중단' : '서비스중') : '-' }}
                                </label>
                                {{ h.shortName }}
                            </div>
                            <div>
                                <small class="lgray_txt">({{ h.name }})</small>
                            </div>
                        </td>
                        <td class="word_break" width="18%">{{ h._id }}</td>
                        <td>{{ ISODatetoDate(h.createdAt) }}</td>
                        <td>
                            {{ h.nurseID.name }}
                            <small class="lgray_txt">({{ h.nurseID.id }})</small>
                        </td>
                        <td>
                            <label
                                class="label"
                                :class="{
                                    lblue: h.usingVideoContent == true,
                                    lgray: h.usingVideoContent != true
                                }"
                            >
                                {{ h.usingVideoContent != undefined ? (h.usingVideoContent == true ? '사용' : '미사용') : '-' }}
                            </label>
                        </td>
                        <td>
                            <label
                                class="label"
                                :class="{
                                    lblue: h.usingHospitalEvent == true,
                                    lgray: h.usingHospitalEvent != true
                                }"
                            >
                                {{ h.usingHospitalEvent != undefined ? (h.usingHospitalEvent == true ? '사용중' : '미사용') : '-' }}
                            </label>
                        </td>
                        <td>{{ h.doctorCount }}</td>
                        <td>
                            <button class="btn_s btn_blue_line" @click="action_ShowChatResponseRequired(h)">채팅응대</button>
                        </td>
                        <td>
                            <button class="btn_s btn_gray_line" @click="action_ShowTreatmentCategoryPopup(h)">치료유형</button>
                        </td>
                        <td>
                            <button class="btn_s btn_blue_line" @click="action_ShowEditHospitalPopup(h)">수정</button>
                        </td>
                        <td>
                            <button class="btn btn_s btn_gray" @click="action_ShowSuspendHospitalPopup(h)">이용중단</button>
                        </td>
                        <td>
                            <button class="btn btn_s btn_gray" @click="action_ShowUpdateChattingAlertPopup(h)">채팅알림</button>
                        </td>
                        <td>
                            <button class="btn btn_s btn_gray" @click="action_ShowAnsweringChatPopup(h)">수정</button>
                        </td>
                    </tr>
                </table>
            </div>
            <Pagenation
                v-bind:totalCount="hTotalCount"
                v-bind:currentPage="hCurrentPage"
                v-bind:countPerPage="hCountPerPage"
                v-bind:countPerRow="5"
                v-on:changePage="hChangePage"
            />
        </div>
        <!-- 채팅알림 팝업 -->
        <ChattingAlertPopupVue @clearChatpopup="clearSelectedHospitalChatPopup" :hospital="selectedHospitalID" />
        <!-- 채팅응대 팝업 -->
        <HospitalChatResponseRequiredPopup
            v-bind:isShow="isShow_Pop_ChatResponseRequired"
            v-bind:hospitalID="hospitalID_For_ChatResponseRequired"
            v-on:action_Close="action_CloseChatResponseRequired"
        />
        <!-- 이용중단 팝업 -->

        <AnsweringChat @action_Close="initData"/>
        <HospitalSuspendPopup v-on:initData="initData" />
        <!-- 병원 수정 팝업  -->
        <div class="pop_overlay_bg" v-if="hospitalpopup.isShow">
            <div class="hospital_pop_wrap">
                <PopupTitleSection :title="popupTitle" @action_Close="action_Close" />
                <div class="pop_cont pop_scroll_wrap">
                    <div class="pop_scroll">
                        <div class="form_list">
                            <div class="title">
                                병원 종류<span class="red_txt"></span>
                                <br />
                                <small style="word-break: keep-all" class="lgray_txt mgt_10"> 자주 사용되는 기본 채팅 및 문자 메시지 내용을 불러옵니다. </small>
                            </div>
                            <div class="cont">
                                <label class="radio_wrap"
                                    ><input type="radio" name="popup_hospitalType" v-model="popup_hospitalType" value="OS" /><span class="checkmark"></span
                                    >OS</label
                                >
                                <label class="radio_wrap"
                                    ><input type="radio" name="popup_hospitalType" v-model="popup_hospitalType" value="PS" /><span class="checkmark"></span
                                    >PS</label
                                >
                                <label class="radio_wrap"
                                    ><input type="radio" name="popup_hospitalType" v-model="popup_hospitalType" value="CUSTOM" />
                                    <span class="checkmark"></span>직접 설정</label
                                >
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">건강관리 뉴스 사용 여부<span class="red_txt">*</span></div>
                            <div class="cont">
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingHealthContentValue"
                                        v-model="popup_radioUsingHealthContentValue"
                                        :value="false"
                                    /><span class="checkmark"></span> 아니오</label
                                >
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingHealthContentValue"
                                        v-model="popup_radioUsingHealthContentValue"
                                        :value="true"
                                    /><span class="checkmark"></span> 네</label
                                >
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                메인 건강관리 뉴스<span class="red_txt">*</span>
                                <small style="word-break: keep-all" class="lgray_txt mgt_10">
                                    모바일 앱 첫 화면에서 노출될 건강관리 뉴스 카테고리를 선택합니다.
                                </small>
                            </div>
                            <div class="cont">
                                <select
                                    class="input_s"
                                    v-model="popup_selectedHealthContentCategoryID"
                                    :class="{
                                        lgray: hospitalpopup.selectedHospital != undefined
                                    }"
                                >
                                    <option
                                        :value="null"
                                        v-if="hospitalpopup.selectedHospital == undefined || popup_selectedHealthContentCategoryID == null"
                                        selected
                                    >
                                        --선택--
                                    </option>
                                    <option v-for="hc in healthContentCategories" :key="hc._id" :value="hc._id">
                                        {{ hc.name ? hc.name : '-' }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">병원 소식 사용 여부<span class="red_txt">*</span></div>
                            <div class="cont">
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingHospitalContentValue"
                                        v-model="popup_radioUsingHospitalContentValue"
                                        :value="false"
                                    /><span class="checkmark"></span> 아니오</label
                                >
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingHospitalContentValue"
                                        v-model="popup_radioUsingHospitalContentValue"
                                        :value="true"
                                    /><span class="checkmark"></span> 네</label
                                >
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">병원명<span class="red_txt">*</span></div>
                            <div class="cont">
                                <input type="text" class="input_s" placeholder="병원명" v-model="popup_hospitalName" />
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">짧은 병원명<span class="red_txt">*</span></div>
                            <div class="cont">
                                <input type="text" class="input_s" placeholder="짧은 병원명" v-model="popup_hospitalShortName" />
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                담당 서비스 매니저<span class="red_txt">*</span>
                                <small style="word-break: keep-all" class="lgray_txt mgt_10">
                                    서비스 매니저 변경이 필요한 경우, 프로덕트팀에 작업 요청주세요.
                                </small>
                            </div>
                            <div class="cont">
                                <select
                                    class="input_s"
                                    v-model="popup_selectedNurseID"
                                    :class="{
                                        lgray: hospitalpopup.selectedHospital != undefined
                                    }"
                                    :disabled="hospitalpopup.selectedHospital != undefined"
                                >
                                    <option :value="null" v-if="hospitalpopup.selectedHospital == undefined" selected>--선택--</option>
                                    <option v-for="n in nurses" :key="n._id" :value="n._id">{{ n.name }}({{ n.id }})</option>
                                </select>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">문자 이미지 로고 사용 여부</div>
                            <div class="cont">
                                <label class="radio_wrap"
                                    ><input type="radio" name="popup_radiosmsLogoImageValue" v-model="popup_radiosmsLogoImageValue" :value="false" /><span
                                        class="checkmark"
                                    ></span>
                                    미사용</label
                                >
                                <label class="radio_wrap"
                                    ><input type="radio" name="popup_radiosmsLogoImageValue" v-model="popup_radiosmsLogoImageValue" :value="true" /><span
                                        class="checkmark"
                                    ></span>
                                    사용</label
                                >
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">문자 이미지 로고</div>
                            <div class="cont">
                                <input type="file" accept="image/*" class="input_s w_full" @change="action_uploadSMSLogoImageValue($event)" />
                                <small @mouseenter="smsLogoImageHover('on')" @mouseleave="smsLogoImageHover('off')" class="lgray_txt mgt_10 img_preview_wrap">
                                    현재 저장된 FileID : {{ popup_smsLogoImageFileID }}
                                    <span class="img_preview">
                                        <img
                                            v-if="popup_smsLogoImageHoverStatus == true && popup_smsLogoImagePaths != ''"
                                            :src="popup_smsLogoImagePaths"
                                            alt=""
                                        />
                                    </span>
                                </small>
                            </div>
                        </div>
                        <!-- <div class="form_list">
                            <div class="title">
                                앱 가입 시 채팅 메시지<span class="red_txt">* 더이상 사용되지 않음</span>
                                <small class="lgray_txt mgt_10">
                                    고객 가입 시 채팅방 내에 최초로 전송되는 채팅 내용입니다.
                                    <br /><span class="red_txt">**</span>간호사 이름 반드시 변경 필요
                                    <br />
                                    <br />[변환 가능 목록] <br />
                                    - 병원명 : 1번 이상 <br />
                                    - 고객명 : 1번 이상 <br />
                                    - 링크 : 1번
                                </small>
                            </div>
                            <div class="cont">
                                <textarea disabled rows="12" class="w_full" placeholder="간호사 이름 변경 필요, 채팅 내용 입력"
                                    v-model="popup_selectedHospital_initialChat"></textarea>
                            </div>
                        </div> -->
                        <div class="form_list">
                            <div class="title">
                                앱 가입 시 채팅 메시지(신규)<span class="red_txt">*</span>
                                <small class="lgray_txt mgt_10">
                                    고객 가입 시 채팅방 내에 최초로 전송되는 채팅 내용입니다.
                                    <br /><span class="red_txt">**</span>간호사 이름 반드시 변경 필요
                                    <br />
                                    <br />[변환 가능 목록] <br />
                                    - 병원명 : 1번 이상 <br />
                                    - 고객명 : 1번 이상 <br />
                                    - 링크 : 없음
                                </small>
                            </div>
                            <div class="cont">
                                <InitialChats
                                    :hospitalID="popup_selectedHospital_id"
                                    :initialChats="popup_selectedHospital_initialChats"
                                    @saveDraft="action_saveDraftInitialChats"
                                    @initData="initData"
                                    @showSaveToast="showSaveToast"
                                />
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                매니저 최초 채팅 문자 설정<span class="red_txt">*</span><br />
                                <small class="lgray_txt mgt_10"
                                    >고객 가입 이후, 일렉트론에서 고객에게 최초로 메시지를 보냈을 때 발송되는 문자 메시지 기본 내용입니다.
                                    <br />
                                    <br />[변환 가능 목록] <br />
                                    - 병원명 : 1번 <br />
                                    - 고객명 : 1번 <br />
                                    - 링크 : 1번
                                </small>
                            </div>
                            <div class="cont">
                                <textarea rows="12" class="w_full" placeholder="채팅 내용 입력" v-model="popup_selectedHospital_smsChatMessage"></textarea>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                고객등록 가입문자<span class="red_txt">*</span>
                                <small class="lgray_txt mgt_10">
                                    고객 휴대폰으로 발송되는 가입 문자 내용입니다.
                                    <br />
                                    <br />[변환 가능 목록] <br />
                                    - 병원명 : 1번 이상 <br />
                                    - 고객명 : 1번 <br />
                                    - 링크 : 1번
                                </small>
                            </div>
                            <div class="cont">
                                <textarea rows="15" class="w_full" placeholder="내용 입력" v-model="popup_selectedHospital_smsBasicMessage"></textarea>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                예약 가입문자<span class="red_txt">*</span>
                                <small class="lgray_txt mgt_10">
                                    고객 휴대폰으로 발송되는 예약 가입문자 내용입니다.
                                    <br />
                                    <br />[변환 가능 목록] <br />
                                    - 병원명 : 1번 이상 <br />
                                    - 고객명 : 1번 이상 <br />
                                    - 링크 : 1번
                                </small>
                            </div>
                            <div class="cont">
                                <textarea rows="12" class="w_full" placeholder="내용 입력" v-model="popup_selectedHospital_ReservationMessage"></textarea>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                재발송 가입문자<span class="red_txt">*</span>
                                <small class="lgray_txt mgt_10">
                                    고객 휴대폰으로 발송되는 재발송 가입문자 내용입니다.
                                    <br />
                                    <br />[변환 가능 목록] <br />
                                    - 병원명 : 1번 <br />
                                    - 고객명 : 1번 <br />
                                    - 링크 : 1번
                                </small>
                            </div>
                            <div class="cont">
                                <textarea rows="12" class="w_full" placeholder="내용 입력" v-model="popup_selectedHospital_smsResendMessage"></textarea>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                자동 재발송 가입문자 사용 여부
                                <small style="word-break: keep-all" class="lgray_txt mgt_10">
                                    앱 미가입 고객 휴대폰으로 설정된 일시에
                                    <br/>자동으로 가입문자를 재발송하는 기능입니다.
                                </small>
                            </div>
                            <div class="cont">
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingAutoReservedSmsValue"
                                        v-model="popup_radioUsingAutoReservedSmsValue"
                                        :value="false"
                                    /><span class="checkmark"></span> 아니오</label>
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingAutoReservedSmsValue"
                                        v-model="popup_radioUsingAutoReservedSmsValue"
                                        :value="true"
                                    /><span class="checkmark"></span> 네</label>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                자동 재발송 가입문자
                                <small class="lgray_txt mgt_10">
                                    <span>고객 휴대폰으로 발송되는 재발송 가입문자 내용입니다.</span>
                                    <br />
                                    <br />[변환 가능 목록] <br />
                                    - 병원명 : 1번 이상<br />
                                    - 고객명 : 1번 이상 <br />
                                    - 링크 : 1번
                                </small>
                            </div>
                            <div class="cont">
                                <textarea rows="12" class="w_full" placeholder="내용 입력" 
                                    :disabled="popup_radioUsingAutoReservedSmsValue == false" 
                                    v-model="popup_selectedHospital_autoReservedSMSMessage" />
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                자동 재발송 일시 설정
                                <small style="word-break: keep-all" class="lgray_txt mgt_10">
                                    설정된 일시에 맞추어 가입문자가 자동 재발송됩니다.
                                    <br/>변경 시점 이후 등록 환자부터 변경된 내용이 적용됩니다.
                                    <br/>(등록 당일 : 0일)
                                </small>
                            </div>
                            <div class="cont">
                                <span>등록일부터&ensp;</span>
                                <select class="input_s" v-model="popup_selectedHospital_autoReservedSMSDelayDay"
                                    :class="{ lgray: popup_radioUsingAutoReservedSmsValue == false}"
                                    :disabled="popup_radioUsingAutoReservedSmsValue == false">
                                    <option :value="index" v-for="(i, index) in 15" :key="i">{{ index }}</option>
                                </select>
                                <span>&ensp;일 후&emsp;</span>
                                <select class="input_s" v-model="popup_selectedHospital_autoReservedSMSHour"  
                                    :class="{ lgray: popup_radioUsingAutoReservedSmsValue == false}"
                                    :disabled="popup_radioUsingAutoReservedSmsValue == false">
                                    <option :value="index" v-for="(i, index) in 24" :key="i">{{ ('0' + index).slice(-2) }}</option>
                                </select>
                                <span>&ensp;시</span>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                문자 발신 전화번호
                                <small style="word-break: keep-all" class="lgray_txt mgt_10">
                                    병원 문자 발신 번호 등록/변경 시, 프로덕트팀에 작업 요청주세요.
                                </small>
                            </div>
                            <div class="cont">
                                <input type="text" class="input_s" placeholder="예) 07051804070" v-model="popup_selectedHospital_smsPhone" disabled />
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">홈케어 콘텐츠 모듈 사용여부<span class="red_txt">*</span></div>
                            <div class="cont">
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingVideoContentValue"
                                        v-model="popup_radioUsingVideoContentValue"
                                        :value="false"
                                    /><span class="checkmark"></span> 아니오</label
                                >
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingVideoContentValue"
                                        v-model="popup_radioUsingVideoContentValue"
                                        :value="true"
                                    /><span class="checkmark"></span> 네</label
                                >
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                홈케어 콘텐츠 발송시 채팅<span class="red_txt">*</span>
                                <small class="lgray_txt mgt_10">
                                    홈케어 콘텐츠 발송 시, 채팅방 내에 전송되는 채팅 내용입니다.
                                    <br />
                                    <br />[변환 가능 목록] <br />
                                    - 병원명 : 없음 <br />
                                    - 고객명 : 1번 <br />
                                    - 링크 : 없음
                                </small>
                            </div>
                            <div class="cont">
                                <textarea rows="12" class="w_full" placeholder="내용 입력" v-model="popup_selectedHospital_defaultVCMessage"></textarea>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">
                                홈케어 콘텐츠 발송시 문자<span class="red_txt">*</span>
                                <small class="lgray_txt mgt_10">
                                    홈케어 콘텐츠 발송 시, 고객 휴대폰으로 발송되는 문자 내용입니다.
                                    <br />
                                    <br />[변환 가능 목록] <br />
                                    - 병원명 : 1번 <br />
                                    - 고객명 : 1번 <br />
                                    - 링크 : 1번
                                </small>
                            </div>
                            <div class="cont">
                                <textarea rows="12" class="w_full" placeholder="내용 입력" v-model="popup_selectedHospital_defaultVCSMSMessage"></textarea>
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">이벤트/홈케어 상품 모듈 사용여부<span class="red_txt">*</span></div>
                            <div class="cont">
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingHospitalEventValue"
                                        v-model="popup_radioUsingHospitalEventValue"
                                        :value="false"
                                    /><span class="checkmark"></span> 아니오</label
                                >
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingHospitalEventValue"
                                        v-model="popup_radioUsingHospitalEventValue"
                                        :value="true"
                                    /><span class="checkmark"></span> 네</label
                                >
                            </div>
                        </div>
                        <div class="form_list">
                            <div class="title">예약 시트 2차 사용 여부<span class="red_txt">*</span></div>
                            <div class="cont">
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingV2ReservationSheetValue"
                                        v-model="popup_radioUsingV2ReservationSheetValue"
                                        :value="false"
                                    /><span class="checkmark"></span> 아니오</label
                                >
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="popup_radioUsingV2ReservationSheetValue"
                                        v-model="popup_radioUsingV2ReservationSheetValue"
                                        :value="true"
                                    /><span class="checkmark"></span> 네</label
                                >
                            </div>
                        </div>
                        <small class="red_txt mgt_10" v-show="isErrorMessage">
                            {{ errorMessage }}
                        </small>
                        <div class="btn_wrap">
                            <button class="btn btn_l btn_blue" v-if="hospitalpopup.selectedHospital == undefined" @click="action_createHosptial">등록</button>
                            <button class="btn btn_l btn_blue" v-if="hospitalpopup.selectedHospital != undefined" @click="action_updateHospital">수정</button>
                        </div>
                    </div>
                </div>
            </div>
            <SaveToast :isShow="isShowToast" />
        </div>
        <HospitalTreatmentCategoryPopup />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from '@/util/MPMoment'
import SaveToast from '@/views/main/ShareComponents/SaveToastComponent.vue'
import Pagenation from '@/views/main/ShareComponents/PagenationComponent.vue'
import PageController from '@/controller/PageController.js'
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'
import PopupTitleSection from '@/views/main/ShareComponents/PopupTitleSection.vue'
import HospitalSuspendPopup from './Components/HospitalSuspendPopup'
import HospitalChatResponseRequiredPopup from './Components/ChatResponseRequiredPopup.vue'
import HospitalTreatmentCategoryPopup from './Components/TreatmentCatetoryEdit.vue'
import popup_hospital_type_message from './hospital_type_message.js'
import InitialChats from './Components/InitialChats/InitialChats.vue'
import ChattingAlertPopupVue from './Components/ChattingAlertPopup.vue'
import AnsweringChat from '../../AnsweringChat/answeringChat.vue'

export default {
    components: {
        Pagenation,
        HospitalChatResponseRequiredPopup,
        HospitalTreatmentCategoryPopup,
        LoadingIndicate,
        PopupTitleSection,
        HospitalSuspendPopup,
        InitialChats,
        ChattingAlertPopupVue,
        AnsweringChat,
        SaveToast
    },
    data() {
        return {
            tableTitleList: [
                '병원명',
                '병원 ID',
                '생성일',
                '담당 서비스 매니저',
                '사용버전',
                '홈케어 콘텐츠 사용여부',
                '등록된 계정 수',
                '서비스 이용여부',
                '채팅 응대',
                '치료유형',
                '수정',
                '이용중단'
            ],
            chat_message: popup_hospital_type_message,
            selectedHospital: {},

            keyword: '',

            hospitalPageController: undefined,
            hCurrentPage: 0,
            hCountPerPage: 10,
            hData: [],
            hTotalCount: 0,

            nurses: [],
            data: [],
            hospitals: [],
            healthContentCategories: [],

            // popup 컴포넌트에서 필요
            serviceSetting: {},

            // 이하 등록/수정 팝업 component 분리?
            popup_hospitalType: '', // OS, PS, CUSTOM. DB에 기록되지 않음.
            popup_hospitalName: '',
            popup_hospitalShortName: '',
            popup_selectedNurseID: null,

            popup_radioUsingHealthContentValue: true,
            popup_radioUsingHospitalContentValue: false,
            popup_selectedHealthContentCategoryID: null,

            popup_radiosmsLogoImageValue: false,
            popup_smsLogoImageFileID: '',
            popup_smsLogoImagePaths: '',
            popup_smsLogoImageHoverStatus: false,

            popup_selectedHospital_id: undefined,
            popup_selectedHospital_initialChat: '',
            popup_selectedHospital_initialChats: [],
            popup_selectedHospital_smsChatMessageA: '',
            popup_selectedHospital_smsChatMessageB: '',
            popup_selectedHospital_smsChatMessage: '',
            popup_selectedHospital_smsBasicMessage: '',
            popup_selectedHospital_ReservationMessage: '',
            popup_selectedHospital_smsResendMessage: '',

            popup_radioUsingAutoReservedSmsValue: false,
            popup_selectedHospital_autoReservedSMSMessage: '',
            popup_selectedHospital_autoReservedSMSDelayDay: 0,
            popup_selectedHospital_autoReservedSMSHour: 20,

            popup_selectedHospital_smsPhone: '',

            popup_radioUsingVideoContentValue: false,
            popup_selectedHospital_defaultVCMessage: '',
            popup_selectedHospital_defaultVCSMSMessage: '',

            popup_radioUsingHospitalEventValue: false,
            popup_radioUsingV2ReservationSheetValue: false,

            isErrorMessage: false,
            errorMessage: '',

            isLoading: false,

            // 채팅 응대 팝업
            isShow_Pop_ChatResponseRequired: false,
            hospitalID_For_ChatResponseRequired: undefined,
            editHospitalPopupOpen: false,

            selectedHospitalID: undefined,
            isShowToast: false
        }
    },
    watch: {
        serviceSetting: {
            immediate: true,
            handler: function (newValue) {
                console.log('servicesetting', newValue)
            }
        },
        keyword: {
            handler: function (newValue) {
                if (newValue == '') {
                    this.loadHospital(0)
                }
            }
        },
        popup_hospitalType: {
            handler: function (newValue) {
                if (newValue == 'OS') {
                    this.popup_selectedHospital_initialChat = this.chat_message.popup_hospital_type_message.OS.initialChat
                    this.popup_selectedHospital_initialChats = this.chat_message.popup_hospital_type_message.OS.initialChats
                    // set same smsChatMessage to A, B
                    this.popup_selectedHospital_smsChatMessageA = this.chat_message.popup_hospital_type_message.OS.smsChatMessage
                    this.popup_selectedHospital_smsChatMessageB = this.chat_message.popup_hospital_type_message.OS.smsChatMessage
                    this.popup_selectedHospital_smsChatMessage = this.chat_message.popup_hospital_type_message.OS.smsChatMessage
                    this.popup_selectedHospital_smsBasicMessage = this.chat_message.popup_hospital_type_message.OS.smsBasicMessage
                    this.popup_selectedHospital_ReservationMessage = this.chat_message.popup_hospital_type_message.OS.ReservationMessage
                    this.popup_selectedHospital_smsResendMessage = this.chat_message.popup_hospital_type_message.OS.smsResendMessage
                    this.popup_selectedHospital_defaultVCMessage = this.chat_message.popup_hospital_type_message.OS.defaultVCMessage
                    this.popup_selectedHospital_defaultVCSMSMessage = this.chat_message.popup_hospital_type_message.OS.defaultVCSMSMessage

                    // find categoryID by healthContentCategory name, and set id
                    let hcCategory = this.healthContentCategories.find((category) => category.name == '건강질병')

                    this.popup_selectedHealthContentCategoryID = hcCategory ? hcCategory._id : null
                } else if (newValue == 'PS') {
                    this.popup_selectedHospital_initialChat = this.chat_message.popup_hospital_type_message.PS.initialChat
                    this.popup_selectedHospital_initialChats = this.chat_message.popup_hospital_type_message.PS.initialChats
                    // set same smsChatMessage to A, B
                    this.popup_selectedHospital_smsChatMessageA = this.chat_message.popup_hospital_type_message.PS.smsChatMessage
                    this.popup_selectedHospital_smsChatMessageB = this.chat_message.popup_hospital_type_message.PS.smsChatMessage
                    this.popup_selectedHospital_smsChatMessage = this.chat_message.popup_hospital_type_message.PS.smsChatMessage
                    this.popup_selectedHospital_smsBasicMessage = this.chat_message.popup_hospital_type_message.PS.smsBasicMessage
                    this.popup_selectedHospital_ReservationMessage = this.chat_message.popup_hospital_type_message.PS.ReservationMessage
                    this.popup_selectedHospital_smsResendMessage = this.chat_message.popup_hospital_type_message.PS.smsResendMessage
                    this.popup_selectedHospital_defaultVCMessage = this.chat_message.popup_hospital_type_message.PS.defaultVCMessage
                    this.popup_selectedHospital_defaultVCSMSMessage = this.chat_message.popup_hospital_type_message.PS.defaultVCSMSMessage

                    // find categoryID by healthContentCategory name, and set id
                    let hcCategory = this.healthContentCategories.find((category) => category.name == '뷰티/성형/피부')
                    this.popup_selectedHealthContentCategoryID = hcCategory ? hcCategory._id : null
                } else {
                    // do nothing
                    console.log('hospital add custom start')
                }
            }
        },
        popup_smsLogoImageFileID: {
            handler: function (newValue) {
                if (newValue != undefined && newValue != '') {
                    // do method something and update
                    console.log(newValue)
                    this.loadSMSLogo()
                } else {
                    console.log('newValue is .. ' + newValue)
                }
            }
        }
    },
    computed: {
        ...mapState(['hospitalpopup', 'hospitalsuspendpopup', 'hospitaltreatmentcategorypopup', 'chattingalertpopup']),
        popupTitle: function () {
            if (this.popup_selectedHospital_id == undefined) {
                return '병원 생성'
            }
            return '병원 수정'
        }
    },
    mounted() {
        this.hospitalPageController = new PageController(this.API.Hospital.getHospitals)
        this.hospitalPageController.countPerPage = this.hCountPerPage
        this.loadHospital(0)
        this.getNurses()
        this.getHealthContentCategories()
    },
    methods: {
        ...mapActions(['setHospitalPopupState', 'setHospitalSuspendPopupState', 'setHospitalTreatmentCategoryPopupState', 'setHospitalChatPopupState', 'setAnsweringChatPopupState']),

        // reload data
        initData() {
            this.isLoading = false
            this.loadHospital(this.hCurrentPage)
            this.getNurses()
            this.getHealthContentCategories()
        },

        async loadHospital(page) {
            // console.log(process.env.NODE_ENV)
            // if(this.hospital == undefined || this.hospital._id == undefined) { return }

            this.hospitalPageController.filter = {}
            if (this.keyword != '') {
                this.hospitalPageController.filter.keyword = this.keyword
            }

            await this.hospitalPageController.loadPageIndex(page)
            await this.loadTotalHospitalCount()

            this.hData = this.hospitalPageController.data
            this.hData.forEach(async (h) => {
                let result = await this.API.Hospital.countDoctors({ hospitalID: h._id })
                if (result.data.code != 0) {
                    console.log(result)
                    return
                }
                h.doctorCount = result.data.data
                this.$forceUpdate()
            })
        },

        async loadTotalHospitalCount() {
            let result = await this.API.Hospital.countHospitals(this.hospitalPageController.filter)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.hTotalCount = result.data.data
        },

        hChangePage(page) {
            this.hCurrentPage = page
            this.loadHospital(this.hCurrentPage)
        },

        async getNurses() {
            let result = await this.API.Hospital.getNurses({})
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.nurses = result.data.data
        },

        async getHealthContentCategories() {
            let result = await this.API.HealthContentCategory.find({ isRemoved: false })
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.healthContentCategories = result.data.data
        },

        async getServiceSetting(hospital) {
            let model = {
                hospitalID: hospital._id
            }
            let result = await this.API.ServiceSetting.getServiceSettingDetail(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.serviceSetting = result.data.data
            if (this.serviceSetting == null) {
                this.serviceSetting = {}
            }
        },

        initPopup() {
            // init popup inputs
            this.popup_selectedHospital_id = undefined
            this.popup_hospitalType = ''
            this.popup_hospitalName = ''
            this.popup_hospitalShortName = ''
            this.popup_selectedNurseID = null
            this.popup_radiosmsLogoImageValue = false
            this.popup_smsLogoImageFileID = ''
            this.popup_smsLogoImagePaths = ''
            this.popup_selectedHospital_initialChat = ''
            this.popup_selectedHospital_initialChats = []
            this.popup_selectedHospital_smsChatMessageA = ''
            this.popup_selectedHospital_smsChatMessageB = ''
            this.popup_selectedHospital_smsChatMessage = ''
            this.popup_selectedHospital_smsBasicMessage = ''
            this.popup_selectedHospital_ReservationMessage = ''
            this.popup_selectedHospital_smsResendMessage = ''

            this.popup_radioUsingAutoReservedSmsValue = false
            this.popup_selectedHospital_autoReservedSMSMessage = ''
            this.popup_selectedHospital_autoReservedSMSDelayDay = 0
            this.popup_selectedHospital_autoReservedSMSHour = 20

            this.popup_selectedHospital_smsPhone = ''
            this.popup_radioUsingVideoContentValue = false
            this.popup_selectedHospital_defaultVCMessage = ''
            this.popup_selectedHospital_defaultVCSMSMessage = ''

            this.popup_radioUsingHospitalEventValue = false
            this.popup_radioUsingV2ReservationSheetValue = false
            // this.popup_selectedHospital_eventChatMessage : '',
            // this.popup_selectedHospital_aftercareChatMessage : '',

            this.popup_selectedHealthContentCategoryID = null
            this.popup_radioUsingHealthContentValue = true
            this.popup_radioUsingHospitalContentValue = false

            this.serviceSetting = {}
            this.isErrorMessage = false
        },

        action_ShowCreateHospitalPopup() {
            this.initPopup()
            this.setHospitalPopupState({
                isShow: true,
                hospital: undefined
            })
        },

        async action_ShowEditHospitalPopup(hospital) {
            await this.getServiceSetting(hospital)

            // set popup data
            this.popup_hospitalType = 'CUSTOM'
            this.popup_hospitalName = hospital.name
            this.popup_hospitalShortName = hospital.shortName
            this.popup_selectedNurseID = hospital.nurseID._id
            this.popup_radiosmsLogoImageValue = hospital.isUsingLogo != undefined ? hospital.isUsingLogo : false
            this.popup_smsLogoImageFileID = hospital.smsLogoImage != undefined ? hospital.smsLogoImage : ''
            this.popup_selectedHospital_initialChat = hospital.initialChat[0]
            this.popup_selectedHospital_initialChats = hospital.initialChats ? hospital.initialChats : []

            this.popup_selectedHospital_id = hospital._id

            this.popup_selectedHospital_smsChatMessageA = hospital.smsChatMessage
            this.popup_selectedHospital_smsChatMessageB = hospital.smsChatMessage
            this.popup_selectedHospital_smsChatMessage = hospital.smsChatMessage

            // 기본 문구 / 직접 입력 여부 체크. usingBasicMessage true 일 때 기본 설정.
            this.popup_selectedHospital_smsBasicMessage = hospital.usingBasicMessage ? hospital.smsBasicMessage : hospital.smsBasicMessageEdit
            this.popup_selectedHospital_ReservationMessage = hospital.usingReservationMessage ? hospital.smsReservation : hospital.smsReservationEdit
            this.popup_selectedHospital_smsResendMessage = hospital.usingResendMessage ? hospital.smsResendMessage : hospital.smsResendMessageEdit

            this.popup_radioUsingAutoReservedSmsValue = hospital.usingAutoReservedSMS ?? false
            this.popup_selectedHospital_autoReservedSMSMessage = hospital.autoReservedSMSMessage
            this.popup_selectedHospital_autoReservedSMSDelayDay = hospital.autoReservedSMSDelayDay ?? 0
            this.popup_selectedHospital_autoReservedSMSHour = hospital.autoReservedSMSHour ?? 20

            this.popup_selectedHospital_smsPhone = hospital.smsPhone
            this.popup_radioUsingVideoContentValue = hospital.usingVideoContent

            this.popup_radioUsingHospitalEventValue = hospital.usingHospitalEvent ? hospital.usingHospitalEvent : false
            this.popup_radioUsingV2ReservationSheetValue = hospital.usingV2ReservationSheet ? hospital.usingV2ReservationSheet : false

            // TODO : how to set eventChatMessage?
            // this.popup_selectedHospital_eventChatMessage = this.serviceSetting.eventChatMessage ? this.serviceSetting.eventChatMessage : ''
            // this.popup_selectedHospital_aftercareChatMessage = this.serviceSetting.aftercareChatMessage ? this.serviceSetting.aftercareChatMessage : ''

            // this.popup_selectedHealthContentCategoryID = hospital.healthContentCategory ? hospital.healthContentCategory[0] : null
            if (
                hospital.healthContentCategory != undefined &&
                hospital.healthContentCategory != '' &&
                Array.isArray(hospital.healthContentCategory) &&
                hospital.healthContentCategory.length > 0
            ) {
                this.popup_selectedHealthContentCategoryID = hospital.healthContentCategory[0]
            } else {
                this.popup_selectedHealthContentCategoryID = null
            }

            this.popup_radioUsingHealthContentValue = hospital.usingHealthContent ? hospital.usingHealthContent : false
            this.popup_radioUsingHospitalContentValue = hospital.usingHospitalContent ? hospital.usingHospitalContent : false

            this.popup_selectedHospital_defaultVCMessage = this.serviceSetting.defaultVCMessage
            this.popup_selectedHospital_defaultVCSMSMessage = this.serviceSetting.defaultVCSMSMessage

            this.setHospitalPopupState({
                isShow: true,
                selectedHospital: hospital
            })
        },
        clearSelectedHospitalChatPopup() {
            if (this.keyword != '') {
                this.loadHospital(0)
            } else {
                this.initData()
            }
            this.selectedHospitalID = undefined
            this.setHospitalChatPopupState({
                isShow: false,
                selectedHospital: undefined
            })
        },
        action_ShowUpdateChattingAlertPopup(hospital) {
            this.selectedHospitalID = hospital
            this.setHospitalChatPopupState({
                isShow: true,
                selectedHospital: hospital
            })
        },
        async action_ShowSuspendHospitalPopup(hospital) {
            // check if hservice isClosed $ne true exists or not
            let isHServiceLeft = false
            let hResult = await this.API.HService.findNotClosedHServiceByHospitalID({ hospitalID: hospital._id })
            if (hResult.data.code != 0) {
                console.log(hResult)
                return
            }

            console.log(hResult.data.data)
            if (hResult.data.data.length > 0) {
                console.log('hservice not closed exists')
                isHServiceLeft = true
            }

            this.setHospitalSuspendPopupState({
                isShow: true,
                isHServiceLeft: isHServiceLeft,
                selectedHospital: hospital
            })
        },

        async action_ShowTreatmentCategoryPopup(hospital) {
            this.setHospitalTreatmentCategoryPopupState({
                isShow: true,
                selectedHospital: hospital
                // what else?
            })
        },
        async action_ShowAnsweringChatPopup(hospital) {
            this.setAnsweringChatPopupState({
                isShow: true,
                selectedHospital: hospital
            })
        },
        async action_createHosptial() {
            if (this.popup_hospitalName == undefined || this.popup_hospitalName == '') {
                this.isErrorMessage = true
                this.errorMessage = '병원명을 입력해주세요.'
                return
            }
            if (this.popup_hospitalShortName == undefined || this.popup_hospitalShortName == '') {
                this.isErrorMessage = true
                this.errorMessage = '짧은 병원명을 입력해주세요.'
                return
            }
            if (this.popup_selectedNurseID == undefined || this.popup_selectedNurseID == '') {
                this.isErrorMessage = true
                this.errorMessage = '담당 서비스 매니저를 선택해주세요.'
                return
            }

            this.isLoading = true

            let hospitalModel = {
                name: this.popup_hospitalName,
                shortName: this.popup_hospitalShortName,
                nurseID: this.popup_selectedNurseID.toString(),
                isUsingLogo: this.popup_radiosmsLogoImageValue,

                usingAutoReservedSMS: this.popup_radioUsingAutoReservedSmsValue,
                autoReservedSMSDelayDay: this.popup_selectedHospital_autoReservedSMSDelayDay,
                autoReservedSMSHour: this.popup_selectedHospital_autoReservedSMSHour,

                usingVideoContent: this.popup_radioUsingVideoContentValue,
                isSecondEdition: true,
                usingHospitalEvent: this.popup_radioUsingHospitalEventValue,
                usingV2ReservationSheet: this.popup_radioUsingV2ReservationSheetValue,
                usingHealthContent: this.popup_radioUsingHealthContentValue,
                usingHospitalContent: this.popup_radioUsingHospitalContentValue
            }

            if (this.popup_smsLogoImageFileID != undefined && this.popup_smsLogoImageFileID != '') {
                hospitalModel.smsLogoImage = this.popup_smsLogoImageFileID
            }
            if (this.popup_selectedHospital_initialChat != undefined) {
                hospitalModel.initialChat = [this.popup_selectedHospital_initialChat]
            } else {
                hospitalModel.initialChat = []
            }
            if (this.popup_selectedHospital_initialChats != undefined) {
                hospitalModel.initialChats = this.popup_selectedHospital_initialChats
            } else {
                hospitalModel.initialChats = []
            }

            if (this.popup_selectedHospital_smsChatMessageA != undefined) {
                hospitalModel.smsChatMessageA = this.popup_selectedHospital_smsChatMessage
            } else {
                hospitalModel.smsChatMessageA = ''
            }
            if (this.popup_selectedHospital_smsChatMessageB != undefined) {
                hospitalModel.smsChatMessageB = this.popup_selectedHospital_smsChatMessage
            } else {
                hospitalModel.smsChatMessageB = ''
            }
            if (this.popup_selectedHospital_smsChatMessage != undefined) {
                hospitalModel.smsChatMessage = this.popup_selectedHospital_smsChatMessage
            } else {
                hospitalModel.smsChatMessage = ''
            }
            if (this.popup_selectedHospital_smsBasicMessage != undefined) {
                hospitalModel.smsBasicMessage = this.popup_selectedHospital_smsBasicMessage
            } else {
                hospitalModel.smsBasicMessage = ''
            }
            if (this.popup_selectedHospital_ReservationMessage != undefined) {
                hospitalModel.smsReservation = this.popup_selectedHospital_ReservationMessage
            } else {
                hospitalModel.smsReservation = ''
            }
            if (this.popup_selectedHospital_smsResendMessage != undefined) {
                hospitalModel.smsResendMessage = this.popup_selectedHospital_smsResendMessage
            } else {
                hospitalModel.smsResendMessage = ''
            }

            if (this.popup_selectedHospital_autoReservedSMSMessage != undefined) {
                hospitalModel.autoReservedSMSMessage = this.popup_selectedHospital_autoReservedSMSMessage
            } else {
                hospitalModel.autoReservedSMSMessage = ''
            }

            if (this.popup_selectedHospital_smsPhone != undefined) {
                hospitalModel.smsPhone = this.popup_selectedHospital_smsPhone
            } else {
                hospitalModel.smsPhone = ''
            }

            if (this.popup_selectedHealthContentCategoryID != null) {
                hospitalModel.healthContentCategory = [this.popup_selectedHealthContentCategoryID]
            }

            // set default hospital representImage
            // TODO : file findById, if result does not exist, upload file and set
            // this.API.File.findOne({_id:FileID})
            if (process.env.NODE_ENV == 'development') {
                hospitalModel.representImage = '63dcd6c1db992534cc260182'
            }
            if (process.env.NODE_ENV == 'production') {
                hospitalModel.representImage = '63dceeb8e8ae550e20576f5c'
            }

            let hResult = await this.API.Hospital.createHospital(hospitalModel)
            if (hResult.data.code != 0) {
                console.log(hResult)
                this.isLoading = false
                return
            }

            let hospitalID = hResult.data.data._id

            // hospital authorizationType create
            let aResult = await this.API.HospitalAuthorizationType.find({ hospitalID: hospitalID })
            console.log(aResult.data.data)
            console.log('created hospital authType')

            // create default hospitalContentCategory if usingHospitalContent is ON
            if (this.popup_radioUsingHospitalContentValue == true) {
                let hccModel = { hospitalID: hospitalID }
                let hospitalCategory = await this.API.HospitalContentCategory.findOne(hccModel)
                console.log('### creating hospitalCategory')
                console.log(hospitalCategory.data.data)
            }

            // create servicesettings via hospital create result
            let serviceSettingModel = {
                hospitalID: hospitalID,
                defaultVCMessage: this.popup_selectedHospital_defaultVCMessage,
                defaultVCSMSMessage: this.popup_selectedHospital_defaultVCSMSMessage
            }
            let result = await this.API.ServiceSetting.createServiceSetting(serviceSettingModel)
            if (result.data.code != 0) {
                console.log(result)
                this.isLoading = false
                return
            }
            console.log(result.data.data)
          
            this.initData()
            this.action_Close()
        },

        async action_updateHospital() {
            // TODO : null check, export to new method
            if (this.popup_hospitalName == undefined || this.popup_hospitalName == '') {
                this.isErrorMessage = true
                this.errorMessage = '병원명을 입력해주세요.'
                return
            }
            if (this.popup_hospitalShortName == undefined || this.popup_hospitalShortName == '') {
                this.isErrorMessage = true
                this.errorMessage = '짧은 병원명을 입력해주세요.'
                return
            }

            this.isLoading = true

            let hospitalID = this.hospitalpopup.selectedHospital._id

            let hospitalModel = {
                _id: hospitalID,
                name: this.popup_hospitalName,
                shortName: this.popup_hospitalShortName,
                nurseID: this.popup_selectedNurseID.toString(),
                isUsingLogo: this.popup_radiosmsLogoImageValue,
                usingAutoReservedSMS: this.popup_radioUsingAutoReservedSmsValue,
                autoReservedSMSDelayDay: this.popup_selectedHospital_autoReservedSMSDelayDay,
                autoReservedSMSHour: this.popup_selectedHospital_autoReservedSMSHour,
                usingVideoContent: this.popup_radioUsingVideoContentValue,
                isSecondEdition: true,
                usingHospitalEvent: this.popup_radioUsingHospitalEventValue,
                usingV2ReservationSheet: this.popup_radioUsingV2ReservationSheetValue,
                usingHealthContent: this.popup_radioUsingHealthContentValue,
                usingHospitalContent: this.popup_radioUsingHospitalContentValue
            }

            if (this.popup_smsLogoImageFileID != undefined && this.popup_smsLogoImageFileID != '') {
                hospitalModel.smsLogoImage = this.popup_smsLogoImageFileID
            }
            if (this.popup_selectedHospital_initialChat != undefined) {
                hospitalModel.initialChat = [this.popup_selectedHospital_initialChat]
            } else {
                hospitalModel.initialChat = []
            }
            if (this.popup_selectedHospital_initialChats != undefined) {
                hospitalModel.initialChats = this.popup_selectedHospital_initialChats
            } else {
                hospitalModel.initialChats = []
            }
            if (this.popup_selectedHospital_smsChatMessageA != undefined) {
                hospitalModel.smsChatMessageA = this.popup_selectedHospital_smsChatMessage
            } else {
                hospitalModel.smsChatMessageA = ''
            }
            if (this.popup_selectedHospital_smsChatMessageB != undefined) {
                hospitalModel.smsChatMessageB = this.popup_selectedHospital_smsChatMessage
            } else {
                hospitalModel.smsChatMessageB = ''
            }
            if (this.popup_selectedHospital_smsChatMessage != undefined) {
                hospitalModel.smsChatMessage = this.popup_selectedHospital_smsChatMessage
            } else {
                hospitalModel.smsChatMessage = ''
            }

            if (this.popup_selectedHospital_autoReservedSMSMessage != undefined) {
                hospitalModel.autoReservedSMSMessage = this.popup_selectedHospital_autoReservedSMSMessage
            } else {
                hospitalModel.autoReservedSMSMessage = ''
            }

            if (this.popup_selectedHospital_smsPhone != undefined) {
                hospitalModel.smsPhone = this.popup_selectedHospital_smsPhone
            }

            if (this.popup_selectedHealthContentCategoryID != null) {
                hospitalModel.healthContentCategory = [this.popup_selectedHealthContentCategoryID]
            }

            // append updateModel by edit booleans
            this.hospitalpopup.selectedHospital.usingBasicMessage
                ? (hospitalModel.smsBasicMessage = this.popup_selectedHospital_smsBasicMessage)
                : (hospitalModel.smsBasicMessageEdit = this.popup_selectedHospital_smsBasicMessage)
            this.hospitalpopup.selectedHospital.usingReservationMessage
                ? (hospitalModel.smsReservation = this.popup_selectedHospital_ReservationMessage)
                : (hospitalModel.smsReservationEdit = this.popup_selectedHospital_ReservationMessage)
            this.hospitalpopup.selectedHospital.usingResendMessage
                ? (hospitalModel.smsResendMessage = this.popup_selectedHospital_smsResendMessage)
                : (hospitalModel.smsResendMessageEdit = this.popup_selectedHospital_smsResendMessage)

            let hResult = await this.API.Hospital.updateHospital(hospitalModel)
            if (hResult.data.code != 0) {
                console.log(hResult)
                this.isLoading = false
                return
            }
            console.log(hResult.data.data)

            // create default hospitalContentCategory if usingHospitalContent is ON
            if (this.popup_radioUsingHospitalContentValue == true) {
                let hccModel = { hospitalID: hospitalID }
                let hospitalCategory = await this.API.HospitalContentCategory.findOne(hccModel)
                console.log('### check hospitalCategory..')
                console.log(hospitalCategory.data.data)
            }

            let serviceSettingModel = {}

            // check this.serviceSetting is null, if so create serviceSetting and set
            if (this.serviceSetting._id == undefined) {
                serviceSettingModel.hospitalID = hospitalID
                let serviceSettingresult = await this.API.ServiceSetting.createServiceSetting(serviceSettingModel)
                this.serviceSetting = serviceSettingresult.data.data
            }

            serviceSettingModel = {
                _id: this.serviceSetting._id,
                hospitalID: hospitalID,
                defaultVCMessage: this.popup_selectedHospital_defaultVCMessage,
                defaultVCSMSMessage: this.popup_selectedHospital_defaultVCSMSMessage
            }

            let sResult = await this.API.ServiceSetting.updateServiceSetting(serviceSettingModel)
            if (sResult.data.code != 0) {
                console.log(sResult)
                this.isLoading = false
                return
            }
            console.log(sResult.data.data)

            this.initData()
            this.action_Close()
        },

        action_Close() {
            this.initPopup()
            this.setHospitalPopupState({
                isShow: false
            })
        },

        action_saveDraftInitialChats(chatArray) {
            this.popup_selectedHospital_initialChats = chatArray
        },

        async action_UploadImageFile(event) {
            let files = event.currentTarget.files
            let sizes = []

            for (let i = 0; i < files.length; i++) {
                let file = files[i]
                var imageReader = new FileReader()
                imageReader.onload = function (e) {
                    var img = new Image()
                    img.src = e.target.result

                    img.onload = function () {
                        var w = this.width
                        var h = this.height
                        let size = {
                            width: w,
                            height: h
                        }
                        sizes.push(size)
                    }
                }
                // console.log(file)
                imageReader.readAsDataURL(file)
            }
            let mimeType = 'image/*'
            let type = 300
            let userID = this.$store.state.user._id
            let result = await this.MPAWS.uploadFiles(files, sizes, mimeType, type, userID)

            if (result.data.code != 0) {
                console.log(result.data.message)
                return
            }
            console.log(result.data.data)
            // return result.data.data._id
            return result.data.data
        },

        smsLogoImageHover(status) {
            switch (status) {
                case 'on':
                    this.popup_smsLogoImageHoverStatus = true
                    break
                case 'off':
                    this.popup_smsLogoImageHoverStatus = false
            }
        },

        async loadSMSLogo() {
            let model = {
                _id: this.popup_smsLogoImageFileID
            }
            let result = await this.API.File.getFile(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            let file = undefined
            if (result.data.data != undefined && result.data.data.length > 0) {
                file = result.data.data[0]
            }
            if (file.paths != undefined && file.paths.length > 0) {
                this.popup_smsLogoImagePaths = file.paths[0]
            }
        },

        async action_uploadSMSLogoImageValue(event) {
            let result = await this.action_UploadImageFile(event)
            if (result != undefined && result != '') {
                this.popup_smsLogoImageFileID = result._id
            }
        },

        action_ShowChatResponseRequired(h) {
            let hospitalID = h._id
            this.hospitalID_For_ChatResponseRequired = hospitalID
            this.isShow_Pop_ChatResponseRequired = true
        },

        action_CloseChatResponseRequired() {
            this.hospitalID_For_ChatResponseRequired = undefined
            this.isShow_Pop_ChatResponseRequired = false
        },

        // TODO : make these methods below to common function..? + Vue.mixin?
        ISODatetoDate(date) {
            let modifiedDate = moment(date).format('YYYY-MM-DD HH:mm')
            return modifiedDate
        },
        isEmptyObject(obj) {
            for (var i in obj) return false
            return true
        },

        // emitter
        showSaveToast() {
            this.isShowToast = true
            setTimeout(() => {
                this.isShowToast = false
            }, 1000);
        }
    }
}
</script>
