<template>
    <div>
        <div>
            초기 채팅
        </div>
        <br />
        <div>
            <table class="table_default">
                <thead>
                    <tr>
                        <th>순서</th>
                        <th>Type</th>
                        <th>Content</th>
                        <th>수정/삭제</th>
                    </tr>
                </thead>
                <InitialChatsCell
                    v-for="(chat, index) in chatArray"
                    :key="index"
                    :chat="chat"
                    :index="index"
                    @updateChat="updateChat"
                    @remove="remove"
                    @upTo="upTo"
                    @downTo="downTo"
                />
                <tr>
                    <td colspan="4">
                        <div style="display: flex; justify-content: flex-end">
                            <a class="btn btn_s btn_blue_line" @click="action_Add">추가</a>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div style="text-align: right; margin-top: 14px; margin-bottom: 14px">
            <a class="btn btn_s btn_gray_line" @click="action_Restore">되돌리기</a>
            <a class="btn btn_s btn_blue_line" v-if="hospitalID == undefined" @click="action_saveDraft">임시 저장</a>
            <a class="btn btn_s btn_blue_line" v-if="hospitalID != undefined" @click="action_Save">저장</a>
        </div>
    </div>
</template>


<script>
import InitialChatsCell from './InitialChatsCell.vue'
export default {
    components: { InitialChatsCell },
    props: {
        hospitalID: String,
        initialChats: Array
    },
    data() {
        return {
            chatArray: undefined
        }
    },
    watch: {
        initialChats: {
            immediate: true,
            handler: function (newValue) {
                this.chatArray = JSON.parse(JSON.stringify(newValue))
            }
        },
    },
    methods: {
        action_Restore() {
            this.chatArray = JSON.parse(JSON.stringify(this.initialChats))
        },
        action_saveDraft(){
            this.$emit('saveDraft', this.chatArray)
        },
        async action_Save() {
            //api call
            let model = {
                _id: this.hospitalID,
                initialChats: this.chatArray
            }

            let result = await this.API.Hospital.updateHospital(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            
            this.$emit('initData')
            this.$emit('showSaveToast')
        },
        

        action_Add() {
            this.add()
        },
        
        // 
        add() {
            this.chatArray.splice(this.chatArray.length, 0, {chatType: 100, content: undefined, fileIDs: []})
        },
        remove(index) {
            this.chatArray.splice(index, 1)
        },
        upTo(index) {
            if (index - 1 < 0) { return }
            let item = this.chatArray.splice(index, 1)[0]
            this.chatArray.splice(index-1, 0, item)
        },
        downTo(index) {
            if (index + 1 > this.chatArray.length) { return }
            let item = this.chatArray.splice(index, 1)[0]
            this.chatArray.splice(index+1, 0, item)
        },
        
        updateChat(value, index) {
            this.chatArray.splice(index, 1, value)
        }
    }

}

</script>