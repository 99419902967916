import axios from 'axios';
import URL from './URL';
import Util from './Util';

const find = async function (m) {
    return axios({
        method: 'GET',
        url: URL.ANSWERING_CHAT_SETTING_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

const create = async function (m) {
    return axios({
        method: 'POST',
        url: URL.ANSWERING_CHAT_SETTING_CREATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const update = async function (m) {
    return axios({
        method: 'POST',
        url: URL.ANSWERING_CHAT_SETTING_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const checkFind = async function (m) {
    return axios({
        method: 'GET',
        url: URL.ANSWERING_CHAT_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

const checkCount = async function (m) {
    return axios({
        method: 'GET',
        url: URL.ANSWERING_CHAT_COUNT,
        params: m,
        headers: Util.getHeaders()
    })
}

export default {
    find: find,
    create: create,
    update: update,

    checkFind: checkFind,
    checkCount: checkCount
}

