import axios from 'axios'
import URL from './URL'
// import Util from '../../api/Util'

let login = async function (id, password) {
    return axios({
        method: 'POST',
        url: URL.AUTH_LOGIN,
        data: {
            id: id,
            password: password
        }
    })
}

export default {
    login: login
}
