const XLSX   = require('xlsx')

class ExcelParser {
    constructor() {
        this.file = undefined
        this.sheetName = undefined
        this.reader = new FileReader();
    }

    async parse() {
        const reader = new FileReader();

        const fileReadPromise = new Promise((resolve, reject) => {
            reader.onload = (e) => {
                const fileContents = e.target.result;
                resolve(fileContents);
            }
            reader.onerror = (error) => {
                reject(error);
            }
            reader.readAsBinaryString(this.file);
        })

        try {
            const fileContents = await fileReadPromise;
            let book = XLSX.read(fileContents, { type: 'binary' })

            let sheetName = this.sheetName ?? book.SheetNames[0]
            let sheetData = book.Sheets[sheetName]
            let datas = XLSX.utils.sheet_to_json(sheetData)
            return datas
        } catch (error) {
            throw Error(error)
        }
    }
}


export default ExcelParser