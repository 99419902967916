<template>
    <div class="content_wrap">
        <div class="page_title">
            <span>병원유형 설정</span>
        </div>
        <div class="cc lgray">
            <div class="h_type_set_wrap">
                <!-- load hospital servicesettings -->
                <div class="h_type_set fix">
                    <div class="form_flex_wrap">
                        <div class="form_flex_title">
                            <span class="title">등록된 병원 유형</span>
                            <button class="btn btn_xs btn_blue mgl_auto" @click="action_ShowCreateSampleServiceSettingPopup">추가</button>
                        </div>
                        <div class="form_flex_cont">
                            <div class="h_scroll_fix">
                                <table class="table_default">
                                    <tr v-if="sampleServiceSettings.length == 0">
                                        데이터 로드 실패. 새로고침 해주세요.
                                    </tr>
                                    <tr
                                        v-for="ss in sampleServiceSettings"
                                        :key="ss._id"
                                        :class="{ lgray: ss.isRemoved, on: ss._id == selectedSampleServiceSettingID }"
                                    >
                                        <td>{{ ss.name }}</td>
                                        <td class="txt_right">
                                            <button class="btn_xs btn_blue_line btn" @click="action_ShowServiceSettingDetail(ss)">수정</button>
                                            <label :for="ss._id" class="switch_wrap mgl_10">
                                                <input
                                                    type="checkbox"
                                                    :id="ss._id"
                                                    v-model="ss.isRemoved"
                                                    true-value="false"
                                                    false-value="true"
                                                    @change="action_updateIsRemoved(ss)" />
                                                <span class="slider round"></span
                                            ></label>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- load hospital servicesetting detail -->
                <div class="h_type_set">
                    <div class="h_type_set_scroll">
                        <div class="form_flex_wrap">
                            <div class="form_flex_title">좌측 탭에서 수정할 병원 유형의 '수정' 버튼을 눌러 진행</div>
                        </div>
                    </div>

                    <div class="h_type_set_scroll" v-if="selectedSampleServiceSettingID != undefined && selectedSampleServiceSettingID != ''">
                        <!-- load treatmentCategory -->
                        <div class="form_flex_wrap">
                            <div class="form_flex_title">
                                <span class="title">치료 유형 (최대 40개)</span>
                                <span class="info mgl_auto">입력된 유형만 일렉트론에 노출됩니다.</span>
                            </div>
                            <div class="form_flex_cont">
                                <div class="form_list">
                                    <div class="title">치료유형 1</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName1" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 2</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName2" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 3</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName3" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 4</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName4" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 5</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName5" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 6</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName6" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 7</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName7" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 8</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName8" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 9</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName9" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 10</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName10" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 11</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName11" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 12</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName12" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 13</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName13" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 14</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName14" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 15</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName15" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 16</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName16" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 17</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName17" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 18</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName18" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 19</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName19" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 20</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName20" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 21</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName21" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 22</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName22" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 23</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName23" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 24</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName24" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 25</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName25" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 26</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName26" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 27</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName27" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 28</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName28" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 29</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName29" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 30</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName30" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 31</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName31" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 32</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName32" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 33</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName33" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 34</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName34" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 35</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName35" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 36</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName36" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 37</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName37" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 38</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName38" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 39</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName39" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 40</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName40" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 41</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName41" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 42</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName42" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 43</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName43" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 44</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName44" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 45</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName45" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 46</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName46" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 47</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName47" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 48</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName48" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 49</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName49" /></div>
                                </div>
                                <div class="form_list">
                                    <div class="title">치료유형 50</div>
                                    <div class="cont"><input type="text" class="input_s" placeholder="입력" v-model="detail_treatmentCategoryName50" /></div>
                                </div>
                            </div>
                        </div>
                        <!-- load usingFirstVisit -->
                        <div class="form_flex_wrap">
                            <div class="form_flex_title">
                                <span class="title">초/재진 환자 구분</span>
                            </div>
                            <div class="form_flex_cont">
                                <label class="radio_wrap"
                                    ><input type="radio" name="detail_radioUsingFirstVisitValue" v-model="detail_radioUsingFirstVisitValue" :value="true" />
                                    <span class="checkmark"></span>초/재진 구분</label
                                >
                                <label class="radio_wrap"
                                    ><input type="radio" name="detail_radioUsingFirstVisitValue" v-model="detail_radioUsingFirstVisitValue" :value="false" />
                                    <span class="checkmark"></span>구분안함</label
                                >
                            </div>
                        </div>
                        <!-- load usingStartDate -->
                        <div class="form_flex_wrap">
                            <div class="form_flex_title">
                                <span class="title">치료시작일 입력 여부</span>
                            </div>
                            <div class="form_flex_cont">
                                <label class="radio_wrap"
                                    ><input type="radio" name="detail_radioUsingStartDateValue" v-model="detail_radioUsingStartDateValue" :value="true" />
                                    <span class="checkmark"></span>치료시작일 입력</label
                                >
                                <label class="radio_wrap"
                                    ><input type="radio" name="detail_radioUsingStartDateValue" v-model="detail_radioUsingStartDateValue" :value="false" />
                                    <span class="checkmark"></span>사용 안함</label
                                >
                            </div>
                        </div>
                        <!-- load usingAutoSendVideo -->
                        <div class="form_flex_wrap">
                            <div class="form_flex_title">
                                <span class="title">홈케어 콘텐츠 설정</span>
                            </div>
                            <div class="form_flex_cont">
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="detail_radioUsingAutoSendVideoValue"
                                        v-model="detail_radioUsingAutoSendVideoValue"
                                        :value="true"
                                    /><span class="checkmark"></span>사용함</label
                                >
                                <div class="h_type_set_select" v-for="vc in videoContentTopCategories" :key="vc._id">
                                    <label class="check_wrap"
                                        ><input type="checkbox" v-model="vc.isSelected" :disabled="!detail_radioUsingAutoSendVideoValue" />
                                        <span class="checkmark"></span>
                                        <b>{{ vc.categoryName }}</b> ({{ vc.subCategoryNames }})</label
                                    >
                                    <hr />
                                    <div class="form_list mgt_0">
                                        <div class="title">이행기간 설정</div>
                                        <div class="cont">
                                            <select class="input_s" v-model.number="vc.duration">
                                                <option value="1">1주 동안</option>
                                                <option value="2">2주 동안</option>
                                                <option value="3">3주 동안</option>
                                                <option value="4">4주 동안</option>
                                                <option value="8">8주 동안</option>
                                            </select>
                                            <select class="input_s" v-model.number="vc.doPerWeek">
                                                <option value="7">매일 반복</option>
                                                <option value="6">주 6회 반복</option>
                                                <option value="5">주 5회 반복</option>
                                                <option value="4">주 4회 반복</option>
                                                <option value="3">주 3회 반복</option>
                                                <option value="2">주 2회 반복</option>
                                                <option value="1">주 1회 반복</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <label class="radio_wrap"
                                    ><input
                                        type="radio"
                                        name="detail_radioUsingAutoSendVideoValue"
                                        v-model="detail_radioUsingAutoSendVideoValue"
                                        :value="false"
                                    /><span class="checkmark"></span>사용 안함</label
                                >
                            </div>
                        </div>
                        <small class="red_txt mgt_10" v-show="isError == true">
                            {{ errorMessage }}
                        </small>
                        <div class="btn_wrap">
                            <button class="btn btn_l btn_blue" @click="action_updateSampleServiceSetting">저장</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 병원 유형 추가 팝업 -->
        <div class="pop_overlay_bg" v-if="popup_isShow == true">
            <div class="add_hospital_type_pop_wrap">
                <div class="pop_title">
                    <span class="title">병원유형 추가</span>
                    <a class="mgl_auto" @click="action_closePopup"><v-icon large>mdi-close</v-icon></a>
                </div>
                <div class="pop_cont">
                    <div class="form_list">
                        <div class="title">병원유형</div>
                        <div class="cont">
                            <input type="text" class="input_s w_full" placeholder="예) 정형외과" v-model="popup_serviceSettingName" />
                        </div>
                    </div>
                </div>
                <small class="red_txt mgt_10" v-show="popup_isError == true">
                    {{ popup_errorMessage }}
                </small>
                <div class="btn_wrap">
                    <button class="btn btn_blue btn_l" @click="action_createSampleServiceSetting">등록</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isError: false,
            errorMessage: '',

            selectedSampleServiceSettingID: '',
            selectedSampleServiceSetting: {},
            selectedTreatmentCategories: [],

            sampleServiceSettings: [],
            sampleTreatmentCategories: [],
            videoContentTopCategories: [],

            // sampleServiceSetting create popup
            popup_serviceSettingName: '',
            popup_isShow: false,

            popup_isError: false,
            popup_errorMessage: '',

            // detail area
            // component로... 분리....
            detail_treatmentCategoryName1: '',
            detail_treatmentCategoryName2: '',
            detail_treatmentCategoryName3: '',
            detail_treatmentCategoryName4: '',
            detail_treatmentCategoryName5: '',
            detail_treatmentCategoryName6: '',
            detail_treatmentCategoryName7: '',
            detail_treatmentCategoryName8: '',
            detail_treatmentCategoryName9: '',
            detail_treatmentCategoryName10: '',
            detail_treatmentCategoryName11: '',
            detail_treatmentCategoryName12: '',
            detail_treatmentCategoryName13: '',
            detail_treatmentCategoryName14: '',
            detail_treatmentCategoryName15: '',
            detail_treatmentCategoryName16: '',
            detail_treatmentCategoryName17: '',
            detail_treatmentCategoryName18: '',
            detail_treatmentCategoryName19: '',
            detail_treatmentCategoryName20: '',
            detail_treatmentCategoryName21: '',
            detail_treatmentCategoryName22: '',
            detail_treatmentCategoryName23: '',
            detail_treatmentCategoryName24: '',
            detail_treatmentCategoryName25: '',
            detail_treatmentCategoryName26: '',
            detail_treatmentCategoryName27: '',
            detail_treatmentCategoryName28: '',
            detail_treatmentCategoryName29: '',
            detail_treatmentCategoryName30: '',
            detail_treatmentCategoryName31: '',
            detail_treatmentCategoryName32: '',
            detail_treatmentCategoryName33: '',
            detail_treatmentCategoryName34: '',
            detail_treatmentCategoryName35: '',
            detail_treatmentCategoryName36: '',
            detail_treatmentCategoryName37: '',
            detail_treatmentCategoryName38: '',
            detail_treatmentCategoryName39: '',
            detail_treatmentCategoryName40: '',
            detail_treatmentCategoryName41: '',
            detail_treatmentCategoryName42: '',
            detail_treatmentCategoryName43: '',
            detail_treatmentCategoryName44: '',
            detail_treatmentCategoryName45: '',
            detail_treatmentCategoryName46: '',
            detail_treatmentCategoryName47: '',
            detail_treatmentCategoryName48: '',
            detail_treatmentCategoryName49: '',
            detail_treatmentCategoryName50: '',

            detail_radioUsingFirstVisitValue: false,
            detail_radioUsingStartDateValue: false,
            detail_radioUsingAutoSendVideoValue: false,
            detail_usingAutoSendVideos: [],
            detail_videoContentDuration: []
        }
    },
    watch: {
        selectedSampleServiceSetting: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        sampleServiceSettings: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        selectedTreatmentCategories: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        videoContentTopCategories: {
            deep: true,
            handler: function () {
                // do nothing
            }
        },
        detail_usingAutoSendVideos: {
            deep: true,
            handler: function () {
                // do nothing
            }
        }
    },
    mounted() {
        this.loadSampleServiceSettings()
    },
    methods: {
        initData() {
            this.detail_treatmentCategoryName1 = ''
            this.detail_treatmentCategoryName2 = ''
            this.detail_treatmentCategoryName3 = ''
            this.detail_treatmentCategoryName4 = ''
            this.detail_treatmentCategoryName5 = ''
            this.detail_treatmentCategoryName6 = ''
            this.detail_treatmentCategoryName7 = ''
            this.detail_treatmentCategoryName8 = ''
            this.detail_treatmentCategoryName9 = ''
            this.detail_treatmentCategoryName10 = ''
            this.detail_treatmentCategoryName11 = ''
            this.detail_treatmentCategoryName12 = ''
            this.detail_treatmentCategoryName13 = ''
            this.detail_treatmentCategoryName14 = ''
            this.detail_treatmentCategoryName15 = ''
            this.detail_treatmentCategoryName16 = ''
            this.detail_treatmentCategoryName17 = ''
            this.detail_treatmentCategoryName18 = ''
            this.detail_treatmentCategoryName19 = ''
            this.detail_treatmentCategoryName20 = ''
            this.detail_treatmentCategoryName21 = ''
            this.detail_treatmentCategoryName22 = ''
            this.detail_treatmentCategoryName23 = ''
            this.detail_treatmentCategoryName24 = ''
            this.detail_treatmentCategoryName25 = ''
            this.detail_treatmentCategoryName26 = ''
            this.detail_treatmentCategoryName27 = ''
            this.detail_treatmentCategoryName28 = ''
            this.detail_treatmentCategoryName29 = ''
            this.detail_treatmentCategoryName30 = ''
            this.detail_treatmentCategoryName31 = ''
            this.detail_treatmentCategoryName32 = ''
            this.detail_treatmentCategoryName33 = ''
            this.detail_treatmentCategoryName34 = ''
            this.detail_treatmentCategoryName35 = ''
            this.detail_treatmentCategoryName36 = ''
            this.detail_treatmentCategoryName37 = ''
            this.detail_treatmentCategoryName38 = ''
            this.detail_treatmentCategoryName39 = ''
            this.detail_treatmentCategoryName40 = ''
            this.detail_treatmentCategoryName41 = ''
            this.detail_treatmentCategoryName42 = ''
            this.detail_treatmentCategoryName43 = ''
            this.detail_treatmentCategoryName44 = ''
            this.detail_treatmentCategoryName45 = ''
            this.detail_treatmentCategoryName46 = ''
            this.detail_treatmentCategoryName47 = ''
            this.detail_treatmentCategoryName48 = ''
            this.detail_treatmentCategoryName49 = ''
            this.detail_treatmentCategoryName50 = ''

            this.selectedSampleServiceSettingID = ''
            this.selectedSampleServiceSetting = {}

            this.detail_radioUsingFirstVisitValue = false
            this.detail_radioUsingStartDateValue = false
            this.detail_usingAutoSendVideos = []
            this.detail_radioUsingAutoSendVideoValue = false
            this.detail_videoContentDuration = []

            this.isError = false
            this.errorMessage = ''

            this.loadSampleServiceSettings()
        },

        async loadSampleServiceSettings() {
            let model = {}
            model.isSample = true
            let result = await this.API.ServiceSetting.getServiceSettings(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.sampleServiceSettings = result.data.data
            console.log(result.data.data)
        },

        loadTreatmentCategoriesBySampleServiceSetting(sampleServiceSetting) {
            this.sampleTreatmentCategories = sampleServiceSetting.treatmentCategories
            if (this.sampleTreatmentCategories.length > 0) {
                for (let i = 0; i < this.sampleTreatmentCategories.length; i++) {
                    this.setTreatmentCategoryNameByNum(i)
                }
            }
        },

        // set detail treatmentCategoryName by index ..
        setTreatmentCategoryNameByNum(index) {
            switch (index) {
                case 0:
                    this.detail_treatmentCategoryName1 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 1:
                    this.detail_treatmentCategoryName2 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 2:
                    this.detail_treatmentCategoryName3 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 3:
                    this.detail_treatmentCategoryName4 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 4:
                    this.detail_treatmentCategoryName5 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 5:
                    this.detail_treatmentCategoryName6 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 6:
                    this.detail_treatmentCategoryName7 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 7:
                    this.detail_treatmentCategoryName8 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 8:
                    this.detail_treatmentCategoryName9 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 9:
                    this.detail_treatmentCategoryName10 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 10:
                    this.detail_treatmentCategoryName11 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 11:
                    this.detail_treatmentCategoryName12 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 12:
                    this.detail_treatmentCategoryName13 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 13:
                    this.detail_treatmentCategoryName14 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 14:
                    this.detail_treatmentCategoryName15 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 15:
                    this.detail_treatmentCategoryName16 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 16:
                    this.detail_treatmentCategoryName17 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 17:
                    this.detail_treatmentCategoryName18 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 18:
                    this.detail_treatmentCategoryName19 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 19:
                    this.detail_treatmentCategoryName20 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 20:
                    this.detail_treatmentCategoryName21 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 21:
                    this.detail_treatmentCategoryName22 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 22:
                    this.detail_treatmentCategoryName23 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 23:
                    this.detail_treatmentCategoryName24 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 24:
                    this.detail_treatmentCategoryName25 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 25:
                    this.detail_treatmentCategoryName26 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 26:
                    this.detail_treatmentCategoryName27 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 27:
                    this.detail_treatmentCategoryName28 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 28:
                    this.detail_treatmentCategoryName29 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 29:
                    this.detail_treatmentCategoryName30 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 30:
                    this.detail_treatmentCategoryName31 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 31:
                    this.detail_treatmentCategoryName32 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 32:
                    this.detail_treatmentCategoryName33 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 33:
                    this.detail_treatmentCategoryName34 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 34:
                    this.detail_treatmentCategoryName35 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 35:
                    this.detail_treatmentCategoryName36 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 36:
                    this.detail_treatmentCategoryName37 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 37:
                    this.detail_treatmentCategoryName38 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 38:
                    this.detail_treatmentCategoryName39 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 39:
                    this.detail_treatmentCategoryName40 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 40:
                    this.detail_treatmentCategoryName41 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 41:
                    this.detail_treatmentCategoryName42 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 42:
                    this.detail_treatmentCategoryName43 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 43:
                    this.detail_treatmentCategoryName44 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 44:
                    this.detail_treatmentCategoryName45 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 45:
                    this.detail_treatmentCategoryName46 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 46:
                    this.detail_treatmentCategoryName47 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 47:
                    this.detail_treatmentCategoryName48 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 48:
                    this.detail_treatmentCategoryName49 = this.sampleTreatmentCategories[index].categoryName
                    break
                case 49:
                    this.detail_treatmentCategoryName50 = this.sampleTreatmentCategories[index].categoryName
            }
        },

        async loadVideoContentTopCategories() {
            let model = { isHidden: false } // hide "conditioning"
            let result = await this.API.VideoContentCategory.findtop(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)

            // modify result.data.data
            for (let i = 0; i < result.data.data.length; i++) {
                let c = result.data.data[i] // top Category
                let parentCategoryID = c._id
                let r = await this.API.VideoContentCategory.find({
                    isTop: false,
                    isParent: true,
                    parentCategoryIDs: parentCategoryID
                })
                if (r.data.code != 0) {
                    console.log(result)
                    return
                }
                let array = []
                for (let i = 0; i < r.data.data.length; i++) {
                    array.push(r.data.data[i].categoryName)
                }

                c.subCategoryNames = array.join(', ')
                c.isSelected = this.getIsSelected(c)
                c.doPerWeek = this.getDoPerWeek(c)
                c.duration = this.getDuration(c)
            }

            this.videoContentTopCategories = result.data.data
            this.$forceUpdate()
        },

        async action_updateIsRemoved(sampleServiceSetting) {
            let model = {
                _id: sampleServiceSetting._id,
                isRemoved: sampleServiceSetting.isRemoved
            }
            let result = await this.API.ServiceSetting.updateSampleServiceSetting(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)
        },

        initPopup() {
            this.popup_isError = false
            this.popup_errorMessage = ''
            this.popup_serviceSettingName = ''
        },
        async action_ShowCreateSampleServiceSettingPopup() {
            this.popup_isShow = true
        },
        async action_createSampleServiceSetting() {
            if (this.popup_serviceSettingName == undefined || this.popup_serviceSettingName == '') {
                this.popup_isError = true
                this.popup_errorMessage = '병원유형을 입력해주세요.'
                return
            }
            let model = {
                name: this.popup_serviceSettingName
            }
            model.isSample = true
            model.isRemoved = true // 생성 시 삭제 상태로 시작
            let result = await this.API.ServiceSetting.createSampleServiceSetting(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data.data)
            this.initData()
            this.action_closePopup()
        },

        action_closePopup() {
            this.initPopup()
            this.popup_isShow = false
        },

        async action_ShowServiceSettingDetail(sampleServiceSetting) {
            this.initData()
            // set details
            this.selectedSampleServiceSettingID = sampleServiceSetting._id
            this.selectedSampleServiceSetting = sampleServiceSetting
            this.detail_radioUsingFirstVisitValue = sampleServiceSetting.usingFirstVisit
            this.detail_radioUsingStartDateValue = sampleServiceSetting.usingStartDate
            this.detail_usingAutoSendVideos = sampleServiceSetting.usingAutoSendVideo // array
            this.detail_radioUsingAutoSendVideoValue = sampleServiceSetting.autoSendVideo
            this.detail_videoContentDuration = sampleServiceSetting.videoContentDuration

            this.loadTreatmentCategoriesBySampleServiceSetting(sampleServiceSetting)
            this.loadVideoContentTopCategories()
            console.log(sampleServiceSetting)
        },

        async action_updateSampleServiceSetting() {
            // init error message
            this.isError = false
            this.errorMessage = ''
            // set usingFirstVisit, usingStartDate, autoSendVideo booleans
            let model = {
                _id: this.selectedSampleServiceSettingID,
                usingFirstVisit: this.detail_radioUsingFirstVisitValue,
                usingStartDate: this.detail_radioUsingStartDateValue,
                autoSendVideo: this.detail_radioUsingAutoSendVideoValue
            }

            // set treatmentCategories
            let treatmentCategoryNameArr = []
            let treatmentCategories = []

            if (
                this.detail_treatmentCategoryName1 == '' &&
                this.detail_treatmentCategoryName2 == '' &&
                this.detail_treatmentCategoryName3 == '' &&
                this.detail_treatmentCategoryName4 == '' &&
                this.detail_treatmentCategoryName5 == '' &&
                this.detail_treatmentCategoryName6 == '' &&
                this.detail_treatmentCategoryName7 == '' &&
                this.detail_treatmentCategoryName8 == '' &&
                this.detail_treatmentCategoryName9 == '' &&
                this.detail_treatmentCategoryName10 == '' &&
                this.detail_treatmentCategoryName11 == '' &&
                this.detail_treatmentCategoryName12 == '' &&
                this.detail_treatmentCategoryName13 == '' &&
                this.detail_treatmentCategoryName14 == '' &&
                this.detail_treatmentCategoryName15 == '' &&
                this.detail_treatmentCategoryName16 == '' &&
                this.detail_treatmentCategoryName17 == '' &&
                this.detail_treatmentCategoryName18 == '' &&
                this.detail_treatmentCategoryName19 == '' &&
                this.detail_treatmentCategoryName20 == '' &&
                this.detail_treatmentCategoryName21 == '' &&
                this.detail_treatmentCategoryName22 == '' &&
                this.detail_treatmentCategoryName23 == '' &&
                this.detail_treatmentCategoryName24 == '' &&
                this.detail_treatmentCategoryName25 == '' &&
                this.detail_treatmentCategoryName26 == '' &&
                this.detail_treatmentCategoryName27 == '' &&
                this.detail_treatmentCategoryName28 == '' &&
                this.detail_treatmentCategoryName29 == '' &&
                this.detail_treatmentCategoryName30 == '' &&
                this.detail_treatmentCategoryName31 == '' &&
                this.detail_treatmentCategoryName32 == '' &&
                this.detail_treatmentCategoryName33 == '' &&
                this.detail_treatmentCategoryName34 == '' &&
                this.detail_treatmentCategoryName35 == '' &&
                this.detail_treatmentCategoryName36 == '' &&
                this.detail_treatmentCategoryName37 == '' &&
                this.detail_treatmentCategoryName38 == '' &&
                this.detail_treatmentCategoryName39 == '' &&
                this.detail_treatmentCategoryName40 == '' &&
                this.detail_treatmentCategoryName41 == '' &&
                this.detail_treatmentCategoryName42 == '' &&
                this.detail_treatmentCategoryName43 == '' &&
                this.detail_treatmentCategoryName44 == '' &&
                this.detail_treatmentCategoryName45 == '' &&
                this.detail_treatmentCategoryName46 == '' &&
                this.detail_treatmentCategoryName47 == '' &&
                this.detail_treatmentCategoryName48 == '' &&
                this.detail_treatmentCategoryName49 == '' &&
                this.detail_treatmentCategoryName50 == ''
            ) {
                this.isError = true
                this.errorMessage = '치료 유형을 입력해주세요.'
                return
            }
            treatmentCategoryNameArr.push(
                this.detail_treatmentCategoryName1,
                this.detail_treatmentCategoryName2,
                this.detail_treatmentCategoryName3,
                this.detail_treatmentCategoryName4,
                this.detail_treatmentCategoryName5,
                this.detail_treatmentCategoryName6,
                this.detail_treatmentCategoryName7,
                this.detail_treatmentCategoryName8,
                this.detail_treatmentCategoryName9,
                this.detail_treatmentCategoryName10,
                this.detail_treatmentCategoryName11,
                this.detail_treatmentCategoryName12,
                this.detail_treatmentCategoryName13,
                this.detail_treatmentCategoryName14,
                this.detail_treatmentCategoryName15,
                this.detail_treatmentCategoryName16,
                this.detail_treatmentCategoryName17,
                this.detail_treatmentCategoryName18,
                this.detail_treatmentCategoryName19,
                this.detail_treatmentCategoryName20,
                this.detail_treatmentCategoryName21,
                this.detail_treatmentCategoryName22,
                this.detail_treatmentCategoryName23,
                this.detail_treatmentCategoryName24,
                this.detail_treatmentCategoryName25,
                this.detail_treatmentCategoryName26,
                this.detail_treatmentCategoryName27,
                this.detail_treatmentCategoryName28,
                this.detail_treatmentCategoryName29,
                this.detail_treatmentCategoryName30,
                this.detail_treatmentCategoryName31,
                this.detail_treatmentCategoryName32,
                this.detail_treatmentCategoryName33,
                this.detail_treatmentCategoryName34,
                this.detail_treatmentCategoryName35,
                this.detail_treatmentCategoryName36,
                this.detail_treatmentCategoryName37,
                this.detail_treatmentCategoryName38,
                this.detail_treatmentCategoryName39,
                this.detail_treatmentCategoryName40,
                this.detail_treatmentCategoryName41,
                this.detail_treatmentCategoryName42,
                this.detail_treatmentCategoryName43,
                this.detail_treatmentCategoryName44,
                this.detail_treatmentCategoryName45,
                this.detail_treatmentCategoryName46,
                this.detail_treatmentCategoryName47,
                this.detail_treatmentCategoryName48,
                this.detail_treatmentCategoryName49,
                this.detail_treatmentCategoryName50
            )

            // remove empty value
            treatmentCategoryNameArr = treatmentCategoryNameArr.filter((name) => {
                return name !== null && name !== undefined && name !== ''
            })

            for (let i = 0; i < treatmentCategoryNameArr.length; i++) {
                let m = {
                    categoryName: treatmentCategoryNameArr[i]
                }
                let r = await this.API.TreatmentCategory.create(m)
                console.log(r.data)
                treatmentCategories.push(r.data.data)
            }
            model.treatmentCategories = treatmentCategories

            // set usingAutoSendVideo, videoContentDuration
            let usingAutoSendVideo = []
            let videoContentDuration = []

            if (this.detail_radioUsingAutoSendVideoValue == true) {
                for (let i = 0; i < this.videoContentTopCategories.length; i++) {
                    let c = this.videoContentTopCategories[i]
                    if (c.isSelected == true) {
                        usingAutoSendVideo.push(c._id)
                        let vcd = {
                            categoryID: c._id,
                            duration: c.duration,
                            doPerWeek: c.doPerWeek
                        }
                        videoContentDuration.push(vcd)
                    }
                }
            }
            model.usingAutoSendVideo = usingAutoSendVideo
            model.videoContentDuration = videoContentDuration

            // check
            if (this.detail_radioUsingAutoSendVideoValue == true && usingAutoSendVideo.length == 0) {
                this.isError = true
                this.errorMessage = '홈케어 콘텐츠를 하나 이상 선택해주세요.'
                return
            }

            // TODO : UPDATE GLOBAL SERVICESETTING
            let result = await this.API.ServiceSetting.updateSampleServiceSetting(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            console.log(result.data)
            this.initData()
        },

        // getters
        getIsSelected(c) {
            if (this.detail_usingAutoSendVideos.length == 0) {
                return false
            }
            for (let i = 0; i < this.detail_usingAutoSendVideos.length; i++) {
                if (this.detail_usingAutoSendVideos[i]._id == c._id) {
                    return true
                }
            }
            return false
        },

        // 홈케어 콘텐츠 설정에서, 주당 이행 횟수에 대해 선택한 값이 없는 경우 기본값 3으로 리턴 - '주 3회'
        getDoPerWeek(c) {
            if (this.detail_videoContentDuration.length == 0) {
                return 3
            }
            for (let i = 0; i < this.detail_videoContentDuration.length; i++) {
                if (this.detail_videoContentDuration[i].categoryID == c._id) {
                    return this.detail_videoContentDuration[i].doPerWeek
                }
            }
            return 3
        },

        // 홈케어 콘텐츠 설정에서, 홈케어 이행 기간에 대해 선택한 값이 없는 경우 기본값 2로 리턴 - '2주 동안'
        getDuration(c) {
            if (this.detail_videoContentDuration.length == 0) {
                return 2
            }
            for (let i = 0; i < this.detail_videoContentDuration.length; i++) {
                if (this.detail_videoContentDuration[i].categoryID == c._id) {
                    return this.detail_videoContentDuration[i].duration
                }
            }
            return 2
        },

        isEmptyObject(obj) {
            for (var i in obj) return false
            return true
        }
    }
}
</script>
