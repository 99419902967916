import axios from 'axios'
import URL from './URL'
import Util from './Util'

const find = async function (m) {
    return axios({
        method: 'GET',
        url: URL.VIDEOCONTENTHOSPITAL_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

const create = async function (m) {
    return axios({
        method: 'POST',
        url: URL.VIDEOCONTENTHOSPITAL_CREATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const update = async function (m) {
    return axios({
        method: 'POST',
        url: URL.VIDEOCONTENTHOSPITAL_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}

const copyByHospitalCategory = async function (m) {
    return axios({
        method: 'GET',
        url: URL.VIDEOCONTENTHOSPITAL_COPY_BY_HOSPITAL_CATEGORY,
        params: m,
        headers: Util.getHeaders()
    })
}

const copyByGlobalCategory = async function (m) {
    return axios({
        method: 'GET',
        url: URL.VIDEOCONTENTHOSPITAL_COPY_BY_GLOBAL_CATEGORY,
        params: m,
        headers: Util.getHeaders()
    })
}

const copyAllVCH = async function (m) {
    return axios({
        method: 'GET',
        url: URL.VIDEOCONTENTHOSPITAL_COPY_ALL_VCH,
        params: m,
        headers: Util.getHeaders()
    })
}

// const remove = async function(m) {
//     return axios({
//         method: 'POST',
//         url: URL.VIDEOCONTENTHOSPITAL_REMOVE,
//         data: m,
//         headers: Util.getHeaders()
//     })
// }

// const count = async function(m) {
//     return axios({
//         method: 'GET',
//         url: URL.VIDEOCONTENTHOSPITAL_COUNT,
//         params: m,
//         headers: Util.getHeaders()
//     })
// }

export default {
    find: find,
    create: create,
    update: update,
    copyByHospitalCategory: copyByHospitalCategory,
    copyByGlobalCategory: copyByGlobalCategory,
    copyAllVCH: copyAllVCH
    // remove:     remove,
    // count:      count,
}
